const censorCreditCardNumber = (number) => {
	if (number.length === 16) {
		return "**** **** **** " + number.substr(12, 4); // VISA / Mastercard
	} else if (number.length === 15) {
		return "**** ****** *" + number.substr(11, 4); // AMEX
	}

	return number;
};

const getCardType = (number) => {
	if (number.match(/^4/) != null) {
		return "Visa";
	}

	if (number.match(/^5[1-5]/) != null) {
		return "MasterCard";
	}

	if (number.match(/^(34|37)/) != null) {
		return "American Express";
	}

	return "";
}

export { censorCreditCardNumber, getCardType };
