import { sendFetchRequest, getApiContext } from './api_utils';

const cartURL = `${getApiContext()}/api/cart`;

const getByPartnerID = (partnerID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${cartURL}/partner/${partnerID}`, options);
};

const submitCartPayment = (payload) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(payload),
	};

	return sendFetchRequest(`${cartURL}/partner/${payload.PartnerId}`, options);
};

const addCartItem = (cartItem) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(cartItem),
	};

	return sendFetchRequest(`${cartURL}/item`, options);
};

const deleteCartItem = (cartItemId) => {
	const options = {
		method: 'DELETE',
	};

	return sendFetchRequest(`${cartURL}/item/${cartItemId}`, options);
};

const submitChoice = (cartItemId, baseProductOptionId, data) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			cartItemId,
			baseProductOptionId,
			data,
		}),
	};

	return sendFetchRequest(`${cartURL}/item/option`, options);
};

export { getByPartnerID, submitCartPayment, addCartItem, deleteCartItem, submitChoice };
