import React, { Component } from 'react';

import SmallMoment from '../Moment/SmallMoment';

import { getDateRange } from '../../utils/general';

import './Moments.scss';

class Moments extends Component {
	renderTutorial = (isImageSet, isYearSet, isDescriptionSet) => (
		<div className="tutorial">
			{this.renderTutorialItem('fa-camera', '1. Upload An Image', isImageSet)}
			{this.renderTutorialItem('fa-calendar', '2. Enter the Years', isYearSet)}
			{this.renderTutorialItem('fa-pencil', '3. Write a Description', isDescriptionSet)}
			{this.renderTutorialItem('fa-clock-o', '4. Create Moments', false)}
		</div>
	);

	renderTutorialItem = (icon, text, isSet) => {
		const iconClasses = [ 'fa' ];
		if (icon) {
			iconClasses.push(icon);
		}

		const tickClasses = [ 'fa fa-check-circle tutorial__tick' ];
		if (isSet) {
			tickClasses.push('tutorial__tick--active');
		}

		return (
			<div className="tutorial__item">
				<div className="tutorial__text">
					<div className="tutorial__icon">
						<i className={iconClasses.join(' ')} />
					</div>
					{text}
				</div>
				<div className="tutorial__buttons">
					{/* {!isSet && <div className="tutorial__show">show me</div>} */}
					<i className={tickClasses.join(' ')} />
				</div>
			</div>
		);
	};

	render() {
		const { lifeTimeLine, editAllowed, onDeleteMoment } = this.props;
		const { lifeTimeLineID, moments, image, fromYear, toYear, description } = lifeTimeLine;

		if (!moments || moments.length === 0) {
			const isImageSet = !!image;
			const isYearSet = fromYear > 0;
			const isDescriptionSet = !!description;

			return this.renderTutorial(isImageSet, isYearSet, isDescriptionSet);
		}

		//TASK: fine tune this. Added extreme number of years to ensure every single year was returned, not just the calculated DateMenu items.
		// This is due to the mobile version not using view height to get set menu items. RN
		const earliersMomentYear = moments[0].year;
		const useThisFirstYear = earliersMomentYear < fromYear ? earliersMomentYear : fromYear;

		const latestMomentYear = moments[moments.length-1].year;
		const toYearChecked = (typeof toYear === 'number' && isFinite(toYear) && Math.floor(toYear) === toYear && toYear > 0) ? toYear : (new Date()).getFullYear();
		const useThisLastYear = latestMomentYear > toYearChecked ? latestMomentYear : toYearChecked;

		const years = getDateRange(useThisFirstYear, useThisLastYear, 10000);

		const momentsContent = [];

		for (let i = 0; i < years.length; i++) {
			const year = years[i];
			const nextYear = years[i+1] || years[i]+1;

			momentsContent.push(<div key={`year-${year}`} id={`year-${year}`}></div>);

			moments
				.filter(moment => moment.year >= year && moment.year < nextYear)
				.forEach((moment) => {
					momentsContent.push(
						<SmallMoment
							key={`small-moment-${moment.momentId}`}
							momentID={moment.momentId}
							lifeTimeLineID={lifeTimeLineID}
							date={moment.date}
							approximate={moment.approximate}
							year={moment.year}
							month={moment.month}
							day={moment.day}
							image={moment.image}
							shortDescription={moment.shortDescription}
							metaData={moment.metaData}
							editAllowed={editAllowed}
							onDeleteMoment={onDeleteMoment}
							buildURL={this.props.buildURL}
							author={this.props.author}
						/>
					);
				});
		}

		return (
			<div className="moments">
				{momentsContent}
			</div>
		);
	}
};

export default Moments;