import React from 'react';

import { getDateRange, smoothScroll } from '../../utils/general';

import './DateMenu.scss';

// Accepts two strings representing the start and end year of the LifeTimeLine
// Also optional fixed count used to control mobile devices that do not use the screen height to layout the date menu

const DateMenu = ({ fromYear, toYear, fixedMenuItemCount, handleDateMenuToggle }) => {
	const years = getDateRange(fromYear, toYear, fixedMenuItemCount);
	const content = years.length ? years.map(year =>
		<a
			className="dateMenu__year"
			key={`dateMenu-${year}`}
			href={`#year-${year}`}
			onClick={(e) => {
				e.preventDefault();
				smoothScroll(`year-${year}`);
				handleDateMenuToggle();
			}}
		>
			{year}
		</a>
	) : '...';

	return (
		<div className="dateMenu">
			{content}
		</div>
	);
};

export default DateMenu;
