import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { brandConfig } from '../../api/brandConfig';

import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './Help.scss';

class Help extends Component {
	constructor(props) {
		super(props);

		this.state = {
			oneRevealActive: false,
			twoRevealActive: false,
			threeRevealActive: false,
			fourRevealActive: false,

		};


	}

	toggleReveal = (revealContext) => {
		this.setState({
			[revealContext]: !this.state[revealContext],
		});
	}

	render() {

		return (
			<div className="help__page">

				<div className="page-content__wrapper">
					<div className="page-content">

						<h1 className="noTopMargin">Quick Start Guide</h1>
						<p className="noTopMargin ">Before you begin - have one or two photos of your subject ready.</p>
						<p className="noTopMargin ">Build over time - don't feel you need to add dozens of Moments straight away.</p>

						<div className="headingControl">
							<h2>1. Register</h2>
							<div
								className="revealControl"
								onClick={() => this.toggleReveal('oneRevealActive')}>
								{this.state.oneRevealActive ? "less" : "more"}
							</div>
						</div>
						{this.state.oneRevealActive &&
							<div className="page-content__revealPanel">
								<p className="noTopMargin ">Register <a href="/register">here</a> for free.</p>
								<p className="">You can enter as little as your email and password.</p>
								<p className="">We'll send you an email to confirm your address is correct.</p>
							</div>
						}

						<div className="headingControl">
							<h2>2. Create a LifeTimeLine</h2>
							<div
								className="revealControl"
								onClick={() => this.toggleReveal('twoRevealActive')}>
								{this.state.twoRevealActive ? "less" : "more"}
							</div>
						</div>
						{this.state.twoRevealActive &&
							<div className="page-content__revealPanel">
								<p className="noTopMargin noBottomMargin">Sign in to arrive at your LifeTimeLines screen.</p>
								<p className="noTopMargin hints">This is also accessible via the User Menu ( <i class="fa fa-bars fa"></i> icon ) then click
									<Logo brand={brandConfig['lifetimeline'].brand} logoContext="userMenu"/> LifeTimeLines.</p>
								<p className="noBottomMargin">If it is for yourself, click the link "Add my own LifeTimeLine"</p>
								<p className="noTopMargin hints">Located at the bottom of the blue box.</p>
								<p className="">If it's for someone else, enter the first and last name and click 'Add a New Record'</p>
								<p className="hints">You'll be taken to the new LifeTimeLine screen.</p>
								<p className="">Click on the person sihlouette and choose the image from your computer or device</p>
								<p className="">Enter a 'From year' (usually the year of birth) and a short description then click 'Save'</p>
								<p className="">You can update this record at any time.</p>
								<p className="">You are now ready to add Moments.</p>
							</div>
						}

						<div className="headingControl">
							<h2>3. Add Moments</h2>
							<div
								className="revealControl"
								onClick={() => this.toggleReveal('threeRevealActive')}>
								{this.state.threeRevealActive ? "less" : "more"}
							</div>
						</div>
						{this.state.threeRevealActive &&
							<div className="page-content__revealPanel">
								<p className="noTopMargin hints">Assumes you are signed in and looking at a LifeTimeLine for which you are an author.</p>
								<p className="">Click the 'Add a Moment' button.</p>
								<p className="">Click the image icon and choose an image from your computer.</p>
								<p className="">Enter a date (at least the year) and a Description then click 'Save'.</p>
							</div>
						}

						<div className="headingControl">
							<h2>4. Publish your LifeTimeLine</h2>
							<div
								className="revealControl"
								onClick={() => this.toggleReveal('fourRevealActive')}>
								{this.state.fourRevealActive ? "less" : "more"}
							</div>
						</div>
						{this.state.fourRevealActive &&
							<div className="page-content__revealPanel">
								<p className="noTopMargin ">When you are happy with your content, you can click the 'Publish' icon.</p>
								<p className="">You can continue adding moments.</p>
							</div>
						}

						<h1>Photos</h1>
						<p>There are several good apps you can use to scan photos with your phone.</p>
						<p>We recommend <a href="https://www.google.com/photos/scan/">Google Photos Scan</a>.</p>

						<h1>Need More Help?</h1>
						<p>We understand, everyone is different and sees things from a unique perspective.
							<br />If you are having trouble using the system we really want to know.
							<br />It's the best way we can build a better system so <a href="/contact-us">contact us</a>.</p>

					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default Help;
