import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../Form/Button';
import SignInPanel from '../SignInPanel';

import { formatMoney } from '../../utils/general';
import * as cartAPI from '../../api/cartAPI';

import './ProductPanel.scss';

class ProductPanel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isSignInPanelOpen: false,
		};
	}

	onClose = () => this.setState({ isSignInPanelOpen: false });

	onBuyNow = (productId) => {
		if (!this.props.profile.isSignedIn) {
			this.setState({ isSignInPanelOpen: true });
			return;
		}

		if (productId === 'new') {
			this.props.history.push('/author/new')
		}

		cartAPI.getByPartnerID(this.props.audience.partnerID)
			.then(resp => {
				if (!resp.success) {
					// TASK: Handle error
					return;
				}

				cartAPI.addCartItem({
					cartId: resp.data.cartId,
					productId: productId,
					quantity: 1,
				})
				.then(resp => {
					if (!resp.success) {
						// TASK: Handle error
						return;
					}

					// TASK: Check this style of redirection is ok
					window.location = '/cart';
				});
			});
	};

	renderRibbon = (relatedProducts, description) => {
		// TASK: Add support for multiple related products
		const product = relatedProducts[0];

		const financial = product && product.price > 0;

		let color = 'blue';
		if (financial) {
			color = 'green';
		}

		const timeCode = product && product.baseProductCode.slice(-1);
		let timePeriod = 'month';
		if (timeCode === 'A') {
			timePeriod = 'year';
		} else if (timeCode !== 'M') {
			timePeriod = '';
		}

		return (
			<div className={`product-ribbon product-ribbon--${color}`}>
				{product && <div className="product-ribbon__top">OR</div>}
				{product && <Button
					className="product-ribbon__button"
					color={color}
					onClick={() => this.onBuyNow(product.productId)}
				>
					<div className="button__label">
						<div>BUY NOW</div>
					    <div>{formatMoney(product.price/100, 0)}{timePeriod && <span className={`product__sub--${color}`}>/{timePeriod}</span>}</div>
					</div>
				</Button>}
				<div className="product-ribbon__description">{product ? product.description : description}</div>
			</div>
		);
	};

	render() {
		const {
			product,
			relatedProducts,
		} = this.props;

		const features = product.features.map((feature, i) =>
			<div key={`product-feature-${i}`} className="feature">
				{feature}
			</div>
		);

		const timeCode = product.baseProductCode.slice(-1);
		let timePeriod = 'month';
		if (timeCode === 'A') {
			timePeriod = 'year';
		} else if (timeCode !== 'M') {
			timePeriod = '';
		}

		return (
			<div className="product">
				<div className="product__name">{product.name}</div>
				<div className="product__price">
					<div className="product__price--price">{product.price ? formatMoney(product.price/100, 0) : 'FREE'}</div>
					<div className="product__price--timePeriod">{timePeriod && "/"+timePeriod}</div>
				</div>
				<div className="product__features">
					{features}
				</div>
				<div className="product__buttons">
					<Button
						color="blue"
						className="product__button"
						onClick={() => this.onBuyNow(product.productId)}
					>
						{product.price ? <div className="button__label"><div>BUY NOW</div><div>{formatMoney(product.price/100, 0)}{timePeriod && <span className="product__sub--blue">/{timePeriod}</span>}</div></div> : 'Try It for FREE!'}
					</Button>
					<SignInPanel
						isOpen={this.state.isSignInPanelOpen}
						productID={product.productId}
						history={this.props.history}
					/>
				</div>
				{this.renderRibbon(relatedProducts, product.description)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	audience: state.audience,
	profile: state.profile,
});

export default connect(
	mapStateToProps,
)(ProductPanel);
