import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
//import { brandConfig } from '../../api/brandConfig';

//import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './About.scss';

class About extends Component {

	render() {

		return (
			<div className="about__page">
				<div className="page-content__wrapper">
					<div className="page-content">

						<div className="page-column">
							<h2>What is a LifeTimeLine?</h2>
							<p>A LifeTimeLine is an organised collection of Moments and information about a person.</p>
							<p>Moments are bits of content (initially just photos, but we are working on other content like videos too) that you can upload, document and share.
								Being able to describe the details of a Moment, who is involved, where and why it was taken, can add an important dimension to each Moment and
								your story as a whole.
								</p>
							<h2>What should I put in a Moment?</h2>
							<p>A Moment can be about anything. It might be an important event such as when you started a new job or moved into your first house.
								It might also be an everyday Moment such as you enjoying a ritual morning coffee in your kitchen.
								It's these Moments, anecdotal or defining, that so often get lost to time but can be a fascinating insight into your life.
								{/* If you are stuck for something to say, just say whatever you would to someone you are sharing the photo with in person.
								You can always come back and update it. */}
								</p>
							<h2>Who would be interested in my life?</h2>
							<p>Primarily, friends and family, as well as descendants - even ones you may never meet.
								Imagine being able to open a detailed pictorial account of the life of one of your great, great grandparents.
								But even if you have no family, we are all connected and everyone's story is important.
								Think of where you live or work.
								Was there someone there 50 or even 100 years ago?
								What was their life like?
								Who will be there 100 years from now?
								</p>
							<h2>Does it take long to build?</h2>
							<p>You can build your story over time.
								Set a goal of uploading a moment, say, every week or so.
								You'll be surprised how quickly you build up a meaningful archive.
								</p>
							<h2>How does it work?</h2>
							{/* <p className="highlight">You can tell your story now<br />and for generations to come.</p> */}
							<p>The LifeTimeLine.com platform allows you to upload and document a pictorial story of the Moments that make up your life experience,
								or that of someone close to you.
								It will publish your LifeTimeLine forever in a moderated, safe and importantly ad free environment.
								</p>
						</div>

						<div className="page-column">
							<h2>Why we built it.</h2>
							<p className="subtitle">Rawdyn Nutting - Co-Founder & CPO</p>
							<p>As my father aged and communication became more difficult, members of the family gradually took over various tasks for him.
								One of my roles was keeping him in touch with his old classmates from Royal Military College Duntroon from which he graduated in 1955.
								Duntroon was an incredibly important part of Dad's life.
								I noticed a very close camaraderie between the graduates that has lasted over 60 years and continues today.
								As part of my communications with the class, I have been privileged to hear many great stories.
								Along the way I was also saddened to see a few names disappear from the active registry.
								It struck me these stories are being lost forever and I was filled with a sense of urgency that we need a way to capture and share them.
								</p>
							<p>That is exactly what LifeTimeLine does.</p>
							<p>You can check out my Dad's LifeTimeLine <a href="/1">here</a> where I hope you will get a good idea of what this system can do.</p>
							<a href="/1"><img className="page-column--Image" src="https://cdn.inmemoriam.com/images/about-chester-nutting-facebook-share-preview-V03.png" alt="Chester Nutting LifeTimeLine" /></a>
						</div>

						<div className="page-column">
							<h2>Is it really online forever?</h2>
							<p>Our technical team are very experienced in delivering stable online software specifically designed to survive long periods of time
								with minimal intervention.
								We also have in place a proprietary business model with multiple levels of redundancy to ensure,
								as much as is possible, your data will be visible into the future - even if we go out of business.
								We have even gone so far as to making contingency plans should the internet ever stop existing. It sounds crazy, but we have planned for it.
								</p>
							<h2>How is it different?</h2>
							<p>We are a service provider to our end customer, you. Many other systems treat the user as an
								asset to sell by way of selling advertising or selling user data to business. We don't do any of that.
								We don't share your personal data with anyone.
								We have no ads anywhere. We exist to provide you a platform to tell your story and that's it.</p>

							<h2>What about pricing?</h2>
							<p>We work hard to run efficiently and deliver value to our users.
								We offer a range of payment options including a free one to get you started so you can be sure this is the right home for your story.
								We don't sell your private data or sell ad-space so all of our revenue is derived from our end user purchases.
								A large part of our model is to take a part of any profit and re-invest it into covering on-going hosting.
								Remember, when you buy outright we have to host it forever and that's a rather long time.
								</p>
							<h2>Product Roadmap</h2>
							<p>We have a vast feature roadmap which will keep us very busy for years to come.
								Without giving away too much, we can mention some of the items on our 'to-do' list.
								</p>
							<p>Genealogy Tool, Tagging, Collaboration, Gift Certificates, Video Moments, Messaging, Granular Privacy Controls, Moderation (internal and community),
								Alerts, Saved Searches, Watch for Changes to a LifeTimeLine, Image tools (crop, rotate etc.), print a book from a LifeTimeLine ... the list goes on.
								</p>


							{/* <h3>Broad Goals</h3>
							<p>All of the work we do is with some important goals in mind. The system must be <strong>Simple to Use</strong>.
								It must show <strong>Continued Improvements</strong> and remain as <strong>Affordable as Possible</strong>.
								As a product and business we must protect and further our market <strong>Integrity</strong> meaning no ads or data mining.
								We will strive to <strong>Continually improve security</strong> - such as proprietary copyright watermarking (in development).
								</p>

							<h3>Genealogy</h3>
							<p>Adding the ability to build a dynamic family tree and collaborate with others across branches and time.</p>

							<h3>Tagging</h3>
							<p>When mentioning people in your LifeTimeLine, we will be able to build a private reference list allowing you to easily flag people in your Moments.</p>

							<h3>Spans™</h3>
							<p>This will allow you to describe sections of your LifeTimeLine based on custom crtieria. For example, you might create Spans for the houses you lived in.</p>

							<h3>Collaboration</h3>
							<p>Adding the tools to allow multiple people to author and manage a LifeTimeLine including showing authors relationship to the subject so viewers
								understand the context of the information. Other tools allowing collaboration from family and friends will include advanced moderation
								tools to ensure LifeTimeLine remains a safe place to share memories.</p>

							<h3>Custom Domains and Custom URLs</h3>
							<p>When you create a LifeTimeLine you will be able to choose a permanent URL or domain for a small extra cost.
								This means that instead of sharing (say) lifetimeline.com/1 you could hsare lifetimeline.com/chester or even a
								domain of your own choice such as chester-nutting.com.</p>

							<h3>Gift Certificates</h3>
							<p>Adding the ability for you to buy a LifeTimeLine for a friend or family member.</p>

							<h3>Interactions</h3>
							<p>Will allow you to interact with LifeTimeLines and Moments to Like, bookmark, report, share, and comment.</p>

							<h3>Video</h3>
							<p>Being able to add Moments other than images. This will include video, documents and possible other content types.</p>

							<h3>Privacy Controls</h3>
							<p>This is a high priority item. Our design will allow you to control at a granular level who has access to
								view your LifeTimeLine record and when. For example, you may set it to (say) Friends and Family now and anyone in 100 years.</p>

							<h3>Messaging</h3>
							<p>Being able to send and receive messages about a LifeTimeLine in a secure and safe environment where you remain protected
								behind the LifeTimeLine system.</p>

							<h3>Moderation</h3>
							<p>Advanced moderation tools will allow trusted users to add content quickly and at the same time protect viewers from inappropriate content.</p>

							<h3>Alerts</h3>
							<p>Create alerts so when records matcing criteria you set are created or editied, we can notify you.</p>

							<h3>Saved Searches</h3>
							<p>Allows you to save searches you want to regularly run.</p>

							<h3>Subscribe to changes</h3>
							<p>Many LifeTimeLines are built over time. You can subscribe to a LifeTimeLine and be notified when changes are made.</p>

							<h3>Image Tools (rotate, crop etc)</h3>
							<p>Will allow you to make simple edits to images such as cropping, rotating etc.</p>

							<h3>Links</h3>
							<p>Linking LifeTimeLines such as when a person appears in a Moment in someone elses LifeTimeLine,
								a subtle link to that person's own LifeTimeLine is visible.</p>
*/}
						</div>

					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default About;
