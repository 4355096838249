import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Search from '../../components/Search';


import './SearchPage.scss';

class SearchPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

	}

	render() {

		return (
			<div className="search__page">
				<div className="page-content__wrapper">
					<div className="page-content page-content--search">
						<Search className="search-page" />
					</div>
				</div>
			</div>
		);
	}
};

export default SearchPage;
