import { sendFetchRequest, getApiContext } from './api_utils';

const fileURL = `${getApiContext()}/api/file`;

// The type argument is image, document, etc.
const upload = (type, file) => {
	const data = new FormData();

	// TASK: Tripple check FormData append is supported in IE10
	data.append('uploadedFile', file);

	const options = {
		method: 'POST',
		body: data,
	};

	return sendFetchRequest(`${fileURL}/${type}`, options);
};

export { upload };
