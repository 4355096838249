import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as timeLineAPI from '../../api/timeLineAPI';

import NewLifeTimeLine from '../../components/NewLifeTimeLine';

import RecordBox from '../../components/RecordBox/RecordBox';
import Spinner from '../../components/Spinner';

import { dispatchError } from '../../utils/general';

import './LifeTimeLines.scss';

class LifeTimeLines extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};


	}

	componentDidMount = () => {
		this._isMounted = true;

		timeLineAPI.getAll(this.props.profile.entityId)
			.then(resp => {
				console.log('HP.26 _isMounted = ', this._isMounted );
				if (!this._isMounted) {
					console.log('LTL.28 Canceled the subscription as the component has been un-mounted.');
					return null;
				}
				if (resp.success) {
					this.props.onSetLifeTimeLines(resp.data);
				}

				this.setState({ loading: false });
			})
			.catch((err) => {
				dispatchError(err);
				// STANDARD FETCH CONNECTION ERROR
				this.setState({ loading: false });
				this.props.onSetMessage({
					title: 			'Ooops!',
					message: 		'Please check your internet connection and try again.',
					description: 	'We could not connect to the service.',
					detail: 		'',
					actionEmail: 	false,
					id:				'LTL41'
				});
			});
	}

	componentWillUnmount = () => {
		this._isMounted = false;
		console.log('HP.48 _isMounted = ', this._isMounted );
	}

	onAddLifeTimeLine = (data) => {
		if (data.subjectEntityId) {
			this.props.onSetLifeTimeLine(data);
		}

		this.props.history.push('/author/new');
	};

	renderLoadingSpinner = (loading) => {
		if (!loading) {
			return null;
		}

		return (
			<div className="spinnerBox"><Spinner active={true} color="#999" /></div>
		);
	}

	renderLifeTimelines = (lifeTimeLines) => {
		if (!lifeTimeLines) {
			return null;
		}

		return Object.keys(lifeTimeLines).map(lifeTimeLineID => {
			const lifeTimeLine = lifeTimeLines[lifeTimeLineID];

			if (lifeTimeLine.ownerEntityId !== this.props.profile.entityId || lifeTimeLineID === 'new') {
				return null;
			}

			return (
				<RecordBox
					key={lifeTimeLineID}
					{...lifeTimeLine}
				/>
			);
		});
	};

	render() {
		const lifeTimeLines = this.renderLifeTimelines(this.props.lifeTimeLines);

		const loadingSpinner = this.renderLoadingSpinner(this.state.loading);

		return (
			<div className="lifeTimeLines__page">
				<div className="lifeTimeLines">
					<NewLifeTimeLine
						ownerEntityID={this.props.profile.entityId}
						onAddLifeTimeLine={this.onAddLifeTimeLine}
						title={`${this.props.profile.firstName || ''} ${this.props.profile.lastName || ''}`.trim()}
					/>
					{lifeTimeLines}
					{loadingSpinner}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		profile: state.profile,
		lifeTimeLines: state.lifeTimeLines,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetLifeTimeLines: (data) => {
		dispatch({
			type: 'SET_LIFETIMELINES',
			data,
		});
	},
	onSetLifeTimeLine: (data) => {
		dispatch({
			type: 'SET_LIFETIMELINE',
			data,
		});
	},
	onSetMessage: (data) => {
		dispatch({
			type: 'SET_MESSAGE',
			data,
		});
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LifeTimeLines);
