import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { brandConfig } from '../../api/brandConfig';

import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './PrivacyPolicy.scss';

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lifeTimeLines: [],
			loading: true,
		};


	}



	render() {

		return (
			<div className="privacy-policy__page">
				<div className="main-slider">
					<div className="main-slider__centred">
						<div className="main-slider__textBlock">
							<h1 className="compactSliderHeading">Privacy Policy</h1>
							<h1 className="compactSliderHeading">Terms & Conditions</h1>
							<h1 className="">Copyright Notice</h1>
							<p>We'll protect your private data and<br />only contact you when we need to.</p>
						</div>
						<Logo
							className="main-slider__logo"
							brand={brandConfig['lifetimeline'].brand}
							logoContext="mainSlider"
						/>
					</div>
				</div>
				<div className="page-content__wrapper">
					<div className="page-content smallBottomMargin">
						<div className="colspanThree">
							<p>When you use our services, you trust us with your information. The Privacy Policy, the Terms and Conditions and the
								Copyright Notice help you understand what data we collect, why we collect it, and what we do with it
								as well as your obligations and expectations when using our service. We’ve tried to keep it as simple as possible,
								but if you have any questions contact us.
								</p>
						</div>
					</div>
					</div>
				<div className="page-content__wrapper">
					<div className="page-content smallTopMargin">
						<div className="page-column">


<h1>Privacy Policy</h1>
<p className="subtleHighlight">Version 0.0.2</p>

	<h2>Information we collect</h2>
	<p>We collect information in the following ways:</p>

	<h3>Information you give us.</h3>
	<p>Such as when you to register or purchase something through our system.</p>
	<p>When you do, we’ll ask for personal information, like your name, email address, telephone number or credit card to store with your account.</p>

	<h3>Information we get from your use of our services.</h3>
	<p>We collect information about the services that you use and how you use them, like when you upload an image, or view and interact with our users and content.</p>
	<p>This information includes:</p>

	<h4>Device information</h4>
	<p>We collect device-specific information (such as your hardware model, operating system version, unique device identifiers,and mobile network information including phone number).</p>

	<h4>Log information</h4>
	<p>When you use our services or view content provided by us, we automatically collect and store certain information in server logs.</p>

	<h4>Local storage</h4>
	<p>We may collect and store information (including personal information) locally on your device using mechanisms such as browser web storage (including HTML 5) and application data caches.</p>

	<h4>Cookies and similar technologies</h4>
	<p>We may use various technologies to collect, store and protect your information. This may include using cookies or similar technologies to identify your browser or device.</p>

	<h2>How we use information we collect</h2>
	<p>We use the information we collect to provide, maintain, protect and improve them, to develop new ones, and to protect us and our users. We may also use this information to offer you tailored content – like giving you more relevant search results.</p>
	<p>If you have an Account, we may display your Profile name, Profile photo, and actions you take on our service, such as 'likes' etc., and comments you post and content you author.</p>
	<p>When you contact us, we keep a record of your communication to help solve any issues you might be facing. We may use your email address to inform you about our services, such as letting you know about upcoming changes or improvements.</p>
	<p>We use information collected from cookies and other technologies, like pixel tags, to improve your user experience and the overall quality of our services.</p>
	<p>We will ask for your consent before using information for a purpose other than those that are set out in this Privacy Policy.</p>
	<p>We process personal information on our servers in many countries around the world. We may process your personal information on a server located outside the country where you live.</p>

	<h2>Information you share</h2>
	<p>Many of our services let you share information with others. Remember that when you share information publicly, it may be indexable by search engines, including Google.</p>

</div>

<div className="page-column">

	<p className="subtleHighlight">Privacy Policy continued</p>

	<h2>Accessing and updating your personal information</h2>
	<p>Whenever you use our services, we aim to provide you with access to your personal information. If that information is wrong, we strive to give you ways to update it quickly or to delete it – unless we have to keep that information for legitimate business or legal purposes.</p>
	<p>We aim to maintain our services in a manner that protects information from accidental or malicious destruction. Because of this, after you delete information from our services, we may not immediately delete residual copies from our active servers and may not remove information from our backup systems.</p>

	<h2>Information we share</h2>
	<p>We do not share personal information with companies, organizations and individuals unless one of the following circumstances applies:</p>
	<p>By your explicit instruction</p>
	<p>We may share personal information with companies, organizations or individuals when you explicitly instruct us to do so.</p>
	<p>For legal reasons</p>
	<p>We will share personal information with companies, organizations or individuals if we have a good-faith belief that access, use, preservation or	disclosure of the information is reasonably necessary to:</p>
	<p>meet any applicable law, regulation, legal process or enforceable governmental request.</p>
	<p>enforce applicable Terms of Service, including investigation of potential violations.</p>
	<p>detect, prevent, or otherwise address fraud, security or technical issues.</p>
	<p>protect against harm to the rights, property or safety of us, our users or the public as required or permitted by law.</p>
	<p>If we are involved in a merger, acquisition or asset sale, we will continue to ensure the confidentiality of any personal information and give affected users notice before personal information is transferred or becomes subject to a different privacy policy.</p>

	<h2>Information security</h2>
	<p>We work hard to protect our systems and users from unauthorized access to or unauthorized alteration, disclosure or destruction of information we hold. In particular:</p>

	<h3>We encrypt many of our services using SSL.</h3>
	<p>We review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems.</p>

	<h3>When this Privacy Policy applies</h3>
	<p>Our Privacy Policy applies to all of the services offered by In Memoriam Group Pty Ltd.</p>

	<h3>Compliance and cooperation with regulatory authorities</h3>
	<p>We regularly review our compliance with our Privacy Policy. When we receive formal written complaints, we will contact the person who made the complaint to follow up.</p>
	<p>We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of personal data that</p>
	<p>we cannot resolve with our users directly.</p>

	<h2>Changes</h2>
	<p>Our Privacy Policy will change from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice 	(including, for certain services, email notification of privacy policy changes).</p>

</div>

<div className="page-column">

	<h1>Terms and Conditions</h1>
	<p className="subtleHighlight">Version 0.0.1</p>

		<h2>General</h2>
		<p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
		<p>We reserve the right to change these terms and conditions without notice by posting the changes to our Web site.</p>
		<p>We or you may terminate your account and remove your data from our database at any time for any reason.</p>

		<h2>Subscriptions</h2>
		<p>Some parts of the Service may be billed on a subscription basis ("Subscription(s)"). If you choose a subscription, you will be billed in advance on a recurring basis and may cancel at any time. </p>

		<h2>Acceptable Use</h2>
		<p>You will not use the system for any activity other than its intended design or any activity a reasonable person might describe as inappropriate.
			This includes (but is not limited to);
			</p>
			<ul>
				<li>Stalking, harrassing or abusing other users</li>
				<li>Making false or malicious claims</li>
				<li>Uploading content you do not have the rights to share</li>
				<li>Conduct or promote illeagal activities or activities not in keeping with our Modus Operandi (safe, respectful)</li>
				<li>Make any attemtps to circumvent security (including attempts to upload executabel code)</li>
				<li>or any other activity we deem to be inappropriate.</li>
			</ul>

		<h2>Your Claims</h2>
		<ul>
				<li>You understand and agree that you have appropriate rights all content you upload or enter into the system</li>
				<li>You grant us non-exclusive rights to display that content on your behalf at your request</li>
				<li>You have read and agree to all Terms and Conditions</li>
				<li>You have read, undeerstood and accept the Privacy Policy</li>
				<li>You have read, undeerstood and accept the Copyright Notice</li>
			</ul>

	<h1>Copyright Notice</h1>
	<p className="subtleHighlight">Version 0.0.1</p>
	<p>The LifeTimeLine Slide-bubble, LifeTimeLine name and the In Memoriam logo and other intellectual property remain the property of In Memoriam Group or their original owner.</p>
	<p>All content is protected under copyright law and may not be used for any purpose without express written permission.</p>

</div>


					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default PrivacyPolicy;
