import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as util from '../../utils/general';

import UserMenu from '../UserMenu/UserMenu';
import Logo from '../Brand/Logo';
import Search from '../Search';

import './Header.scss';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userMenuActive: false,
		};
	}

	toggleUserMenu = () => {
		this.setState({
			userMenuActive: !this.state.userMenuActive,
		});
	};

	renderRightMenu = (isResponsive, isSignedIn, cartItems) => {
		const notifyCount = cartItems.length;
		let notifyCountDisplay = notifyCount;
		let notifyClassName = 'badge';
		if (notifyCount > 99) {
			notifyCountDisplay = '99+';
			notifyClassName += ' badge-lg';
		}

		if (isSignedIn) {
			return (
				<div className="right-menu">
					{!isResponsive &&
						<Link
							to="/cart"
							className="right-menu__cart"
						>
							<i className="fa fa-shopping-cart">
								{notifyCount > 0
									? <span className={notifyClassName}>{notifyCountDisplay}</span>
									: ''
								}
							</i>
						</Link>
					}
				</div>
			);
		}

		return (
			<div className="right-menu">
				{isResponsive &&
					<Link
						to="/search"
						className="global__button right-menu__search"
					>
						Search
					</Link>
				}
				<Link
					to="/sign-in"
					className="global__button right-menu__button"
					>
					Sign in
				</Link>
				<Link
					to="/register"
					className="global__button global__button--blue right-menu__button"
					>
					Register
				</Link>
			</div>
		);
	};

	renderLeftUserMenu(isResponsive, isSignedIn) {
		if (!isSignedIn) {
			if (isResponsive) {
				return null;
			} else {
				return <div className="left-menu left-menu__empty"></div>;
			}
		}

		var notifyCount = 0;
		var notifyCountDisplay = notifyCount;
		var notifyClassName = 'badge';
		if (notifyCount > 99) {
			notifyCountDisplay = '99+';
			notifyClassName += ' badge-lg';
		}

		return (
			<div className="left-menu" onClick={this.toggleUserMenu}>
				<i className="fa fa-bars fa-2x">
					{notifyCount > 0
						? <span className={notifyClassName}>{notifyCountDisplay}</span>
						: ''
					}
				</i>
				<UserMenu
					userMenuActive={this.state.userMenuActive}
					onCloseHandler={this.toggleUserMenu}
				/>
			</div>
		);
	}

	render () {
		var {
			profile,
			audience,
			global,
			cart,
		} = this.props;

		const isResponsive = global.breakpoint.level <= util.breakpoints['tablet'];

		return (
			<div className="header" id="header">
				<div className="header__leftMenuAndBrand">
					{this.renderLeftUserMenu(isResponsive, profile.isSignedIn)}

					<Link to="/" className="brand">
						<Logo brand={audience.brand} logoContext="default"/>
						{audience.brand.displayName && <div className="brand__title">{audience.brand.name}</div>}
					</Link>
				</div>
				{!isResponsive && <Search />}
				{this.renderRightMenu(isResponsive, profile.isSignedIn, cart.items)}
			</div>
		);
	}
}

// make the sign in: key's data (within the global state), prop(s) to this component
const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
		global: state.global,
		cart: state.cart,
	};
}

export default connect(
	mapStateToProps,
)(Header);
