import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from '../../components/Footer';
import ProductPanel from '../../components/ProductPanel';

import * as productAPI from '../../api/productAPI';

import './Products.scss';

class Products extends Component {
	state = {
		products: [
			{
				productId: 'new',
				name: 'Try it for FREE!',
				baseProductCode: 'NEW',
				description: 'Try LifeTimeLine for free! Publish up to five Moments but you can create as many as you like.',
				price: 0,
				features: [
					'Create Unlimited Moments',
					'Publish up to 5 Moments',
					'No Ads Ever',
					'Published Forever',
					'Upgrade at any time',
				],
			},
		],
	};

	constructor(props) {
		super(props);

		productAPI.getByPartnerID(props.audience.partnerID)
			.then(resp => {
				if (resp.success) {
					this.setState({
						products: [
							...this.state.products,
							...resp.data,
						],
					});
				}
			});
	}

	render() {
		const {
			products,
		} = this.state;

		if (products.length === 1) {
			return null;
		}

		const content = products.map((product, i) => {
			const isSubProduct = products.some((p) => {
				if (p.baseProductCode === product.baseProductCode) {
					return false;
				}

				const timePeriod = p.baseProductCode.slice(-1);

				return timePeriod !== 'A' && product.baseProductCode.lastIndexOf(p.baseProductCode.slice(0, -1), 0) === 0;
			});

			if (isSubProduct) {
				return null;
			}

			const relatedProducts = products.filter(p => {
				if (!p.baseProductCode || p.baseProductCode === product.baseProductCode) {
					return false;
				}

				return p.baseProductCode.lastIndexOf(product.baseProductCode.slice(0, -1), 0) === 0;
			});

			return (
				<ProductPanel
					key={`product-${i}`}
					product={product}
					relatedProducts={relatedProducts}
					history={this.props.history}
				/>
			);
		});

		return (
			<div className="products-wrapper">
				<div className="products">
					{content}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	audience: state.audience,
});

export default connect(
	mapStateToProps,
)(Products);
