import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Image from '../../components/Form/Image';
import Button from '../../components/Form/Button';
import Backdrop from '../../components/Backdrop';
import NewLifeTimeLine from '../../components/NewLifeTimeLine/NewLifeTimeLine';
import Payment from '../../components/Payment';

import * as cartAPI from '../../api/cartAPI';
import * as timeLineAPI from '../../api/timeLineAPI';

import { formatMoney, breakpoints } from '../../utils/general';
import { censorCreditCardNumber } from '../../utils/card';
import * as defer from '../../utils/defer';

import './Cart.scss';

class Cart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			total: this.calculateTotal(props.cart.items),
			activeDetailIndex: null,
			openOptions: [],
			newLifeTimeLineModal: null,
			isPaymentFormOpen: false,
			invoice: null,
		};

		if (Object.keys(this.props.lifeTimeLines).length === 0) {
			timeLineAPI.getAll(props.profile.entityId)
				.then(resp => {
					if (resp.success) {
						this.props.onSetLifeTimeLines(resp.data);
					}
				});
		}
	}

	clearCart = this.props.onSetConfirm;

	componentWillReceiveProps(nextProps) {
		const total = this.calculateTotal(nextProps.cart.items);
		if (this.state.total !== total) {
			this.setState({
				total,
				isPaymentFormOpen: this.state.isPaymentFormOpen && total !== 0,
			});
		}
		/* checkChangedOption Part 1 of 2 :: This was set up to check when the option selected in an open options panel was changed to change teh colour of the 'Done' button to blue.
		if (this.state.openOptions[0]) {
			console.log('GET PROP', this.getCartItemSelected(this.state.openOptions[0], this.props.cart.items));
			console.log('GET NEXT', this.getCartItemSelected(this.state.openOptions[0], nextProps.cart.items));
		}
		*/
	}
	/* checkChangedOption Part 2 of 2 :: This was set up to check when the option selected in an open options panel was changed to change teh colour of the 'Done' button to blue.
	getCartItemSelected = (targetCartItemId, cart) => {
		return cart.filter(obj => obj.cartItemId === targetCartItemId)[0].optionResponses[0].data;
	}
	*/

	calculateTotal = (items) => items.reduce((total, item) => (total + item.price), 0);

	removeItem = (cartItemId) => {
		cartAPI.deleteCartItem(cartItemId)
			.then((resp) => {
				if (resp.success) {
					this.props.removeItem(cartItemId);
				}
			});
	};

	renderIconOrImage = (item) => {
		// baseProductTypes = [
		// 	LTL,
		// 	IM,
		// 	URL,
		// 	SDOM,
		// 	DOM,
		// 	RES,
		// 	BK,
		// 	DVD,
		// 	PCRD
		// ];
		const productIcons = {
			LTLFREE: 	'fa-clock-o',
			LTLSUBM: 	'fa-clock-o',
			LTLSUBA: 	'fa-clock-o',
			LTLSUBMLTD: 'fa-clock-o',
			LTLSUBALTD: 'fa-clock-o',
			LTLSUBUG: 	'fa-clock-o',
			LTLFULL: 	'fa-clock-o',
			LTLFULL5: 	'fa-clock-o',

			IM1: 		'fa-id-badge',
			IM5: 		'fa-id-badge',

			LTLURL: 	'fa-map-marker',
			IMURL:	 	'fa-map-marker',

			LTLSDOM: 	'fa-map-marker',
			IMSDOM: 	'fa-map-marker',

			LTLDOM: 	'fa-map-marker',
			IMDOM: 		'fa-map-marker',

			LTLRES: 	'fa-thumb-tack',
			IMRES: 		'fa-thumb-tack',

			LTLBK: 		'fa-book',

			IMDVDOFF: 	'fa-video',
			IMDVDON: 	'fa-video',

			IMPCRD50: 	'fa-envelope-o',
		};

		const lifeTimeLine = item.optionResponses && item.optionResponses.length > 0 ? this.props.lifeTimeLines[parseInt(item.optionResponses[0].data, 10)] : null;

		if (lifeTimeLine && lifeTimeLine.image) {
			return (
				<Image
					className="cart-item__image"
					image={lifeTimeLine.image}
				/>
			)
		} else if (item.product && item.product.baseProductCode) {
			return <i className={"fa "+ productIcons[item.product.baseProductCode]} />
		} else {
			return <i className="fa fa-globe" />;
		}
	};

	renderOptionsLink = (item, isInvoiceMode) => {
		if (!item.options || isInvoiceMode) {
			return null;
		}

		switch(item.product.baseProductCode) {
			case 'Non-settings-product-code':
				break;
			default:
				const isOpen = this.state.openOptions.findIndex(option => option === item.cartItemId) !== -1;

				const toggleClasses = [ 'cart-item__options-toggle' ];
				if (isOpen) {
					toggleClasses.push('cart-item__options-toggle--open');
				}

				return (
					<span
						className={toggleClasses.join(' ')}
						onClick={() => this.onToggleOption(item.cartItemId)}
					>
						(set&nbsp;options)
					</span>
				);
		}
	};

	renderDescription = (index, description, isMobile_L, isInvoiceMode) => {
		if (isMobile_L || isInvoiceMode) {
			return null;
		}

		const isOpen = this.state.activeDetailIndex === index;

		const popupClasses = [ 'description__popup' ];
		const caretClasses = [ 'fa' ];

		if (isOpen) {
			popupClasses.push('description__popup--open');
			caretClasses.push('fa-caret-down');
		} else {
			caretClasses.push('fa-caret-up');
		}

		return (
			<div className="description" onClick={() => this.onToggleDescription(index)}>
				Details <i className={caretClasses.join(' ')} />
				<Backdrop className="description__backdrop" isOpen={isOpen} />
				<div className={popupClasses.join(' ')}>
					{description}
					<div className="description__close">
						Close <i className="fa fa-close" />
					</div>
				</div>
			</div>
		);
	};

	onToggleDescription = (index) => this.setState({
		activeDetailIndex : this.state.activeDetailIndex === null ? index : null,
	});

	onToggleOption = (cartItemId) => {
		if (!cartItemId) {
			return;
		}

		const openOptions = this.state.openOptions.slice();
		const index = openOptions.findIndex(option => option === cartItemId);

		if (index === -1) {
			openOptions.push(cartItemId);
		} else {
			openOptions.splice(index, 1);
		}

		this.setState({ openOptions });
	};

	renderItems = (items, isInvoiceMode) => {
		if (!items) {
			return null;
		}

		const isMobile_L = this.props.global.breakpoint.level <= breakpoints['mobile-l'];
		const content = items.map((item, i) => {
			const taxRate = 0.1;
			const itemPrice = (item.price/ (100 * (taxRate+1) ) );
			const itemTax 	= (item.price - (item.price/(taxRate+1))) /100;
			const itemTotal = (item.price/100);

			// TASK: In the future there may be multiple responses, so we will need to find a better solution than 0 index
			const selectedResponse = item.optionResponses && item.optionResponses.length > 0 ? item.optionResponses[0] : [];

			const selectedChoice = item.optionChoices && item.optionChoices.length > 0 ? item.optionChoices.find(choice => choice.baseProductOptionId === selectedResponse.baseProductOptionId && choice.value === selectedResponse.data) : "";

			return (
				<Fragment key={`cart-item-${item.cartItemId}`}>
					<div className="cart-item">
						<div className="cart-item__title">
							<div className="cart-item__title--iconBox">
								{this.renderIconOrImage(item)}
							</div>
							<div className="cart-item__title--titleBox">
								{item.product.name} {selectedChoice && `for ${selectedChoice.displayText}`} {this.renderOptionsLink(item, isInvoiceMode)}

								{this.renderDescription(
									i,
									item.product.description,
									isMobile_L,
									isInvoiceMode,
								)}
							</div>
						</div>
						<div className="cart-item__col cart-item__col--right">{formatMoney(itemPrice)}</div>
						<div className="cart-item__col cart-item__col--right">{formatMoney(itemTax)}</div>
						<div className="cart-item__col cart-item__col--right">{formatMoney(itemTotal,0)}</div>
						{isInvoiceMode ? null :
							<div
								className="cart-item__col cart-item__remove"
								onClick={() => this.removeItem(item.cartItemId)}
							>
								{isMobile_L ? <i className="fa fa-minus-circle" /> : 'remove item'}
							</div>
						}
					</div>
					{this.renderItemOptions(item, selectedResponse, isInvoiceMode)}
				</Fragment>
			);
		});

		return content;
	};

	renderItemOptions = (item, selectedResponse, isInvoiceMode) => {
		const {
			cartItemId,
			optionChoices,
		} = item;

		const baseProductOptionId = item.options[0] && item.options[0].baseProductOptionId;

		if (!optionChoices || isInvoiceMode || this.state.openOptions.findIndex(option => option === cartItemId) === -1) {
			return null;
		}

		const selectedChoices = this.props.cart.items.reduce((result, item) => {
			if (!item.optionResponses || item.optionResponses.length === 0 || item.optionResponses[0].data === selectedResponse.data) {
				return result;
			}

			return [
				...result,
				item.optionResponses[0].data,
			];
		}, []);

		return (
			<div className="option">
				{optionChoices.map((choice, i) => {
					const choiceClasses = [ 'choice' ];
					const isActive = choice.baseProductOptionId === selectedResponse.baseProductOptionId && choice.value === selectedResponse.data;
					if (isActive) {
						choiceClasses.push('choice--active');
					}

					const isInactive = selectedChoices.findIndex(selectedChoice => selectedChoice === choice.value) !== -1;
					if (isInactive) {
						choiceClasses.push('choice--inactive');
					}

					const lifeTimeLine = this.props.lifeTimeLines[parseInt(choice.value, 10)];

					return (
						<div className={choiceClasses.join(' ')} key={`cart-item-${cartItemId}-choice-${i}`}
							onClick={!isActive && !isInactive ? () => this.submitChoice(
								cartItemId,
								baseProductOptionId,
								choice.value,
								choice.displayText,
								choice.displayOrder,
							): null}
							>
							<div className="choice__icon">
								{lifeTimeLine &&
									<Image
										className="choice__image"
										image={lifeTimeLine.image}
									/>
								}
							</div>
							<div className="choice__text">
								{choice.displayText}
							</div>
							<i
								className="fa fa-check-circle choice__tick"
							/>
							{isInactive && <div className="choice__inactive-tooltip">
								This has already been selected on another item in your cart.
							</div>}
							<div className="cart-title__item cart-item__col--right"></div>
							<div className="cart-title__item cart-item__col--right"></div>
							<div className="cart-title__item cart-item__col--right"></div>
						</div>
					);
				})}
				<div className="choice">
					<div className="choice__icon">
						<i className="fa fa-paper-plane choice__new" />
					</div>
					<div className="choice__text">
						Create a new LifeTimeLine.
					</div>
					<i
						className="fa fa-check-circle choice__tick"
						onClick={() => this.setNewLifeTimeLineModal({ cartItemId, baseProductOptionId })}
					/>
					<div className="cart-title__item cart-item__col--right"></div>
					<div className="cart-title__item cart-item__col--right"></div>
					<div className="cart-title__item cart-item__col--right"></div>
				</div>
				<div className="choice">
					<div className="choice__icon"></div>
					<div className="choice__text"></div>
					<div className="cart-title__item cart-item__col--right">
						<Button
							className="option__button"
							onClick={() => this.onToggleOption(cartItemId)}
							color="midGrey"
							>Done
						</Button>
					</div>
					<div className="cart-title__item cart-item__col--right"></div>
					<div className="cart-title__item cart-item__col--right"></div>
					<div className="cart-title__item cart-item__col--right"></div>
				</div>
			</div>
		);
	};

	onAddLifeTimeLine = (data) => {
		const {
			cartItemId,
			baseProductOptionId,
		} = this.state.newLifeTimeLineModal;

		if (data) {
			// Delete the 'new' timelineId
			delete(data.timelineId);
			timeLineAPI.create(data)
				.then(resp => {
					if (resp.success) {
						this.submitChoice(cartItemId, baseProductOptionId, resp.data.timelineId, resp.data.title, 0);

						this.props.onSetLifeTimeLine(resp.data);
					} else {
						// TASK: Handle error
					}
				});
		}
	};

	submitChoice = (cartItemId, baseProductOptionId, lifeTimeLineID, displayText, displayOrder) => {
		cartAPI.submitChoice(
			cartItemId,
			baseProductOptionId,
			lifeTimeLineID,
		)
		.then((resp) => {
			if (resp.success) {
				this.props.setCartOptionChoice({
					...resp.data,
					displayText,
					displayOrder,
				});
				this.setNewLifeTimeLineModal(null);
			} else {
				// TASK: Handle error
			}
		});
	};

	renderNewLifeTimeLineModal = () => {
		return (
			<Backdrop isOpen={this.state.newLifeTimeLineModal !== null} onClose={() => this.setNewLifeTimeLineModal(null)}>
				<NewLifeTimeLine
					isCancelable={true}
					onCancel={() => this.setNewLifeTimeLineModal(null)}
					ownerEntityID={this.props.profile.entityId}
					title={`${this.props.profile.firstName || ''} ${this.props.profile.lastName || ''}`.trim()}
					onAddLifeTimeLine={this.onAddLifeTimeLine}
				/>
			</Backdrop>
		);
	};

	setNewLifeTimeLineModal = (newLifeTimeLineModal) => this.setState({ newLifeTimeLineModal });

	togglePaymentForm = (isPaymentFormOpen) => {
		if (!isPaymentFormOpen || this.state.total > 0) {
			this.setState({ isPaymentFormOpen });
		}
	};

	onSubmitPayment = (resp, payload) => {
		this.setState({
			invoice: {
				date: new Date(resp.data.processedTimestamp).toLocaleDateString('en-GB'),
				cardNumber: censorCreditCardNumber(payload.CreditCardNumber),
				cardType: "Credit Card",
				// TASK: Hack to make the invoice view work without the API
				items: this.props.cart.items,
			},
		});

		defer.get('PUBLISH_TIMELINE_ON_PAYMENT').forEach(lifeTimeLineID => {
			const lifeTimeLine = this.props.lifeTimeLines[lifeTimeLineID];

			const payload = {
				...lifeTimeLine,
				published: true,
			};

			// TASK: Add UI to make it clear which LifeTimeLine's have been published
			timeLineAPI.update(payload)
				.then(resp => {
					if (!resp.success) {
						return;
					}
					this.props.onSetLifeTimeLine(payload);
				});
		});

		// Clear cart and get a new one
		cartAPI.getByPartnerID(this.props.audience.partnerID)
			.then(resp => {
				if (!resp.success) {
					return;
				}
				this.props.setCartItems(resp.data.cartId, resp.data.cartItems);
			});
	};

	// TASK: Will need to be split out into its own page once the API is in place
	renderInvoice = (invoice) => {
		if (!invoice) {
			return null;
		}

		return (
			<div className="cart-invoice">
				<div className="cart-invoice__title"><i className="fa fa-check-circle" /> Payment Successful</div>
				<div className="cart-invoice__text">Payment Processed: {invoice.date}</div>
				<div className="cart-invoice__text">Payment Method: {invoice.cardType}: {invoice.cardNumber}</div>
				<div className="cart-invoice__notice">
					<strong>Important!</strong> This transaction will appear in your statement as <span className="cart-invoice__highlight">InMemoriamGroup</span>
				</div>
			</div>
		);
	};

	render() {
		const {
			items,
		} = this.props.cart;

		const {
			isPaymentFormOpen,
			invoice,
		} = this.state;

		const isInvoiceMode = invoice !== null;

		// TASK: Hack to make the invoice view work without the API
		const content = this.renderItems(isInvoiceMode ? invoice.items : items, isInvoiceMode);

		const hasItems = items.length > 0;
		const total = isInvoiceMode ? this.calculateTotal(invoice.items) : this.state.total;
		const totalScale =  total % 100 > 0 ? 2 : 0;
		const totalDisplay = formatMoney(total/100,totalScale);

		const isSubscriptionPayment = !!items.find(item => item.product.baseProductCode.substr(3, 3) === 'SUB');

		return (
			<Fragment>
				<div className="cart-wrapper">
					<div className="cart">
						<div className="cart-table">
							<div className="cart-title">
								<div className="cart-title__item">
									{isInvoiceMode ? 'Invoice' : 'Shopping Cart'}
								</div>
								<div className="cart-title__item cart-item__col--right">
									Price
								</div>
								<div className="cart-title__item cart-item__col--right">
									Tax
								</div>
								<div className="cart-title__item cart-item__col--right">
									Total
								</div>
								{!isInvoiceMode && <div className="cart-title__item cart-item__col--right" />}
							</div>
							{content}
							<div className="cart-item cart-item--totals">
								<div className="cart-item__title"></div>
								<div className="cart-item__col cart-item__col--right"></div>
								<div className="cart-item__col cart-item__col--right">Total</div>
								<div className="cart-item__col cart-item__col--right">{totalDisplay}</div>
								<div className="cart-item__col cart-item__remove"></div>
							</div>
						</div>
						{hasItems && !isInvoiceMode &&
							<div className="cart-buttons">
								<Button
									className="cart-buttons__button cart-buttons__button--first"
									color="red"
									onClick={this.clearCart}
								>
									<i className="fa fa-warning cart-buttons__icon" />
									Clear Cart
								</Button>
								<div className="cart-buttons__right">
									{/* <Button
										className="cart-buttons__button"
										color="midGrey"
									>
										Pay with PayPal
									</Button> */}
									<Button
										className="cart-buttons__button cart-buttons__button--last"
										color="blue"
										onClick={() => this.togglePaymentForm(true)}
									>
										Pay with Card
									</Button>
								</div>
							</div>
						}
						{isPaymentFormOpen && !isInvoiceMode &&
							<Payment
								amount={total}
								isSubscriptionPayment={isSubscriptionPayment}
								onCancel={() => this.togglePaymentForm(false)}
								partnerID={this.props.audience.partnerID}
								onSubmit={this.onSubmitPayment}
							/>
						}
						{this.renderInvoice(invoice)}
					</div>
				</div>
				{this.renderNewLifeTimeLineModal()}
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	global: state.global,
	cart: state.cart,
	audience: state.audience,
	profile: state.profile,
	lifeTimeLines: state.lifeTimeLines,
});

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		removeItem: (cartItemId) => {
			dispatch({
				type: 'REMOVE_CART_ITEM',
				data: {
					cartItemId,
				},
			});
		},
		setCartOptionChoice: (data) => {
			dispatch({
				type: 'SET_CART_OPTION_CHOICE',
				data,
			});
		},
		onSetLifeTimeLine: (data) => {
			dispatch({
				type: 'SET_LIFETIMELINE',
				data,
			});
		},
		onSetLifeTimeLines: (data) => {
			dispatch({
				type: 'SET_LIFETIMELINES',
				data,
			});
		},
		setCartItems: (cartID, data) => {
			dispatch({
				type: 'SET_CART_ITEMS',
				cartID,
				data,
			});
		},
		onSetConfirm: (data) => {
			dispatch({
				type: 'SET_MODAL',
				data: {
					type: 'clear-cart',
				},
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Cart);