import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PageMenu from '../PageMenu/PageMenu';
import PageMenuItem from '../PageMenu/PageMenuItem';
// import SettingsPanel from '../SettingsPanel';

import * as productAPI from '../../api/productAPI';
import * as timeLineAPI from '../../api/timeLineAPI';
import * as cartAPI from '../../api/cartAPI';
import * as util from '../../utils/general';
import * as defer from '../../utils/defer';

import Button from '../Form/Button';
import { formatMoney } from '../../utils/general';

import './AuthorMenu.scss';

class AuthorMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isPublishPanelOpen: false,
			isPrivacyPanelOpen: false,
			products: [],
		};
	}

	togglePublish = () => {
		const payload = {
			...this.props.lifeTimeLine,
			published: !this.props.lifeTimeLine.published,
		};

		timeLineAPI.update(payload)
			.then(resp => {
				if (resp.success) {
					this.props.onSetLifeTimeLine({
						...this.props.lifeTimeLine,
						published: resp.data.published
					});
				} else {
					// TASK: Handle error - publish failed
				}
				this.setIsPublishPanelOpen(false);
			});
	};

	setIsPublishPanelOpen = isOpen => {
		productAPI.getByPartnerID(this.props.partnerID)
			.then(resp => {
				if (resp.success) {
					this.setState({
						isPublishPanelOpen: isOpen,
						isPrivacyPanelOpen: false,
						products: resp.data,
					});
				}
			});
	};

	setIsPrivacyPanelOpen = isOpen => {

		productAPI.getByPartnerID(this.props.partnerID)
			.then(resp => {
				if (resp.success) {
					this.setState({
						isPrivacyPanelOpen: isOpen,
						isPublishPanelOpen: false,
						products: resp.data,
					});
				}
			});
	};

	renderPublishPanel = () => {
		if (!this.state.isPublishPanelOpen) {
			return null;
		}

		return (
			<div className="panel-wrapper">
				<div className="panel">
					<div className="panel__title">
						Choose a Product that Suits You
					</div>
					<div className="panel__row">
						<div className="panel__product">
							<div className="panel__text">
								<div>Try it for FREE!</div>
								<div>Publish 5 Moments for free!</div>
							</div>
						</div>
						<Button
							onClick={this.togglePublish}
							color="blue"
							className="panel__button"
						>
							Publish Now
						</Button>
					</div>
					{this.state.products.map(product => (
						<div className="panel__row" key={`ltl-product-${product.productId}`}>
							<div className="panel__product">
								<div className="panel__text">
									<div>{product.name}</div>
									<div>{product.features[3]}</div>
								</div>
							</div>
							<Button
								onClick={() => this.onBuyNow(product.productId)}
								color="blue"
								className="panel__button"
							>
								{formatMoney(product.price/100, 0)}{product.baseProductCode !== 'LTLFULL' && "/m"} Buy Now
							</Button>
						</div>
					))}
					<Link to="/products" className="panel__link">
						View and compare all product options here.
					</Link>
					<div className="panel__row">
						<Button
							onClick={() => this.setIsPublishPanelOpen(false)}
							className="panel__cancel-button"
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		);
	};

	onBuyNow = (productId) => {
		cartAPI.addCartItem({
			cartId: this.props.cartID,
			productId: productId,
			quantity: 1,
		})
		.then(resp => {
			if (!resp.success) {
				// TASK: Handle error
				return;
			}

			this.props.addCartItem(resp.data);

			if (resp.data.options.length === 0) {
				defer.put(
					'PUBLISH_TIMELINE_ON_PAYMENT',
					this.props.lifeTimeLineID,
				);
				this.props.history.push('/cart');
				return;
			}

			cartAPI.submitChoice(
				resp.data.cartItemId,
				resp.data.options[0].baseProductOptionId,
				this.props.lifeTimeLineID,
			)
			.then((resp) => {
				if (resp.success) {
					this.props.setCartOptionChoice({
						...resp.data,
						displayText: this.props.lifeTimeLine.title,
						displayOrder: 0,
					});

					defer.put(
						'PUBLISH_TIMELINE_ON_PAYMENT',
						this.props.lifeTimeLineID,
					);
					this.props.history.push('/cart');
				} else {
					// TASK: Handle error
				}
			});
		});
	};

	render () {
		if (this.props.lifeTimeLineID === 'new') {
			return null;
		}

		const {
			isPublishPanelOpen,
			// isPrivacyPanelOpen,
		} = this.state;

		let publishIsActive = !!this.props.lifeTimeLine.published;
		let publishLabel = 'Publish';
		let onClickPublishHandler = () => this.setIsPublishPanelOpen(!isPublishPanelOpen);

		if (publishIsActive) {
			publishLabel = 'Published';
		}

		if (publishIsActive || this.props.lifeTimeLine.orderItemId > 0) {
			onClickPublishHandler = this.togglePublish;
		}

		const isTabletAndBelow = this.props.breakpoint.level <= util.breakpoints['tablet'];
		//const isMobileAndBelow = this.props.breakpoint.level <= util.breakpoints['mobile-l'];

		return (
			<PageMenu className="pageMenu pageMenu--lifeTimeLine" id="lifeTimeLineMenuContent">
				<PageMenuItem
					isOn={false}
					isHighlighted={true}
					isActive={false}
					linkTo={this.props.buildURL(this.props.author, 'new')}
					iconClassName={isTabletAndBelow ? "fa fa-plus-circle" : "fa fa-clock-o"}
					label={isTabletAndBelow ? "Moment" : "Add a Moment"}
				/>
				<PageMenuItem
					isOn={false}
					isHighlighted={false}
					isActive={false}
					onClick={this.props.editMode ? this.props.handleCancelEdit : this.props.toggleEditMode}
					iconClassName={`fa fa-${this.props.editMode ? 'ban' : 'pencil'}`}
					label={this.props.editMode ? 'Cancel Edit' : 'Edit Details'}
				/>
				{/* <PageMenuItem
					isOn={false}
					isHighlighted={false}
					isActive={false}
					iconClassName="fa fa-cog"
					label="Settings"
					isOpen={isPrivacyPanelOpen}
					onClick={() => this.setIsPrivacyPanelOpen(!isPrivacyPanelOpen)}
				>
					<SettingsPanel
						isOpen={isPrivacyPanelOpen}
						onClose={() => this.setIsPrivacyPanelOpen(false)}
					/>
				</PageMenuItem> */}
				<PageMenuItem
					isOn={false}
					isHighlighted={false}
					isActive={publishIsActive}
					onClick={onClickPublishHandler}
					iconClassName="fa fa-bullhorn"
					label={publishLabel}
					isOpen={isPublishPanelOpen}
				>
					{this.props.lifeTimeLine.published && <div><i className="fa fa-check-circle"></i></div>}
					{this.renderPublishPanel()}
				</PageMenuItem>
			</PageMenu>
		);
	}
}

export default AuthorMenu;
