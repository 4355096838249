// Fires func at most once per interval
const throttle = (func, interval = 200) => {
	let first = true;
	let waiting = false;

	return () => {
		if (first) {
			func();
			first = false;
			return;
		}

		if (!waiting) {
			waiting = true;
			window.setTimeout(() => {
				func();
				waiting = false;
			}, interval);
		}
	};
};

// Fires func once it hasn't been called for at least interval
const debounce = (func, interval = 200, leading = true) => {
	let first = leading;
	let bounceID = null;

	return () => {
		if (first) {
			func();
			first = false;
			return;
		}

		if (bounceID !== null) {
			window.clearTimeout(bounceID);
		}
		bounceID = window.setTimeout(func, interval);
	};
};

const breakpoints = {
	'mobile-s': 1,
	'mobile-m': 2,
	'mobile-l': 3,
	'mobile-landscape': 4,
	'tablet': 5,
	'desktop-s': 6,
	'desktop-m': 7,
	'desktop-l': 8,
	'wide': 9,
}

// Mirrors styles/media.scss
const getBreakpoint = () => {
	if (window.matchMedia('(max-width: 20em)').matches) {  // 320px
		return 'mobile-s';
	} else if (window.matchMedia('(max-width: 23.4375em)').matches) {  // 375px
		return 'mobile-m';
	} else if (window.matchMedia('(max-width: 30em)').matches) {  // 480px
		return 'mobile-l';
	} else if (window.matchMedia('(max-width: 48em) and (orientation: landscape)').matches) {  // 768px
		return 'mobile-landscape';
	} else if (window.matchMedia('(max-width: 48em)').matches) {  // 768px
		return 'tablet';
	} else if (window.matchMedia('(max-width: 51.5625em)').matches) {  //  825px
		return 'desktop-s';
	} else if (window.matchMedia('(max-width: 64.0625em)').matches) {  // 1025px
		return 'desktop-m';
	} else if (window.matchMedia('(max-width: 90em)').matches) {  // 1440px
		return 'desktop-l';
	} else if (window.matchMedia('(max-width: 160em)').matches) {  // 2560px
		return 'wide';
	}

	return 'desktop-m';
}

const getDateRange = (fromYear, toYear, fixedMenuItemCount) => {
	const years = [];

	const endYearInt = (toYear !== '' && toYear !== 0) ? parseInt(toYear,10) : new Date().getFullYear();
	const startYearInt = (fromYear !== '' && fromYear !== 0) ? parseInt(fromYear,10) : 0;//endYearInt-10;
	const fixedMenuItemCountInt = (fixedMenuItemCount && fixedMenuItemCount !== '') ? parseInt(fixedMenuItemCount,10) : 0; // used for mobile date menu that does noto use the page height.

	// If no start year provided (end year should always exist as it's either provided or set to this year)
	if (startYearInt===0) {
		return [endYearInt];
	}

	const h = window.innerHeight*0.95;			// number of pixels to display menu
	const y = endYearInt-startYearInt;			// number of years in the timeline (startYearInt will be a value as if zero, we return out earlier)
	const mih = 25;								// Menu Item Height : pixel height allowed for each menu item
	const mmi = h / mih;						// Maximum Menu Items : Number of menu items to totally fill available height
	const mic = 0.6;							// Menu Item Concentration : Desired concentration of menu items in available space
	let gap = ( y / (mmi*mic) );				// fractional steps between menu items (e.g. 2.78316 years)

	if (fixedMenuItemCountInt > 0) {
		gap = ( y / fixedMenuItemCountInt );	// Allow mobile version to force menu item count as it does notp use page hight as limiting factor
	}

	const minGap = gap < 1 ? 1 : gap; 			// Ensure the step is at least 1 year. Do not round here. We want the gap to increment including fractions of a year.

	let year;
	let roundedYear
	for (year = startYearInt; year <= endYearInt; year += minGap) {
		roundedYear = Math.round(year);			// round only the year we put in the array. Keep the counter more granular
		years.push(roundedYear);
	}
	if (years.length > 0 && years[years.length-1] !== endYearInt) {
		years.push(endYearInt);
	}

	return years;
}

// Based on https://stackoverflow.com/a/39494245
const smoothScroll = (id, duration = 250) => {
	const headerOffset = 61; // Search headerOffset-Height - Noted in components/header.scss

	// TASK: Account for different sized headers

	const elemOffset = document.getElementById(id).offsetTop - headerOffset;

	const pageOffset = window.pageYOffset;
	const diff = elemOffset - pageOffset;
	let start;

	// Bootstrap our animation - it will get called right before next frame shall be rendered.
	window.requestAnimationFrame(function step(timestamp) {
		if (!start) {
			start = timestamp;
		}

		// Elapsed miliseconds since start of scrolling.
		const time = timestamp - start;

		// Get percent of completion in range [0, 1].
		const percent = Math.min(time / duration, 1);

		window.scrollTo(0, pageOffset + (diff * percent));

		// Proceed with animation as long as we wanted it to.
		if (time < duration) {
			window.requestAnimationFrame(step);
		}
	});
}

const formatMoney = (num, scale = 2) => {
	const c = scale;
	const d = '.';
	const t = ',';

	const s = num < 0 ? '-' : '';
	const i = String(parseInt(num = Math.abs(Number(num) || 0).toFixed(c), 10));
	let j = i.length > 3 ? i.length % 3 : 0;
	return '$' + s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(num - i).toFixed(c).slice(2) : '');
};

const dispatchError = err => window.dispatchEvent(new CustomEvent('error', { detail: err }));

export {
	throttle,
	debounce,
	breakpoints,
	getBreakpoint,
	getDateRange,
	smoothScroll,
	formatMoney,
	dispatchError,
};
