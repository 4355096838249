import { sendFetchRequest,getApiContext } from './api_utils';

const timeLineURL = `${getApiContext()}/api/timeline`;

const get = (lifeTimeLineID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${timeLineURL}/${lifeTimeLineID}`, options);
};

const getAll = (ownerId) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${timeLineURL}/owner/${ownerId}`, options);
};

const getPublic = (lifeTimeLineID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${timeLineURL}/public/${lifeTimeLineID}`, options);
};

const getAllPublic = () => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${timeLineURL}/public`, options);
};

const create = (timeLine) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(timeLine),
	};

	return sendFetchRequest(timeLineURL, options);
};

const update = (timeLine) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(timeLine),
	};

	return sendFetchRequest(`${timeLineURL}/${timeLine.timelineId}`, options);
};


const search = (title) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ title }),
	};

	return sendFetchRequest(`${timeLineURL}/search`, options);
};


export { get, getAll, getPublic, getAllPublic, create, update, search };
