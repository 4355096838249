import React from 'react';

import './InputGroup.scss';

const InputGroup = ({ className, children, ...otherProps }) => {
	const classes = [ 'inputGroup' ];

	if (className) {
		classes.push(className);
	}

	return (
		<div className={classes} {...otherProps}>
			{children}
		</div>
	);
}

export default InputGroup;