import React, { Component } from 'react';

import './Select.scss';

class Select extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
			error: props.error,
		};
	}

	componentWillReceiveProps(nextProps) {
		const data = {};

		if (nextProps.value !== this.props.value) {
			data.value = nextProps.value;
		}

		if (nextProps.error !== this.props.error) {
			data.error = nextProps.error;
		}

		if (Object.keys(data).length > 0) {
			this.setState(data);
		}
	}

	handleOnChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange(event);
		}

		const value = event.target.value;

		this.validate(value);

		this.setState({
			value,
		});
	}

	validate = (value) => {
		const {
			label,
			placeholder,
			required,
		} = this.props;

		if (required && !value) {
			const name = label || placeholder;

			this.setState({
				error: name ? `${name} is a required field.` : 'Required field.',
			});

			return;
		}
	};

	renderLabel = (label) => {
		if (!label) {
			return null;
		}

		return (
			<label htmlFor={this.props.id} className="select__label">
				{label}
			</label>
		);
	};

	renderError(error) {
		if (!error) {
			return null;
		}

		return (
			<div className="select__error">
				{error}
			</div>
		);
	};

	render() {
		const {
			label,
			className,
			editable,
			editMode,
			children,
			...otherProps
		} = this.props;

		const {
			value,
			error,
		} = this.state;

		const classes = [ 'select-wrapper' ];

		if (error && error.length) {
			classes.push('select-wrapper--error');
		}

		if (className) {
			classes.push(className);
		}

		if (!editable || !editMode) {
			const isZeroValue = !value || value === '0';

			return (
				<div className={classes.join(' ')} {...otherProps}>
					{isZeroValue ? this.props.placeholder || label : value}
				</div>
			);
		}

		return (
			<div className={classes.join(' ')}>
				{this.renderLabel(label)}
				<div className="select__icon">
					<i className="fa fa-smile-o" />
				</div>
				<select className="select" onChange={this.handleOnChange} {...otherProps}>
					{children}
				</select>
				{this.renderError(error)}
			</div>
		);
	}
}

export default Select;
