import { sendFetchRequest, getApiContext } from './api_utils';

const momentURL = `${getApiContext()}/api/moment`;

const get = (momentID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${momentURL}/${momentID}`, options);
};

const getAll = (lifeTimeLineID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${momentURL}/lifetimeline/${lifeTimeLineID}`, options);
};

const create = (moment) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(moment),
	};

	return sendFetchRequest(momentURL, options);
};

const update = (moment) => {
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(moment),
	};

	return sendFetchRequest(`${momentURL}/${moment.momentId}`, options);
};

const deleteMoment = (momentID) => {
	const options = {
		method: 'DELETE',
	};

	return sendFetchRequest(`${momentURL}/${momentID}`, options);
};


export { get, getAll, create, update, deleteMoment };
