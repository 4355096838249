const defaultState = {};

const reducer = (state = defaultState, action) => {
	switch(action.type) {
		case 'SET_LIFETIMELINES': {
			const newState = Object.assign({}, state);

			action.data.forEach((lifeTimeLine) => {
				newState[lifeTimeLine.timelineId] = lifeTimeLine;
			});

			return newState;
		}
		case 'SET_LIFETIMELINE': {
			const newState = Object.assign({}, state);

			newState[action.data.timelineId] = Object.assign({}, action.data);

			return newState;
		}
		case 'SET_LIFETIMELINE_MOMENT': {
			const newState = Object.assign({}, state);

			const newLifeTimeLine = Object.assign({}, newState[action.data.timelineId]);

			if (!newLifeTimeLine || !newLifeTimeLine.moments) {
				return state;
			}

			const momentIndex = newLifeTimeLine.moments.findIndex(moment => moment.momentId === action.data.momentId);
			if (momentIndex >= 0) {
				newLifeTimeLine.moments[momentIndex] = action.data;
			} else {
				newLifeTimeLine.moments.push(action.data);
			}

			newLifeTimeLine.moments.sort((moment1, moment2) => {
				const a = new Date(moment1.date).valueOf();
				const b = new Date(moment2.date).valueOf();

				// Sort needs to return -1, 0 or 1.
				const result = (a > b) - (a < b);

				// If the values are equal and 0 will be returned, sort by the momentID instead.
				if (result === 0) {
					return moment1.momentId > moment2.momentId ? 1 : -1;
				}

				return result;
			});

			return newState;
		}
		case 'DELETE_LIFETIMELINE_MOMENT': {
			const newState = Object.assign({}, state);

			const newLifeTimeLine = Object.assign({}, newState[action.data.lifeTimeLineID]);

			if (!newLifeTimeLine || !newLifeTimeLine.moments) {
				return state;
			}

			const momentIndex = newLifeTimeLine.moments.findIndex(moment => moment.momentId === action.data.momentID);
			if (momentIndex >= 0) {
				newLifeTimeLine.moments.splice(momentIndex, 1)
			}

			return newState;
		}
		case 'SIGN_OUT':
			return defaultState;
		default:
			return state;
	}
}

export default reducer;
