import React from 'react';


import './FeedPanel.scss';

const renderFeedItemIcon = (itemType) => {
	return (
		<i className="fa fa-comment" title={itemType} />
	)
}

const FeedPanel = ( {activityFeed} ) => {
	//console.log('FEED',activityFeed);
	const content = activityFeed.feedItems.length ? activityFeed.feedItems.map(feedItem =>
		<div
			className="activityFeed__item"
			key={"key-"+feedItem.itemType+"-"+feedItem.itemID}
		>
			<div className="activityFeed__item--icon">
				{renderFeedItemIcon(feedItem.itemType)}
			</div>
			<div className="activityFeed__item--data">
				<div className="activityFeed__item--content">
					{feedItem.itemContent}
				</div>
				<div className="activityFeed__item--details">
					<div className="activityFeed__item--author">{feedItem.author.firstName} {feedItem.author.lastName}</div>
					<div className="activityFeed__item--dateTime">{feedItem.itemDateTime}</div>
				</div>
			</div>
		</div>
	) : '...';

	return (
		<div className="activityFeedItems">
			{content}
		</div>
	);
};

export default FeedPanel;