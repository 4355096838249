import React, { Component } from 'react';

import './Swiper.scss';

class Swiper extends Component {
	margin = 10;
	touchCount = 0;

	state = {
		startX: 0,
		startY: 0,
		endX: 0,
		endY: 0,
		isGestureInProgress: false,
	};

	handleTouchStart = (e) => {
		this.touchCount++;

		// If two or more touch events are happening at the same time, then this touch is considered a gesture and will
		// be ignored by handleSwipe
		if (this.touchCount > 1) {
			this.setState({ isGestureInProgress: true });
		}

		if (this.props.onTouchStart) {
			this.props.onTouchStart(e);
		}

		const touch = e.changedTouches[0];

		if (touch) {
			this.setState({
				startX: touch.screenX,
				startY: touch.screenY,
			});
		}
	};

	handleTouchEnd = (e) => {
		this.touchCount--;

		if (this.props.onTouchEnd) {
			this.props.onTouchEnd(e);
		}

		const touch = e.changedTouches[0];

		if (touch) {
			// Fires handleSwipe after the gesture is complete and the state has been updated
			this.setState({
					endX: touch.screenX,
					endY: touch.screenY,
				},
				this.handleSwipe,
			);
		}
	};

	handleSwipe = () => {
		const {
			startX,
			startY,
			endX,
			endY,
			isGestureInProgress,
		} = this.state;

		// If there is a gesture in progress then the last touch event to end will end the gesture
		if (isGestureInProgress) {
			if (this.touchCount === 0) {
				this.setState({ isGestureInProgress: false });
			}
			return;
		}

		if (this.props.onSwipeRight && (endX + this.margin) < startX) {
			this.props.onSwipeRight(startX, startY, endX, endY);
			return;
		}

		if (this.props.onSwipeLeft && (endX - this.margin) > startX) {
			this.props.onSwipeLeft(startX, startY, endX, endY);

			return;
		}

		if (this.props.onSwipeUp && (endY + this.margin) < startY) {
			this.props.onSwipeUp(startX, startY, endX, endY);
			return;
		}

		if (this.props.onSwipeDown && (endY + this.margin) > startY) {
			this.props.onSwipeDown(startX, startY, endX, endY);
			return;
		}

		if (this.props.onTap && Math.abs(endY - startY) < this.margin) {
			this.props.onTap(startX, startY, endX, endY);
			return;
		}
	};

	render() {
		const {
			className,
			children,
			...otherProps
		} = this.props;

		delete(otherProps.onSwipeLeft);
		delete(otherProps.onSwipeRight);
		delete(otherProps.onSwipeUp);
		delete(otherProps.onSwipeDown);
		delete(otherProps.onTap);

		const classes = [ 'swiper' ];
		if (className) {
			classes.push(className);
		}

		return (
			<div
				className={classes.join(' ')}
				{...otherProps}
				onTouchStart={this.handleTouchStart}
				onTouchEnd={this.handleTouchEnd}
			>
				{children}
			</div>
		);
	}
}

export default Swiper;
