import React from 'react';

import Logo from '../../components/Brand/Logo';

import './FocusBox.scss';

const FocusBox = ({ className, brand, children }) => {
	if (!children || children.length === 0) {
		return null;
	}

	let leftContent = children;
	if (Array.isArray(children)) {
		leftContent = children[0];
	}

	const isTwin = children && children.length === 2;

	const focusBoxClasses = ['focusBox'];

	if (className) {
		focusBoxClasses.push(className);
	}

	let rightContent = null;
	if (isTwin) {
		focusBoxClasses.push('focusBox--twin');

		rightContent = (
			<div className="focusBox__right">
				{children[1]}
			</div>
		);
	}

	return (
		<div className={focusBoxClasses.join(' ')}>
			<div className="focusBox__left">
				<Logo brand={brand} logoContext="reverse"/>
				{leftContent}
			</div>
			{rightContent}
		</div>
	);
}

export default FocusBox;