import React/*, { Component }*/ from 'react';
//import PropTypes from 'prop-types';
import { /*withRouter,*/ Route, Redirect } from 'react-router-dom';

//import * as defer from './defer';

const AuthRoute = ({ signInRequired, isSignedIn, ...otherProps }) => {
	if (signInRequired && !isSignedIn) {
		return <Redirect from={otherProps.path} to="/sign-in" />
	}

	return <Route {...otherProps} />
};

// class LocationListener extends Component {
// 	static contextTypes = {
// 		router: PropTypes.object
// 	};

// 	componentDidMount() {
// 		console.log('context',this.context);
// 		console.log('props',this.props);
// 		//this.unlisten = this.context.router.history.listen(this.handleLocationChange);
// 	}

// 	componentWillUnmount() {
// 		//this.unlisten();
// 	}

// 	handleLocationChange = (location) => {
// 		// document.dispatchEvent(new CustomEvent(
// 		// 	'ROUTE_CHANGE',
// 		// 	{ detail: location },
// 		// ));

// 		// We can use this addEventListener code back in to handle route changes in other components
// 		// document.addEventListener(
// 		// 	'ROUTE_CHANGE',
// 		// 	(location) => { console.log('fire >', location) },
// 		// );

// 		// TASK: Custom URL
// 		this.checkStorage(
// 			location,
// 			'PUBLISH_TIMELINE_ON_PAYMENT',
// 			[/^\/cart/, /^\/lifetimelines/, /^\/[0-9]+/, /^\/author\/[0-9]+/],
// 		);

// 		this.checkStorage(
// 			location,
// 			'ADD_PRODUCT_TO_CART_ON_SIGN_IN',
// 			[/^\/products/, /^\/sign-in/, /^\/register/, /^\/cart/],
// 		);
// 	};

// 	checkStorage = (location, namespace, tests) => {
// 		let isOnPath = false;
// 		for (let i = 0; i < tests.length; i++) {
// 			if (tests[i].test(location.pathname)) {
// 				isOnPath = true;
// 				break;
// 			}
// 		};

// 		if (!isOnPath) {
// 			defer.clear(namespace);
// 		}
// 	};

// 	render() {
// 		return this.props.children;
// 	}
// }


export { AuthRoute };//, LocationListener
