import { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as defer from './defer';

class LocationListener extends Component {



	// componentWillMount() {
	// 	// will trigger the callback function whenever a new Route renders a component(as long as this component stays mounted as routes change)
	// 	this.props.history.listen(() => {
	// 	  // view new URL ===>		  console.log('New URL', this.props.history.location.pathname);

	// 	});
	//   }

	componentDidMount() {
		this.unlisten = this.props.history.listen(this.handleLocationChange);
	}

	componentWillUnmount() {
		this.unlisten();
	}

	handleLocationChange = (location) => {
		console.log('U.L. 30: New URL', location.pathname);
		// document.dispatchEvent(new CustomEvent(
		// 	'ROUTE_CHANGE',
		// 	{ detail: location },
		// ));

		// We can use this addEventListener code back in to handle route changes in other components
		// document.addEventListener(
		// 	'ROUTE_CHANGE',
		// 	(location) => { console.log('fire >', location) },
		// );

		// TASK: Custom URL
		this.checkStorage(
			location,
			'PUBLISH_TIMELINE_ON_PAYMENT',
			[/^\/cart/, /^\/lifetimelines/, /^\/[0-9]+/, /^\/author\/[0-9]+/],
		);

		this.checkStorage(
			location,
			'ADD_PRODUCT_TO_CART_ON_SIGN_IN',
			[/^\/products/, /^\/sign-in/, /^\/register/, /^\/cart/],
		);
	};

	checkStorage = (location, namespace, tests) => {
		let isOnPath = false;
		for (let i = 0; i < tests.length; i++) {
			if (tests[i].test(location.pathname)) {
				isOnPath = true;
				break;
			}
		};

		if (!isOnPath) {
			defer.clear(namespace);
		}
	};

	render() {
		return this.props.children;
	}
}


export default withRouter(LocationListener);
