const initialState = {
	partnerID: 2,
	brand: {
		key: "inmemoriam",
		name: 'In Memoriam™',
		logo:	{
			"logoType": "SVG",
			"logoContextAsset": {
				"default": "#00BAFF",
				"reverse": "#FFFFFF",
				"watermark": "#CCCCCC"
			}
		}
	},
	contactUsEmail: 'ask@inmemoriam.com',
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case 'GET_AUDIENCE':
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
}

export default reducer;