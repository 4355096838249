import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../../Form/Button';
import Image from '../../../Form/Image';

import * as momentAPI from '../../../../api/momentAPI';

import './DeleteMoment.scss';

class DeleteMoment extends Component {
	handleDeleteMoment = () => {
		const {
			lifeTimeLineID,
			momentID ,
		} = this.props;

		if (!momentID) {
			return;
		}

		momentAPI.deleteMoment(momentID)
			.then((resp) => {
				if (resp.success) {
					this.props.onDeleteMoment({
						lifeTimeLineID,
						momentID
					});
					this.props.onClose();
				}
			});
	}

	render() {
		const {
			moment,
			onClose,
		} = this.props;

		if (!moment) {
			return null;
		}

		return (
			<div className="modal delete-moment" onClick={(e) => e.stopPropagation()}>
				<div className="delete-moment__view">
					<Image
						className="delete-moment__image"
						image={moment.image}
					/>
					<div>
						<div className="delete-moment__title">
							<i className="fa fa-warning" />
							Confirm Delete
						</div>
						<div>
							Are you sure you want to delete this Moment?
						</div>
					</div>
				</div>
				<div className="delete-moment__buttons">
					<Button onClick={onClose}>
						Cancel
					</Button>
					<Button
						className="delete-moment__button"
						onClick={this.handleDeleteMoment}
					>
						Permanently Delete Now
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const lifeTimeLine = state.lifeTimeLines[props.lifeTimeLineID];

	if (!lifeTimeLine) {
		return {};
	}

	let moment = lifeTimeLine.moments.find(m => m.momentId === props.momentID);

	return {
		moment,
	};
};

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDeleteMoment: (data) => {
			dispatch({
				type: 'DELETE_LIFETIMELINE_MOMENT',
				data,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DeleteMoment);
