import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as timeLineAPI from '../../api/timeLineAPI';
//import { brandConfig } from '../../api/brandConfig';

import RecordBox from '../../components/RecordBox/RecordBox';
import Spinner from '../../components/Spinner';
import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './Home.scss';

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lifeTimeLines: [],
			loading: true,
		};

		timeLineAPI.getAllPublic()
			.then(resp => {
				if (resp.success) {
					this.setState({
						lifeTimeLines: resp.data,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			});
	}

	renderLifeTimelines = (lifeTimeLines) => {
		if (!lifeTimeLines) {
			return null;
		}
		const recordBoxesAcross = Math.floor(window.innerWidth/306.66);
		const getToRecordPosition = recordBoxesAcross === 3 ? 5 : 7; // this gets 6 records if width = 3 across and 8 in all other cases.
		return Object.keys(lifeTimeLines).map((lifeTimeLineID, i) => {
			if (i > getToRecordPosition) {
				return null;
			}

			//console.log('ownerEntityId',lifeTimeLines[lifeTimeLineID].ownerEntityId,'entityId',this.props.profile.entityId)
			return (
				<RecordBox
					className="homeRecordBox"
					key={lifeTimeLineID}
					isPublic={this.props.profile.entityId !== lifeTimeLines[lifeTimeLineID].ownerEntityId}
					isImageLazyLoaded={false}
					{...lifeTimeLines[lifeTimeLineID]}
				/>
			);
		});
	};

	render() {
		const lifeTimeLines = this.renderLifeTimelines(this.state.lifeTimeLines);
		const audience = this.props.audience;
		return (
			<div className="home-page">
				<div className="main-slider">
					<div className="main-slider__centred">
						<div className="main-slider__textBlock">
							<h1>Beautiful and enduring life stories.</h1>
							<p>LifeTimeLine<span className="regMark">®</span> is an ad-free, community-centric</p>
							<p>and permanent online space. It provides a </p>
							<p>safe and respectful place to create lasting </p>
							<p>life stories. It’s a way to share your story </p>
							<p>around the world and across generations.</p>

						</div>
						<Logo
							className="main-slider__logo"
							brand={audience.brand}
							logoContext="mainSlider"
						/>
					</div>
				</div>
				<div className="home-actions">
					<div className="home-actions__centred">
						<Link className="home-actions__action" to="/products" >
							<div className="fa-stack fa-2x">
								<i className="fa fa-circle fa-stack-2x"></i>
								<i className="fa fa-bell-o fa-stack-1x"></i>
							</div>
							<div className="home-actions__action--textBlock">
								<h2>Compare our Products</h2>
								<p>Try it out for free or jump in with both feet. Compare our product offerings in detail.</p>
							</div>
						</Link>
						<Link className="home-actions__action" to="/about" >
							<div className="fa-stack fa-2x">
								<i className="fa fa-circle fa-stack-2x"></i>
								<i className="fa fa-tag fa-stack-1x"></i>
							</div>
							<div className="home-actions__action--textBlock">
								<h2>What is LifeTimeLine?</h2>
								<p>Take a look at what LifeTimeLine does for you and we'll tell you a little bit about us too.</p>
							</div>
						</Link>
						<Link className="home-actions__action" to="/help" >
							<div className="fa-stack fa-2x">
								<i className="fa fa-circle fa-stack-2x"></i>
								<i className="fa fa-clock-o fa-stack-1x"></i>
							</div>
							<div className="home-actions__action--textBlock">
								<h2>Quick Start Guide</h2>
								<p>Quickly and easily publish a LifeTimeLine for free in just a few minutes from any device.</p>
							</div>
						</Link>
					</div>
				</div>
				<div className="home-records__wrapper">
					<div className="home-records">
						{lifeTimeLines}
						<Spinner
							className="home-records__spinner"
							active={this.state.loading}
							color="#999"
						/>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}


export default connect(
	mapStateToProps,
)(Home);
