import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import FocusBox from '../../components/FocusBox/FocusBox';
import TextInput from '../../components/FocusBox/Inputs/TextInput';
import Button from '../../components/Form/Button/Button';

import * as personAPI from '../../api/personAPI';

import { dispatchError } from '../../utils/general';

class ResetPasswordRequest extends Component {
	state = {
		email: '',
		errorMessage: '',
		loading: false,
	};

	componentDidMount() {
		if (this.props.profile.isSignedIn) {
			this.props.history.push('/lifetimelines');
		}
	}

	onInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		this.setState({
			[name]: value,
			errorMessage: '',
		});
	};

	handleResetPasswordRequest = () => {
		this.setState({ loading: true });

		personAPI.requestPasswordReset(this.state.email)
			.then(this.onSuccess)
			.catch(this.onError);
	};

	onSuccess = (data) => {
		this.setState({ loading: false });

		this.props.onSetMessage({
			title: 'Password Reset',
			message: `If the email address "${this.state.email}" has an account in the system then an email with a link ` +
				"to reset your password will be sent to it.",
		});
	};

	onError = (errorMessage) => {
		dispatchError(errorMessage);
		this.setState({
			errorMessage,
			loading: false,
		});
	};

	onHandleEnter = (event) => {
		if (event.key === 'Enter') {
			this.handleResetPasswordRequest();
		}
	};

	renderErrorMessage = (errorMessage) => {
		if (!errorMessage) {
			return null;
		}

		return <div className="focusBox__errorMessage">{errorMessage}</div>;
	};

	render() {
		const errorMessage = this.state.errorMessage;

		const spinnerClass = [ 'focusBox__spinner' ];
		if (this.state.loading) {
			spinnerClass.push('focusBox__spinner--active')
		}

		return (
			<FocusBox brand={this.props.audience.brand}>
				<div>
					<TextInput
						type="email"
						name="email"
						placeholder="Email"
						autoFocus={true}
						onChange={this.onInputChange}
						onKeyPress={this.onHandleEnter}
						icon="fa-envelope-o"
						forceInvalid={errorMessage.length > 0}
						required
					/>

					{this.renderErrorMessage(errorMessage)}

					<Button
						className="focusBox__button"
						color="blue"
						loading={this.state.loading}
						onClick={this.handleResetPasswordRequest}
					>
						Send Reset Link
					</Button>

					<Link to="/sign-in" className="bottom-link">
						Return to Sign In
					</Link>
				</div>
				<div className="focusBox__description">
					<div className="focusBox__title">Password Reset</div>
					<div className="focusBox__text">
						Enter the email address you signed up with and click the 'Send Reset Link' button.
						<br /><br />
						An email will be sent containing a link allowing you to reset your password.
						<br /><br />
						Reset links are sent straight away so if you don't see it after a few minutes, check your spam folder.
						<br /><br />
						Reset links expire after three hours.
					</div>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onResetPasswordRequest: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
		onSignOut: () => {
			dispatch({ type: 'SIGN_OUT' });
		},
		onSetProfile: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ResetPasswordRequest);
