const put = (namespace, value) => window.localStorage.setItem(`${namespace}_${value}`, value);

const get = (namespace) => {
	const result = [];

	for (let i = 0; i < window.localStorage.length; i++) {
		const key = window.localStorage.key(i);

		if (key.substr(0, namespace.length) !== namespace) {
			continue;
		}

		result.push(window.localStorage.getItem(key));
	}

	return result;
};

const clear = (namespace) => {
	for (let i = 0; i < window.localStorage.length; i++) {
		const key = window.localStorage.key(i);

		if (key.substr(0, namespace.length) === namespace) {
			window.localStorage.removeItem(key);
		}
	}
};

export { put, get, clear };
