// Source: http://emailregex.com/
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function email(email) {
	const primaryEmailAddress = [];

	if (email.length < 6 || email.length > 200) {
		primaryEmailAddress.push('Email must be between 6 and 200 characters.');
	}

	if (!emailRegex.test(email)) {
		primaryEmailAddress.push('Email must be a valid email address.')
	}

	return primaryEmailAddress;
}

export function password(pass) {
	const password = [];

	if (pass.length < 8 || pass.length > 50) {
		password.push('Password must be between 8 and 50 characters.');
	}

	return password;
}

// Test Numbers: https://pinpayments.com/developers/api-reference/test-cards
export function cardNumber(number) {
	const card = [];

	// Remove all of the spaces before validating
	number = number.replace(/ /g, '');

	var regex = /^[0-9]{15,16}$/;
	if (!regex.test(number)) {
		card.push('Card Number must be a 15 or 16 digit number.');
	}

	if (!luhnCheck(number)) {
		card.push('Invalid Card Number.');
	}

	return card;
}

// Luhn's algorithm. Source: https://stackoverflow.com/a/29106166
export function luhnCheck(val) {
	const digits = (val + '').split('').reverse();

	let sum = 0;
	let flag = true;

	for (let i = 0; i < digits.length; i++) {
		let digit = parseInt(digits[i], 10);

		flag = !flag
		if (flag) {
			digit *= 2;
		}

		if (digit > 9) {
			digit -= 9;
		}

		sum += digit;
	}

	return sum % 10 === 0;
}
