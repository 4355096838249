import React, { Component } from 'react';

import Spinner from '../../Spinner/Spinner';

import './Button.scss';


class Button extends Component {
	static fieldType = 'Button';

	render() {
		const {
			className,
			color,
			loading,
			onClick,
			disabled,
			children,
			...otherProps
		} = this.props;

		const classes = [ 'formButton' ];

		if (className) {
			classes.push(className);
		}

		if (color) {
			classes.push(`formButton--${color}`);
		}

		if (disabled || loading) {
			classes.push('formButton--disabled');
		}

		delete(otherProps.isSubmit);

		return (
			<button
				className={classes.join(' ')}
				onClick={!(disabled || loading) ? onClick : null}
				{...otherProps}
			>
				{children}
				<Spinner className="formButton__spinner" active={loading} />
			</button>
		);
	}
}

export default Button;
