import React, { Component } from 'react';

import './Switch.scss';

class Switch extends Component {
	static fieldType = 'Switch';

	constructor(props) {
		super(props);

		this.state = {
			value: props.value || false,
			isLockedMessageOpen: false,
		};
	}

	componentWillReceiveProps({ value }) {
		if (value === undefined) {
			return;
		}

		if (value !== this.state.value) {
			this.setState({ value });
		}
	}

	toggleSwitch = (e) => {
		if (this.props.locked) {
			this.setState({ isLockedMessageOpen: true });
			return;
		}

		this.setState((state) => ({ value: !state.value }));

		if (this.props.onClick) {
			this.props.onClick(e);
		}
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter' || e.key === ' ') {
			this.toggleSwitch(e);
		}
	};

	handleCloseTooltip = (locked, isLockedMessageOpen) => {
		if (!locked || !isLockedMessageOpen) {
			return;
		}

		this.setState({ isLockedMessageOpen: false });
	};

	renderLabel = (label) => {
		if (!label) {
			return null;
		}

		return (
			<label className="switch__label" onClick={this.toggleSwitch}>
				{label}
			</label>
		);
	};

	renderTooltip = (lockedMessage, isLockedMessageOpen) => {
		if (!isLockedMessageOpen || !lockedMessage) {
			return null;
		}

		return (
			<div
				className="switch__tooltip"
			>
				{lockedMessage}
			</div>
		)
	};

	render() {
		const {
			label,
			className,
			locked,
			lockedMessage,
			...otherProps
		} = this.props;

		const {
			value,
			isLockedMessageOpen,
		} = this.state;

		const classes = [ 'switch' ];

		if (value) {
			classes.push('switch--open');
		}

		if (className) {
			classes.push(className);
		}

		return (
			<div
				className="switch__wrapper"
				onMouseLeave={() => this.handleCloseTooltip(locked, isLockedMessageOpen)}
			>
				{this.renderLabel(label)}
				<div className="switch__input">
					<div
						tabIndex="0"
						{...otherProps}
						className={classes.join(' ')}
						onClick={this.toggleSwitch}
						onKeyPress={this.handleKeyPress}
					>
						<div className="switch__button">
							{locked && <i className="fa fa-lock switch__lock" />}
						</div>
					</div>
				</div>
				{this.renderTooltip(lockedMessage, isLockedMessageOpen)}
			</div>
		);
	}
}

export default Switch;
