import * as redux from 'redux';

import globalReducer from './globalReducer';
import profileReducer from './profileReducer';
import audienceReducer from './audienceReducer';
import lifeTimeLineReducer from './lifeTimeLineReducer';
import cartReducer from './cartReducer';

const reducer = redux.combineReducers({
	global: globalReducer,
	profile: profileReducer,
	audience: audienceReducer,
	lifeTimeLines: lifeTimeLineReducer,
	cart: cartReducer,
});

export default reducer;