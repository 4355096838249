import React, { Component } from 'react';

import Button from '../Form/Button';
import Backdrop from '../Backdrop';

import './SharePanel.scss';

class SharePanel extends Component {
	copyInput = null;

	state = {
		isOpen: false,
	};

	setIsOpen = isOpen => this.setState({ isOpen });

	onCopy = () => {
		this.copyInput.select();
		document.execCommand('copy');
	};

	onFBShare = (url) => {
		const width = 600;
		const height = 400;

		const left = (window.outerWidth / 2) + (window.screenX || window.screenLeft || 0) - (width / 2);
		const top = (window.outerHeight / 2) + (window.screenY || window.screenTop || 0) - (height / 2);

		const config = {
			height,
			width,
			left,
			top,
			location: 'no',
			toolbar: 'no',
			status: 'no',
			directories: 'no',
			menubar: 'no',
			scrollbars: 'yes',
			resizable: 'no',
			centerscreen: 'yes',
			chrome: 'yes',
		};

		window.open(
			`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
			'Facebook',
			Object.keys(config).map(key => `${key}=${config[key]}`).join(', '),
		);
	};

	renderSharePanel = (isOpen) => {
		if (!isOpen) {
			return null;
		}

		const {
			title,
			lifeTimeLineID,
			momentID,
		} = this.props;

		let shareTypeLabel = 'LifeTimeLine';
		let url = `https://${window.location.hostname}/${lifeTimeLineID}`;
		if (momentID) {
			url += `/m/${momentID}`
			shareTypeLabel = 'Moment';
		}

		return (
			<div className="share-panel">
				<div className="share-panel__title">
					Share {title}'s {shareTypeLabel}
				</div>
				<div className="share-panel__line">
					<input
						className="share-panel__copy"
						ref={ref => this.copyInput = ref}
						defaultValue={url}
						onClick={this.onCopy}
					/>
					<Button
						color="blue"
						className="share-panel__button"
						onClick={this.onCopy}
					>
						<i className="fa fa-files-o" /> Copy Link
					</Button>
				</div>
				<div className="share-panel__line">
					Share to Facebook
					<Button
						color="dark-blue"
						className="share-panel__button"
						onClick={() => this.onFBShare(url)}
					>
						<i className="fa fa-facebook" />
						Facebook
					</Button>
				</div>
			</div>
		);
	};

	render() {
		const isOpen = this.state.isOpen;

		const {
			className,
			position,
		} = this.props;

		const classes = [ 'share' ];
		if (className) {
			classes.push(className);
		}

		// TODO: Implement share--left and share--right in the css
		if (position) {
			classes.push(`share--${position}`)
		}

		return (
			<div className={classes.join(' ')}>
				<i className="fa fa-share-alt" onClick={() => this.setIsOpen(true)} />
				<Backdrop isOpen={isOpen} onClose={() => this.setIsOpen(false)} />
				{this.renderSharePanel(isOpen)}
			</div>
		);
	}
}

export default SharePanel;
