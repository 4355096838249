import { sendFetchRequest, getApiContext } from './api_utils';

const productURL = `${getApiContext()}/api/product`;

const getByPartnerID = (partnerID) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${productURL}/partner/${partnerID}`, options);
};

export { getByPartnerID };
