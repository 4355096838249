import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import FocusBox from '../../components/FocusBox/FocusBox';
import TextInput from '../../components/FocusBox/Inputs/TextInput';
import InputGroup from '../../components/FocusBox/Inputs/InputGroup';
import Button from '../../components/Form/Button/Button';

import * as cartAPI from '../../api/cartAPI';

import signIn from './user_utils';
import * as defer from '../../utils/defer';

class SignIn extends Component {
	state = {
		email: '',
		password: '',
		errorMessage: '',
		loading: false,
	};

	componentDidMount() {
		if (this.props.profile.isSignedIn) {
			this.props.history.push('/lifetimelines');
		}
	}

	onInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		this.setState({
			[name]: value,
			errorMessage: '',
		});
	};

	handleSignIn = () => {
		this.setState({ loading: true });

		signIn(
			this.state.email,
			this.state.password,
			this.onSuccess,
			this.onError,
		);
	};

	onSuccess = (data) => {
		this.setState({ loading: false });

		this.props.onSetProfile(data);

		cartAPI.getByPartnerID(this.props.audience.partnerID)
			.then(resp => {
				if (!resp.success) {
					return;
				}

				this.props.setCartItems(resp.data.cartId, resp.data.cartItems);

				const cartItem = defer.get('ADD_PRODUCT_TO_CART_ON_SIGN_IN');
				if (cartItem.length > 0) {
					defer.clear('ADD_PRODUCT_TO_CART_ON_SIGN_IN');

					const productId = cartItem[0];

					if (productId === 'new') {
						this.props.history.push('/author/new');
						return;
					}

					cartAPI.addCartItem({
						cartId: resp.data.cartId,
						productId: productId,
						quantity: 1,
					})
					.then(resp => {
						if (resp.success) {
							this.props.addCartItem(resp.data);
							this.props.history.push('/cart');
						}
					});
				} else {
					this.props.history.push('/lifetimelines');
				}
			});
	};

	onError = (errorMessage) => {
		this.setState({
			errorMessage,
			loading: false,
		});

		this.props.onSignOut();
	};

	onHandleEnter = (event) => {
		if (event.key === 'Enter') {
			this.handleSignIn();
		}
	};

	renderErrorMessage = (errorMessage) => {
		if (!errorMessage) {
			return null;
		}

		return <div className="focusBox__errorMessage">{errorMessage}</div>
	};

	render() {
		const errorMessage = this.state.errorMessage;

		const spinnerClass = [ 'focusBox__spinner' ];
		if (this.state.loading) {
			spinnerClass.push('focusBox__spinner--active')
		}

		return (
			<FocusBox brand={this.props.audience.brand}>
				<div>
					<InputGroup>
						<TextInput
							type="email"
							name="email"
							placeholder="Email"
							autoFocus={true}
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							icon="fa-envelope-o"
							forceInvalid={errorMessage.length > 0}
						/>
						<TextInput
							type="password"
							name="password"
							placeholder="Password"
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							icon="fa-key"
							forceInvalid={errorMessage.length > 0}
						/>
					</InputGroup>

					{this.renderErrorMessage(errorMessage)}

					<Button
						className="focusBox__button"
						color="blue"
						loading={this.state.loading}
						onClick={this.handleSignIn}
					>
						Sign in
					</Button>

					<Link to="/reset-password" className="bottom-link">
						Forgotten your details?
					</Link>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		addCartItem: (data) => {
			dispatch({
				type: 'ADD_CART_ITEM',
				data,
			});
		},
		onSignOut: () => {
			dispatch({ type: 'SIGN_OUT' });
		},
		onSetProfile: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
		setCartItems: (cartID, data) => {
			dispatch({
				type: 'SET_CART_ITEMS',
				cartID,
				data,
			});
		},
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignIn);
