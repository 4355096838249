import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../../../Form/Button';

import { dispatchError } from '../../../../utils/general';

import * as cartAPI from '../../../../api/cartAPI';

import './ClearCart.scss';

class ClearCart extends Component {
	handleClearCart = () => {
		const {
			cart,
		} = this.props;

		if (!cart || !cart.items) {
			return;
		}

		new Promise((resolve, reject) => {
			cart.items.map(item => this.removeItem(item.cartItemId, reject));
			resolve();
		})
		.then(this.props.onClose());
	};

	removeItem = (cartItemId, reject) => {
		cartAPI.deleteCartItem(cartItemId)
			.then((resp) => {
				if (resp.success) {
					this.props.removeItem(cartItemId);
				} else {
					reject();
				}
			})
			.catch(err => {
				dispatchError(err);
				reject(err);
			});
	};

	render() {
		const {
			cart,
			onClose,
		} = this.props;

		if (!cart || !cart.items) {
			return null;
		}

		return (
			<div className="modal clear-cart" onClick={(e) => e.stopPropagation()}>
				<div className="clear-cart__view">
					<div>
						<div className="clear-cart__title">
							<i className="fa fa-warning" />
							Warning
						</div>
						<div>
							Are you sure you want to clear your cart?
						</div>
					</div>
				</div>
				<div className="clear-cart__buttons">
					<Button onClick={onClose}>
						Cancel
					</Button>
					<Button
						className="clear-cart__button"
						onClick={this.handleClearCart}
					>
						Yes, Remove all items from my cart
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
	cart: state.cart,
});

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		removeItem: (cartItemId) => {
			dispatch({
				type: 'REMOVE_CART_ITEM',
				data: {
					cartItemId,
				},
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ClearCart);
