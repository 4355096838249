const lifeTimeLineBrand = {
	partnerID: 1,
	contactUsEmail: "ask@lifetimeline.com",
	brand: {
		key: "lifetimeline",
		code: "LTL",
		name: "LifeTimeLine®",
		logo: {
			logoType: "SVG",
			logoURL: {}
		}
	}
};

const inMemoriamBrand = {
	partnerID: 2,
	contactUsEmail: "ask@inmemoriam.com",
	brand: {
		key: "inmemoriam",
		code: "IMG",
		name: "In Memoriam™",
		logo: {
			logoType: "SVG",
			logoURL: {}
		}
	}
};

const jbcLifeTimeLineBrand = {
	partnerID: 3,
	contactUsEmail: "ask.jbc@lifetimeline.com",
	brand: {
		key: "jbclifetimeline",
		code: "JBC",
		name: "Just Better Care",
		logo: {
			logoType: "SVG",
			logoURL: {}
		}
	},
	strip: {
		managerName: "Callum McMillian",
		managerRole: "COO/Director",
		managerPic: "TEMP-JBC-Callum-McMillian-COO.jpg",
		homePitch: "We do more than just care for our clients. We help them engage in meaningful and constructive activities that genuniely enrich their lives as well as encourage family interactions."
	}
};

const glenaeonLifeTimeLineBrand = {
	partnerID: 4,
	contactUsEmail: "ask.glenaeon@lifetimeline.com",
	brand: {
		key: "glenaeonlifetimeline",
		code: "GLA",
		name: "Glenaeon",
		logo: {
			logoType: "SVG",
			logoURL: {}
		}
	},
	strip: {
		managerName: "Natalie Patterson",
		managerRole: "Village Management",
		managerPic: "TEMP-GLA-Natalie-Patterson-Head-of-Village-Management.jpg",
		homePitch: "We pride ourselves on our committment to caring for our Glenaeon Family. Our partnership with LifeTimeLine provides a wonderful opportunity for residents and family to engage in a meaningful and productive outlet with lasting benefits to all involved."
	}
};

export const brandConfig = {
	lifetimeline:			lifeTimeLineBrand,
	inmemoriam: 			inMemoriamBrand,
	jbclifetimeline: 		jbcLifeTimeLineBrand,
	glenaeonlifetimeline: 	glenaeonLifeTimeLineBrand,
	localhost: 				inMemoriamBrand,
};
