import * as signInAPI from '../../api/loginAPI';
import * as personAPI from '../../api/personAPI';

import { dispatchError } from '../../utils/general';

const loadProfile = (signInData, onSuccess, onError) => {
	personAPI.get(signInData.entityId)
		.then(resp => {
			if (!resp.success) {
				onError('Something went wrong. Please try again.');
				return;
			}

			onSuccess({
				isSignedIn: true,
				...signInData,
				...resp.data,
			});
		})
		.catch(err => {
			dispatchError(err);
			onError('Something went wrong. Please try again.');
		});
};

const signIn = (email, password, onSuccess, onError) => {
	if (email.length === 0 || password.length === 0) {
		onError('Please enter your Email and Password.');
		return;
	}

	signInAPI.signIn(email, password)
		.then(resp => {
			if (!resp.success) {
				onError('The sign in details you provided were not valid.');
				return;
			}

			loadProfile(resp.data, onSuccess, onError);
		})
		.catch(err => {
			dispatchError(err);
			onError('Something went wrong. Please try again.');
		});
};

export default signIn;
