const save = (obj,prefix='') => Object.keys(obj).forEach(key => {
	if (obj[key] !== null && typeof obj[key] === 'object'){
		save(obj[key],key+' ');
	} else {
		window.localStorage.setItem(prefix+key, obj[key]);
	}
});

const load = (obj) => {
	const result = Object.assign({}, obj);								// Create a copy of the object so we don't mutate the original
	const storageKeys = Object.keys(window.localStorage);				// create an array of all keys in the localStorage
	Object.keys(obj).forEach(key => {									// run through the object we have been asked to fill out with data
		if (obj[key] !== null && typeof obj[key] === 'object') {		// if this key of the template object is an object itself (see profileReducer.js image: {})
			// Only looks two levels deep. e.g. localStorage.one.two  	if more, then sKey will have more than one space... "one two three" etc. BUT not handled yet.
			storageKeys.forEach(sKey => {								// look through all keys from localStorage
				if (sKey.lastIndexOf(key, 0) === 0) {					// if the key from localStorage starts with this template object's key
					const nestedKeys = sKey.split(' ');					// get out the localStorage key (might be several levels deep) e.g. [image, uri]
					result[key][nestedKeys[1]] = loadValue(sKey);		// set the value intot he child object, e.g. result.image.uri = 'a value'
				}
			})
		} else {
			result[key] = loadValue(key);
		}
	});
	return result;
};

const loadValue = (key) => {
	let value = window.localStorage.getItem(key);
	if (value === null) {
		return null;
	}
	return parseValue(value);
}

const parseValue = (value) => {
	if (value === '' || value === 'null') {
		value = null;
	} else if (value === 'true') {
		value = true;
	} else if (value === 'false') {
		value = false;
	} else if (!isNaN(value)) {
		value = Number(value);
	}
	return value;
}

const clear = (obj) => {
	Object.keys(obj).forEach(key => {
		window.localStorage.removeItem(key);
	});
}

export { save, load, clear };
