const initialState = {
	status: undefined,
	title: 'An Error Occured.',
	message: undefined,
	description: undefined,
	detail: undefined,
	actionLabel: 'OK',
	actionTask: 'close',
	actionEmail: undefined,
	id: undefined,
	userMenuActive: false,
	breakpoint: {
		label: 'desktop-m',
		level: 6,
	},
	modal: {
		type: null,
		data: {},
	},
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_MESSAGE':
			return {
				...state,
				...action.data,
			};
		case 'SET_BREAKPOINT':
			const breakpoint = action.data;

			return {
				...state,
				breakpoint,
			};
		case 'SET_MODAL':
			const modal = action.data;

			return {
				...state,
				modal,
			}
		default:
			return state;
	}
}

export default reducer;
