import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as utils from '../utils/general';
import { AuthRoute } from '../utils/routing';
import LocationListener from '../utils/listener';

import { brandConfig } from '../api/brandConfig';
import * as cartAPI from '../api/cartAPI';
import * as maintenanceAPI from '../api/maintenanceAPI';

import Header from '../components/Header/Header';
import Message from '../components/Message/Message';
import Modal from '../components/Modal';

import Spinner from '../components/Spinner';
import Home from './Home/Home';
import HomePartner from './HomePartner/HomePartner';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Help from './Help/Help';
import About from './About/About';
import SignIn from './User/SignIn';
import ResetPasswordRequest from './User/ResetPasswordRequest';
import ResetPasswordAction from './User/ResetPasswordAction';
import Welcome from './User/Verification/Welcome';
import Verify from './User/Verification/Verify';
import Register from './User/Register';
import Cart from './Cart';
import Products from './Products';
import SearchPage from './SearchPage/SearchPage';
import ContactUs from './ContactUs/ContactUs';
import LifeTimeLines from './LifeTimeLines/LifeTimeLines';
import LifeTimeLine from './LifeTimeLine';
import Account from './Account';
import Dashboard from './Dashboard/Dashboard';

import './App.scss';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	resizeHandler = utils.throttle(() => {
		window.dispatchEvent(new CustomEvent('RESIZE'));

		const label = utils.getBreakpoint();
		this.props.onSetBreakpoint({
			label,
			level: utils.breakpoints[label],
		});
	});

	getAudience() {
		//console.log('A.65 GETAUDIENCE', Date.now() );
		const theHostName = window.location.hostname;
		// remove known decorators ('qa.', 'dev.', 'build.', 'demo.') and ancillory host details ('www.', '.com', '.au' etc).
		// Remember this is internal and just produces a key to retrieve branding from the config file
		// e.g. if a partner uses a full url such as inmemoriam.titreelodge.com.au
		// the resulting key would be inmemoriamtitreelodge
		// we could add this to remove inmemoriam and lifetimeline from a partners domains (e.g. inmemroriam.titreelodge.com.au) but not required yet
		const regex = /((^din|(^|-)(dev|devqa|qa))\.)|\.(com|net|org|com\.au)$|\./gi;
		const pureHostName = theHostName.replace(regex, '');
		const forceHostName = 'lifetimeline';	// lifetimeline		inmemoriam
		const resolvedHostName = (pureHostName === 'localhost') ? forceHostName : pureHostName;
		const audience = brandConfig[resolvedHostName];
		if (audience) {
			this.props.onGetAudience(audience);

			if (this.props.profile.isSignedIn) {
				cartAPI.getByPartnerID(audience.partnerID)
					.then(resp => {
						if (!resp.success) {
							return;
						}
						this.props.setCartItems(resp.data.cartId, resp.data.cartItems);
					});
			}
		} else {
			console.log('Error retrieving Brand >>', brandConfig)
		}
	}

	componentWillMount = () => {
		//console.log('A.90 Loading', Date.now() );
		this.setState({loading: true});
	}

	//	Add and remove header shadow on scroll
	componentDidMount = () => {
		this.getAudience();
		this.resizeHandler();
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.resizeHandler);
		window.addEventListener("error", event => maintenanceAPI.logError(event.detail.stack));
		window.addEventListener("unhandledrejection", event => maintenanceAPI.logError(event.reason.stack));
		document.onscroll = utils.debounce(() => window.dispatchEvent(new CustomEvent('SCROLL_EVENT')));
		// Uncomment the setTimeout to test loading spinner
		//setTimeout(function() {
			this.setState({loading: false})
			//console.log('A.105 Loaded', Date.now() );
		//}.bind(this), 2000)
	}
	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.resizeHandler);
		window.removeEventListener("error", event => maintenanceAPI.logError(event.detail.stack));
		window.removeEventListener("unhandledrejection", event => maintenanceAPI.logError(event.reason.stack));
	}

	// TODO - move this to react style solution
	handleScroll = () => {
		if (window.pageYOffset > 5) {
			document.getElementById('header').classList.add('header--shadow');
		}
		if (window.pageYOffset < 5) {
			document.getElementById('header').classList.remove('header--shadow');
		}
	}

	render() {

		if (this.state.loading) {
			//console.log('A.125 Spin', Date.now() );
			return (
				<div className="app-loading-box">
					<Spinner
						className="home-records__spinner"
						active={this.state.loading}
						color="#00BAFF"
						/>
				</div>
			);
		}

		const isSignedIn = this.props.profile.isSignedIn;
		const audience = this.props.audience;
		const appClasses = [ 'app' ];
		if (isSignedIn) {
			appClasses.push('app--signed-in')
		}

		// ADD PARTNRER THEME CLASS - e.g. jbc-theme
		appClasses.push(`${audience.brand.code}-theme`.toLowerCase());


		//console.log('APP.125 Pre-Return', Date.now());
		//console.log('H.127', audience.brand.code, Date.now());

		return (
			<BrowserRouter>
				<LocationListener>
					<div className={appClasses.join(' ')}>
						<Header />
						<div className="content">
							<Switch>
								{/* <Route
									exact
									path="/"
									component={Home}
								/> */}
								<Route
									exact
									path="/"
									render={routeProps => {
										if (!audience.brand.code || audience.brand.code === 'LTL' || audience.brand.code === 'IMG' ) {
											//console.log('APP.145 HOME',routeProps);
											return <Home {...routeProps } partnercode={'CORE'+audience.brand.code}/>;
										}
										//console.log('APP.148 HOMEPARTNER',routeProps);
										return <HomePartner {...routeProps} partnercode={audience.brand.code} />; }
									}
									/>
								<Route
									exact
									path="/privacy-policy"
									component={PrivacyPolicy}
								/>
								<Route
									exact
									path="/help"
									component={Help}
								/>
								<Route
									exact
									path="/about"
									component={About}
								/>
								<AuthRoute
									exact
									path="/register"
									component={Register}
									signInRequired={false}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/sign-in"
									component={SignIn}
									signInRequired={false}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/reset-password"
									component={ResetPasswordRequest}
									signInRequired={false}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/reset-password/action/:id"
									component={ResetPasswordAction}
									signInRequired={false}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/welcome"
									component={Welcome}
									signInRequired={true}
									isSignedIn={isSignedIn}
								/>
								<Route
									exact
									path="/verify-email/action/:id"
									component={Verify}
								/>
								<Route
									exact
									path="/products"
									component={Products}
								/>
								<Route
									exact
									path="/search"
									component={SearchPage}
								/>
								<Route
									exact
									path="/contact-us"
									component={ContactUs}
								/>
								<AuthRoute
									exact
									path="/cart"
									component={Cart}
									signInRequired={true}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/lifetimelines"
									component={LifeTimeLines}
									signInRequired={true}
									isSignedIn={isSignedIn}
								/>
								<AuthRoute
									exact
									path="/dashboard"
									component={Dashboard}
									signInRequired={true}
									isSignedIn={isSignedIn}
								/>
								<Route
									path="/account/:id?" // See Account index.js for routes
									render={(routeProps) => <Account {...routeProps} isSignedIn={isSignedIn} />}
								/>
								<Route
									path="/" // See LifeTimeLine index.js for routes
									render={(routeProps) => <LifeTimeLine {...routeProps} isSignedIn={isSignedIn} />}
								/>
							</Switch>
						</div>
						<Message message={this.props.global.message} />
						<Modal />
					</div>
				</LocationListener>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		global: state.global,
		profile: state.profile,
	};
};

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch) => {
	return {
		onGetAudience: (data) => {
			dispatch({
				type: 'GET_AUDIENCE',
				data,
			});
		},
		setCartItems: (cartID, data) => {
			dispatch({
				type: 'SET_CART_ITEMS',
				cartID,
				data,
			});
		},
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
		onSetBreakpoint: (data) => {
			dispatch({
				type: 'SET_BREAKPOINT',
				data,
			});
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(App);