import React from 'react';

import './Settings.scss';

const Settings = (props) => {

	return (
		<div className="account__page--title">
			Settings
		</div>
	);
};

export default Settings;