const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ordinalIndicator = n => (n%10===1 && n%100!==11?'st':n%10===2&&n%100!==12?'nd':n%10===3 && n%100!==13?'rd':'th'); // 1st, 2nd, 3rd, 4th, 111th etc

const formatDate = (dateString, dateMask = 'DD MMMM YYYY', timeMask = undefined) => {

    // Verify the specific format (contains a 'T') and TZD
    let tzd = 0;
    if (dateString.indexOf('T') > -1) {
        let dateTimeString = dateString.substring(dateString.indexOf('T')+1,dateString.length);
        if (dateTimeString.indexOf('Z') > -1) {
        console.log('ZULU (so use default of 0)');
        } else {
        let tzdPlus = dateTimeString.indexOf('+');
        let tzdMinus = dateTimeString.indexOf('-');
        if(tzdPlus > -1) {
            tzd = dateTimeString.substring(tzdPlus+1,dateTimeString.length);
        } else if(tzdMinus > -1) {
            tzd = dateTimeString.substring(tzdMinus+1,dateTimeString.length);
        }
        console.log('RESOLVED TZD (may be default of 0)',tzd);
        }
    } else {
        console.log('Invalid Date Format. Required YYYY-MM-DDThh:mm[:ss]+0000');
    }

    let dateObj = new Date(dateString);

    let d = dateObj.getUTCDate();
    let ord = ordinalIndicator(d);
    let dp = ('00' + d).slice(-2);
    let mi = dateObj.getUTCMonth();
    let m = dateObj.getUTCMonth()+1;
    let mp = ('00' + m).slice(-2);
    let y = ('00000' + dateObj.getUTCFullYear()).slice(-4);
    let h = dateObj.getUTCHours();
    let n = dateObj.getUTCMinutes();
    let timeStringOut = undefined;
    let dateStringOut = undefined;
    if(isNaN(d) || isNaN(m) || isNaN(y)){
        //console.log('ERROR====:', date, ' is NaN');
        return undefined;
    }
    if( h + n > 0 ){
        let hp = ('00' + h).slice(-2);
        let np = ('00' + n).slice(-2);
        // switch over timeMask to put time together
        switch(timeMask) {
            case 'h:nn':							//  e.g.  6:30		e.g. 19:25
                timeStringOut = h + ':' + np;
                break;
            case 'hh:nn':							//  e.g. 06:30		e.g. 19:25
                timeStringOut = hp + ':' + np;
                break;
            case 'h:nn A':							//  e.g.  6:30 am	e.g.  7:25 pm
                const ha = h > 12 ? h-12 : h;
                const ampm = h > 12 ? "pm" : "am";
                timeStringOut = ha + ':' + np + ' ' + ampm;
                break;
            default:
                timeStringOut = undefined;
        }
    }
    switch(dateMask) {
        case 'DD-MMM-YYYY':							//  06-Mar-1971
            dateStringOut = dp + '-' + monthNames[mi].substring(0,3) + '-' + y;
            break;
        case 'DD-MM-YYYY':							//  06-03-1971
            dateStringOut = dp + '-' + mp + '-' + y;
            break;
        case 'D MMMM YYYY':							//  6 March 1971
            dateStringOut = d+ord + ' ' + monthNames[mi] + ' ' + y;
            break;
        default: 									// DD MMMM YYYY
            dateStringOut = dp + ' ' + monthNames[mi] + ' ' + y;
    }
    return dateStringOut + (timeStringOut ? ', '+timeStringOut : '');
}


export const dateText = (dateString, estimated, year, month, day) => {
    let ds = undefined;
    if(day) {
        ds = day + ' ' + monthNames[month-1] + ' ' + year;
    } else if(month) {
        ds = monthNames[month-1] + ' ' + year;
    } else if (year) {
        ds = year;
    } else if(dateString){
        ds = formatDate(dateString,'D MMMM YYYY','h:nn A');				//,'DD MMMM YYYY','hh:nn A'
	}
    return ds;
}
