import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from '../Form/Input';
import Image from '../Form/Image';
import Button from '../Form/Button';
import DateMenu from '../../components/DateMenu/DateMenu';
import FeedPanel from '../../components/FeedPanel/FeedPanel';
import Form from '../Form/Form';
import SharePanel from '../SharePanel';

import * as util from '../../utils/general';
//import * as timeLineAPI from '../../api/timeLineAPI';

import './SummaryPanel.scss';

class SummaryPanel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			formLoading: false,
			imageLoading: false,
			dateMenuActive: false,
			summaryDescriptionActive: false,
			summaryFeedActive: false,
			formErrors: {},
			isFormValid: true,
		};
	}

	//	Add and remove header shadow on scroll
	componentDidMount = () => {
		window.addEventListener('scroll', this.handleScrollMoments);
	};

	componentWillUnmount = () => {
		window.removeEventListener('scroll', this.handleScrollMoments);
	};

	// TODO - move this to react style solution - see app.js for similar
	handleScrollMoments = () => {
		if (window.pageYOffset > 0) {
			this.handlePanelToggle('all');
			//this.onCancelEdit();
		}
	};

	renderDateMenuToggle(isMobile_L) {
		if (!isMobile_L) {
			return null;
		} else {
			return (
				<div className="summaryPanel__interaction summaryPanel__dateMenuToggle" onClick={(e) => this.handlePanelToggle('dateMenu')}>
					<i className={`fa fa-calendar ${this.state.dateMenuActive && ' fa--active'}`} />
				</div>
			)
		}
	};

	renderCommentsToggle(isTablet) {
		if (!isTablet) {
			return null;
		} else {
			return (
				<div className="summaryPanel__interaction" onClick={(e) => this.handlePanelToggle('summaryFeed')}>
					<i className={`fa fa-comment ${this.state.summaryFeedActive && ' fa--active'}`} />
				</div>
			)
		}
	};

	renderSummaryDescriptionToggle(isTablet) {
		if (!isTablet) {
			return null;
		} else {
			return (
				<div className="summaryPanel__interaction summaryPanel__descriptionToggle" onClick={(e) => this.handlePanelToggle('summaryDescription')}>
					<i className={`fa fa-newspaper-o ${this.state.summaryDescriptionActive && ' fa--active'}`} />
				</div>
			)
		}
	};

	renderSummaryDescription(isTablet, description, editAllowed) {
		if (!this.props.editMode && !this.state.summaryDescriptionActive && isTablet) {
			return null;
		} else {
			return (
				<div className="summaryPanel__descriptionPanel">
					<Input
						type="textarea"
						name="description"
						className="summaryPanel__field summaryPanel__description"
						placeholder="Enter a short description of the person up to 500 characters."
						value={description || ''}
						maxlength={500}
						onValidate={this.checkForm}
						onChange={this.props.handleInputChange}
						editMode={this.props.editMode}
						editable={editAllowed}
						error={this.state.formErrors['Description']}
					/>
				</div>
			);
		}
	};

	checkForm = (name, value, error) => {
		const formErrors = Object.assign({}, this.state.formErrors);
		formErrors[name] = error;

		if (name === 'fromYear') {
			const val = parseInt(value, 10)

			if (isNaN(val) || val <= 0) {
				formErrors.fromYear = 'The Start Year is required.';
				formErrors.toYear = null;
			} else if (
				val > parseInt(this.props.lifeTimeLineData.toYear, 10) &&
				parseInt(this.props.lifeTimeLineData.toYear, 10) !== 0
			) {
				formErrors.fromYear = 'The Start Year must be before the End Year.';
				formErrors.toYear = null;
			} else {
				formErrors.fromYear = null;
				formErrors.toYear = null;
			}
		} else if (name === 'toYear') {
			if (parseInt(value, 10) < parseInt(this.props.lifeTimeLineData.fromYear, 10)) {
				formErrors.toYear = 'The End Year must be later than the Start Year.';
				formErrors.fromYear = null;
			} else {
				formErrors.fromYear = null;
				formErrors.toYear = null;
			}
		}

		let isFormValid = true;

		Object.keys(formErrors).forEach((key) => {
			if (formErrors[key]) {
				isFormValid = false;
			}
		});

		this.setState({
			formErrors,
			isFormValid,
		});
	};

	renderSummaryFeed(isMobile_L, editAllowed) {
		// TASK: Update this mock up with the actual API.
		const activityFeed = {
			feedItems: [
				{	itemType: 'Comment',
					itemID: 46,
					itemDateTime: '2017-11-05 16:36',
					itemContent: "Thanks Peter. It's good to have you along for the journey!",
					author: {
						entityID: 31,
						firstName: 'Chester',
						lastName: 'Nutting',
					},
				},
				{	itemType: 'Comment',
					itemID: 91,
					itemDateTime: '2017-11-03 18:22',
					itemContent: 'Fantastic record of such an interesting life Chester.',
					author: {
						entityID: 36,
						firstName: 'Peter',
						lastName: 'Jennings',
					},
				},
			]
		}
		let tempActivityFeed = { feedItems: [] };
		if (this.props.lifeTimeLineData.timelineId === 1) {		// TEMP hack in some coments for Chester's LifeTimeLine
			tempActivityFeed = activityFeed;
		} else if (this.props.lifeTimeLineData.activityFeed) {
			tempActivityFeed = this.props.lifeTimeLineData.activityFeed;
		}
		// END TASK: Change to get feed from JSON package

		if (!this.state.summaryFeedActive && isMobile_L) {
			return null;
		} else {
			return (
				<div className="summaryPanel__activity-feed">
					<FeedPanel
						activityFeed={tempActivityFeed} // TASK: revert to this.props.lifeTimeLineData.activityFeed once API complete
					/>
				</div>
			)
		}
	};

    // onEdit = (isMobile_L) => {
    //     if (isMobile_L && !this.state.summaryDescriptionActive) {
    //         this.handlePanelToggle('summaryDescription'); // force open the descriptionPanel so the edit can take place
    //     }
    //     this.setState({ editMode: true });
    // }

	handlePanelToggle = (actionPanel) => {
		// Description Panel requires special treatment as when < Mobile-L, it will be open in edit mode and must remain so.
		const newSummaryDescriptionPanelState =  this.props.editMode 					? this.state.summaryDescriptionActive 	:
												 actionPanel !== "summaryDescription" 	? false 								:
																						  !this.state.summaryDescriptionActive	;
		this.setState({
			dateMenuActive: actionPanel !== "dateMenu" ? false : !this.state.dateMenuActive,
			summaryDescriptionActive: newSummaryDescriptionPanelState,
			summaryFeedActive: actionPanel !== "summaryFeed" ? false : !this.state.summaryFeedActive,
		});
	};

	renderDateMenu = (isTabletAndBelow) => {
		const fixedMenuItemCount = isTabletAndBelow ? 40 : null;
		if(isTabletAndBelow && !this.state.dateMenuActive) {
			return null
		} else {
			return (
				<DateMenu
					fromYear={this.props.lifeTimeLineData.fromYear}
					toYear={this.props.lifeTimeLineData.toYear}
					fixedMenuItemCount={fixedMenuItemCount}
					handleDateMenuToggle={(e) => this.handlePanelToggle('dateMenu')}
				/>
			)
		}
	};

	renderInteractions = (editMode, isTablet, isMobile_L, isMinimumSet) => {
		if (editMode) {
			if (this.props.global.breakpoint.level > util.breakpoints['tablet']) {
				return null;
			} else {
				const nestedInInteraction = true;														// for clarity
				return this.renderSaveCancel(editMode, isMinimumSet, nestedInInteraction);
			}
		}

		const {
			timelineId,
			title
		} = this.props.lifeTimeLineData

		return (
			<div className="summaryPanel__interactions">
				{/* <div className="summaryPanel__interaction">
					<i className="fa fa-heart-o" />
				</div>
				<div className="summaryPanel__interaction">
					<i className="fa fa-share-alt" />
				</div> */}
				<SharePanel
					className="summaryPanel__interaction"
					position="bottomCentre"
					lifeTimeLineID={timelineId}
					title={title}
				/>
				{this.renderCommentsToggle(isTablet)}
				{this.renderDateMenuToggle(isTablet)}
				{this.renderSummaryDescriptionToggle(isTablet)}
			</div>
		);
	};


	renderSaveCancel = (editMode, isMinimumSet, nestedInInteractions) => {
		if (!editMode) {
			return null;
		}
		// Button location varies base don device. Desktop they're below summary and mobile devices in place of the interaction icons.
		const isDesktop = this.props.global.breakpoint.level > util.breakpoints['tablet'];
		if (!editMode || (nestedInInteractions && isDesktop) || (!nestedInInteractions && !isDesktop) ) {
			return null;
		} else {
			return (
				<div className="summaryPanel__buttons">
					{isMinimumSet &&
						<Button
							onClick={this.props.handleCancelEdit}
							className="summaryPanel__button"
						>
							Cancel
						</Button>
					}
					<Button
						color="blue"
						className="summaryPanel__button"
						onClick={this.props.saveLifeTimeLine}
						disabled={!this.state.isFormValid}
						isSubmit={true}
						loading={this.props.saving}
					>
						Save
					</Button>
				</div>
			);
		}
	};

	render = () => {
		if (!this.props.lifeTimeLineData || Object.keys(this.props.lifeTimeLineData).length === 0) {
			return <div className="summaryPanel"><div className="summaryPanel--loading">Loading...</div></div>;
		}

		const {
			image,
			title,
			fromYear,
			toYear,
			description,
		} = this.props.lifeTimeLineData;


		const formErrors = this.state.formErrors;

		const {
			editMode,
			editAllowed,
			isMinimumSet,
		} = this.props;

		const isMobile_L = this.props.global.breakpoint.level <= util.breakpoints['mobile-l'];
		const isTablet = this.props.global.breakpoint.level <= util.breakpoints['tablet'];
		const nestedInInteraction = false;											// for clarity, used to draw form controls outside of the interactions panel based on device

		const classes = [ 'summaryPanel' ];
		if (this.props.profile.isSignedIn && this.props.author === 'author') {
			classes.push('summaryPanel--author');
		}
		if (!isMinimumSet) {
			classes.push('summaryPanel--no-min');
		}
		if (editMode) {
			classes.push('summaryPanel--edit');
		}

		return (
			<div className={classes.join(' ')}>
				<div className="summaryPanel__allAndDateMenu">
					<div className="summaryPanel__imageDataDescriptionAndFeed">
						<div className="summaryPanel__imageDataAndDescription">
							<Form>
								<div className="summaryPanel__imageAndData">
									<Image
										image={image}
										alt={title}
										editable={editMode}
										onChange={this.props.handleImageChange}
										onImageUpload={this.props.handleImageUploaded}
										className="summaryPanel__image"
										defaultIcon="fa-user"
										lazyLoading={false}
									/>
									<div className="summaryPanel__dataAndInteractions">
										<div className="summaryPanel__data">
											<Input
												type="text"
												name="title"
												className={`summaryPanel__field summaryPanel__title ${editMode && 'summaryPanel__title--editMode'}`}
												placeholder="Name"
												value={title}				// test longname "Chester Wolfeschlegelsteinhausenbergerdorff"
												required={true}
												onValidate={this.checkForm}
												editMode={editMode}
												editable={editAllowed}
												onChange={this.props.handleInputChange}
												error={formErrors['title']}
											/>
											<div className={`summaryPanel__dates ${editMode && 'summaryPanel__dates--editMode'}`}>
												<Input
													type="text"
													name="fromYear"
													className="summaryPanel__field summaryPanel__date"
													placeholder="From YYYY"
													value={fromYear > 0 ? fromYear : ''}
													editMode={editMode}
													editable={editAllowed}
													onValidate={this.checkForm}
													onChange={this.props.handleInputChange}
													error={formErrors['fromYear']}
													required
												/>
												to
												<Input
													type="text"
													name="toYear"
													className="summaryPanel__field summaryPanel__date"
													placeholder="present"
													value={toYear > 0 ? toYear : ''}
													editMode={editMode}
													editable={editAllowed}
													onValidate={this.checkForm}
													onChange={this.props.handleInputChange}
													error={formErrors['toYear']}
												/>
											</div>
										</div>
										{this.renderInteractions(editMode, isTablet, isMobile_L, isMinimumSet)}
									</div>
								</div>
								{this.renderSummaryDescription(isTablet, description, editAllowed)}
								{this.renderSaveCancel(editMode, isMinimumSet, nestedInInteraction)}
							</Form>
						</div>
						{this.renderSummaryFeed(isTablet)}
					</div>
					{this.renderDateMenu(isTablet)}
				</div>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
		global: state.global,
	};
};

export default connect(
	mapStateToProps,
)(SummaryPanel);
