import React from 'react';
import { connect } from 'react-redux';

import Backdrop from '../Backdrop';

import { DeleteMoment, ClearCart } from './Modals';

import './Modal.scss';

const Modal = ({ type, data, onClose }) => {

	let content = null;

	switch (type) {
		case 'delete-moment':
			content = <DeleteMoment {...data} onClose={onClose} />;
			break;
		case 'clear-cart':
			content = <ClearCart {...data} onClose={onClose} />;
			break;
		default:
			content = null;
	}
	return (
		<Backdrop isOpen={!!content} onClose={onClose}>
			{content}
		</Backdrop>
	);
}

const mapStateToProps = (state) => ({
	...state.global.modal,
});

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClose: (data) => {
			dispatch({
				type: 'SET_MODAL',
				data: {
					type: null,
					data: {},
				},
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Modal);