import React from 'react';
import './PageMenu.scss';

const PageMenu = ({ children, ...otherProps }) => {
	if (!children || children.length === 0) {
		return null;
	}

	return (
		<div {...otherProps}>
			{children}
		</div>
	);
}

export default PageMenu;
