import React, { Component, Fragment } from 'react';

import Button from '../Form/Button';
import Backdrop from '../Backdrop';

import * as defer from '../../utils/defer';

import './SignInPanel.scss';

// TASK: Expand this component so actions other than add product to cart can be used (e.g. Likes)
class SignInPanel extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: props.isOpen,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isOpen !== this.state.isOpen) {
			this.setState({ isOpen: nextProps.isOpen });
		}
	}

	onClose = () => this.setState({ isOpen: false });

	onSignIn = () => {
		const {
			productID,
			history,
		} = this.props;

		defer.put('ADD_PRODUCT_TO_CART_ON_SIGN_IN', productID);

		history.push('/sign-in');
	};

	onRegister = () => {
		const {
			productID,
			history,
		} = this.props;

		defer.clear('ADD_PRODUCT_TO_CART_ON_SIGN_IN');
		defer.put('ADD_PRODUCT_TO_CART_ON_SIGN_IN', productID);

		history.push('/register')
	};

	render() {
		if (!this.state.isOpen) {
			return null;
		}

		return (
			<Fragment>
				<div className="signin-panel">
					<div className="signin-panel__instruction">
						Sign in or Register to add this to your cart.
					</div>
					<div className="signin-panel__buttons">
						<Button onClick={this.onSignIn}>Sign in</Button>
						<Button
							onClick={this.onRegister}
							color="blue"
						>
							Register
						</Button>
					</div>
				</div>
				<Backdrop
					className="signin-panel__backdrop"
					isOpen={true}
					onClose={this.onClose}
				/>
			</Fragment>
		);
	}
}

export default SignInPanel;
