import React, { Component } from 'react';
import { connect } from 'react-redux';

//import Button from '../../../components/Form/Button';
//import Input from '../../../components/Form/Input';
//import Image from '../../../components/Form/Image';
//import Form from '../../../components/Form/Form';


import './Details.scss';

class Transactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			transactions: null,
		};
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ transactions: nextProps.transactions, });
	}




	render() {

		const {
			transactions
		} = this.state;



		if (!transactions) {
			return (
				<div className="account__page">
					<div className="account__page--title">Could not return the Transaction Data</div>
				</div>
			)
		}
		return (
			<div className="account__page">
				<div className="account__page--title">
					Transactions
				</div>
				<div className="account__page--form">

					<div className="account__page--inputs">
						<div className="account__page--input">

						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		transactions: state.transactions,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetMessage: (data) => {
		dispatch({
			type: 'SET_MESSAGE',
			data,
		});
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Transactions);
