import React from 'react';
import { Link } from 'react-router-dom';

import * as textUtils from '../../utils/text';

import Image from '../Form/Image';

import './RecordBox.scss';

const RecordBox = ({ timelineId, image, title, fromYear, toYear, className, isPublic, isImageLazyLoaded = true }) => {
	const timespan = textUtils.formatTimespan(fromYear, toYear);

	const classes = [ 'recordBox' ];
	if (className) {
		classes.push(className);
	}

	return (
		<Link
			to={`/${!isPublic ? 'author/' : ''}${timelineId}`}
			className={classes.join(' ')}
		>
				<div className="recordBox__frame--top">
					<Image
						className="recordBox__image"
						defaultIcon="fa-user"
						image={image}
						alt={title}
						lazyLoading={isImageLazyLoaded}
						thumbnails={true}
					/>
				</div>
				<div className="recordBox__frame--base">
					<div className="recordBox__fullName">
						{title}
					</div>
					{timespan &&
						<div className="recordBox__years">
							{timespan}
						</div>
					}
				</div>
		</Link>
	);
};

export default RecordBox;
