import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as personAPI from '../../api/personAPI';

import TextInput from '../../components/FocusBox/Inputs/TextInput';
import Select from '../../components/FocusBox/Inputs/Select';
import InputGroup from '../../components/FocusBox/Inputs/InputGroup';
import Button from '../../components/Form/Button/Button';

import './NewLifeTimeLine.scss';

class NewLifeTimeLine extends Component {
	state = {
		firstName: '',
		lastName: '',
		entityType: 'Person',
		errorMessage: '',
	};

	onInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		this.setState({
			[name]: value,
			errorMessage: '',
		});
	};

	onAddNewLifeTimeLine = (e) => {
		e.preventDefault();

		const {
			firstName,
			lastName,
			entityType,
		} = this.state;

		if (!firstName && !lastName) {
			this.setState({ errorMessage: 'Please enter a First Name or Surname.' });
			return;
		}

		personAPI.register(undefined, undefined, firstName, lastName)
			.then(resp => {
				if (resp.success) {
					this.props.onAddLifeTimeLine({
						timelineId: 'new',
						ownerEntityId: this.props.ownerEntityID,
						subjectEntityId: resp.data.entityId,
						subjectEntityType: entityType,
						title: `${firstName || ''} ${lastName || ''}`.trim(),
						fromYear: 0,
						toYear: 0,
					});
				} else {
					// TASK: Handle error
				}
			});
	};

	onAddOwnLifeTimeLine = (e) => {
		e.preventDefault();

		this.props.onAddLifeTimeLine({
			timelineId: 'new',
			ownerEntityId: this.props.ownerEntityID,
			subjectEntityId: this.props.ownerEntityID,
			subjectEntityType: this.state.entityType,
			title: this.props.title,
			fromYear: 0,
			toYear: 0,
		});
	};

 	render() {
		const {
			isCancelable,
		} = this.props;

		const buttonsClasses = [ 'focusBox__buttons' ];
		if (isCancelable) {
			buttonsClasses.push('focusBox__buttons--cancelable');
		}

		const errors = [];
		if (this.state.errorMessage.length > 0) {
			errors.push(this.state.errorMessage);
		}

		return (
			<div className="recordBox recordBox--new" onClick={e => e.stopPropagation()}>
				<div className="newRecord__title">Add a New LifeTimeLine</div>
				<div className="newRecord">
					<InputGroup>
						<Select
							name="entityType"
							editable={true}
							editMode={true}
							onChange={this.onInputChange}
						>
							<option value="person">
								for a Person
							</option>
							<option value="pet" disabled className="option__disabled">
								for a Pet (coming soon)
							</option>
							<option value="club" disabled className="option__disabled">
								for a Club (coming soon)
							</option>
							<option value="business" disabled className="option__disabled">
								for a Business (coming soon)
							</option>
							<option value="organisation" disabled className="option__disabled">
								for a Organisation (coming soon)
							</option>
							<option value="building" disabled className="option__disabled">
								for a Building (coming soon)
							</option>
							<option value="location" disabled className="option__disabled">
								for a Location (coming soon)
							</option>
						</Select>
						<TextInput
							name="firstName"
							placeholder="First Name"
							icon="fa-user-secret"
							onChange={this.onInputChange}
							validationMessages={errors}
							forceShowErrors={true}
						/>
						<TextInput
							name="lastName"
							placeholder="Surname"
							icon="fa-id-badge"
							onChange={this.onInputChange}
							forceInvalid={errors.length > 0}
						/>
					</InputGroup>
					<div className={buttonsClasses.join(' ')}>
						{isCancelable &&
							<Button
								className="focusBox__cancel"
								onClick={this.props.onCancel}
							>
								Cancel
							</Button>}
						<Button
							className="focusBox__button newRecord__button"
							onClick={this.onAddNewLifeTimeLine}
						>
							Add a New Record
						</Button>
					</div>
				</div>
				<Link
					to="/author/new"
					className="newRecord__self"
					onClick={this.onAddOwnLifeTimeLine}
				>
					or, Add my own LifeTimeLine
				</Link>
			</div>
		);
	}
};

export default NewLifeTimeLine;
