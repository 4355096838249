const initialState = {
	cartID: 0,
	items: [],
};

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_CART_ITEMS': {
			const cartID = action.cartID;
			const items = action.data;

			return {
				...state,
				cartID,
				items,
			};
		}
		case 'SET_CART_OPTION_CHOICE': {
			const {
				baseProductOptionId,
				cartItemId,
				data,
				displayOrder,
				displayText,
			} = action.data;

			const items = state.items.slice();
			const item = items.find((item) => item.cartItemId === cartItemId);

			if (!item) {
				return state;
			}

			// TASK: Handle multiple option responses
			item.optionResponses = [ action.data ];

			const optionIndex = item.optionChoices.findIndex(
				(item) => item.cartItemId === cartItemId &&
				item.baseProductOptionId === baseProductOptionId &&
				item.value === data
			);

			if (optionIndex === -1) {
				item.optionChoices.unshift({
					baseProductOptionId,
					cartItemId,
					displayOrder,
					displayText,
					value: data,
				});
			}

			return {
				...state,
				items,
			};
		}
		case 'ADD_CART_ITEM': {
			const items = state.items.slice();

			items.push(action.data);

			return {
				...state,
				items,
			};
		}
		case 'REMOVE_CART_ITEM': {
			const items = state.items.slice();
			const index = items.findIndex((item) => item.cartItemId === action.data.cartItemId);

			items.splice(index, 1);

			return {
				...state,
				items,
			};
		}
		default:
			return state;
	}
}

export default reducer;
