import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { brandConfig } from '../../api/brandConfig';

import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './ContactUs.scss';

class PrivacyPolicy extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};

	}



	render() {

		return (
			<div className="contact-us__page">
				<div className="main-slider">
					<div className="main-slider__centred">
						<div className="main-slider__textBlock">
							<h1>We are here to help.</h1>
							<p>We love to get feedback from users.</p>
							<p>We usually get back to you<br/>within 24 business hours.</p>
						</div>
						<Logo
							className="main-slider__logo"
							brand={brandConfig['inmemoriam'].brand}
							logoContext="mainSlider"
						/>
					</div>
				</div>
				<div className="page-content__wrapper">
					<div className="page-content">

					<h1>Contact Us</h1>
					<p>While we are very busy getting our new product up and running, we are keen to hear from you.</p>
					<p>If you need help with any part of the system or have feedback for us, drop us an email.</p>
					<p><a href="mailto:support@lifetimeline.com">support@lifetimeline.com</a></p>
					<p>&nbsp;</p>

					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

export default PrivacyPolicy;
