import React, { Component } from 'react';

class Form extends Component {
	elements = [];

	registerChild = (child) => {
		if (child === null) {
			return;
		}

		const fieldType = child.type && child.type.fieldType;

		switch (fieldType) {
			case 'Switch':
			case 'FormImage':
			case 'Input':
				return React.cloneElement(
					child,
					{ ref: input => input && this.elements.push(input) },
					React.Children.map(child.props.children, this.registerChild),
				);
			case 'Button':
				if (child.props.isSubmit) {
					return React.cloneElement(
						child,
						{ onClick: () => this.onSubmit(child.props.onClick) },
						React.Children.map(child.props.children, this.registerChild),
					);
				}
				break;
			default:
		}

		if (child.props && child.props.children) {
			return React.cloneElement(
				child,
				null,
				React.Children.map(child.props.children, this.registerChild),
			);
		}

		return child;
	};

	onSubmit = (submitFunc) => {
		let data = {};
		let valid = true;

		this.elements.forEach(element => {
			const value = element.state.value;

			data = {
				...data,
				[element.props.name]: value,
			};

			if (!element.validate) {
				return;
			}

			if (element.validate(value) !== null) {
				valid = false;
			}
		});

		if (valid && submitFunc) {
			submitFunc(data);
		}
	};

	render() {
		this.elements = [];

		return React.Children.map(this.props.children, this.registerChild);
	}
}

export default Form;
