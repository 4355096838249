import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import FocusBox from '../../../components/FocusBox/FocusBox';

import * as personAPI from '../../../api/personAPI';

import './Verification.scss';

class Welcome extends Component {
	state = {
		isSuccess: true,
		message: null,
	};

	resendEmail = () => {
		personAPI.requestEmailVerify(this.props.profile.entityId)
			.then(resp => {
				if (resp.success) {
					this.setState({
						message: 'Verification Email successfully re-sent.',
						isSuccess: true,
					});
				} else {
					this.setState({
						message: 'Something went wrong. Please try again.',
						isSuccess: false,
					});
				}
			});
	};

	renderMessage = (isSuccess, message) => {
		if (!message) {
			return null;
		}

		const classes = [ 'verify__message' ];

		if (!isSuccess) {
			classes.push('verify__message--error');
		}

		return (
			<div className={classes.join(' ')}>
				{this.state.message}
			</div>
		);
	};

	render() {
		return (
			<FocusBox className="verify" brand={this.props.audience.brand}>
				<div>
					<div className="verify__content">
						<div className="focusBox__title">Verification Email Sent!</div>
						<div className="focusBox__text">
							You're almost done. Check your email for a link we have sent you. Click the link to verify you own the email address then you'll be logged in and ready to go.
							<br /><br />
							If you don't see the email we sent you in a minute or so, check it has not ended up in your spam folder. If you still can't find it, <Link to="/welcome" onClick={this.resendEmail}>click here to re-send</Link> or <Link to="/contact-us">contact-us</Link> for help.
						</div>
						{this.renderMessage(this.state.isSuccess, this.state.message)}
					</div>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	audience: state.audience,
});

export default connect(
	mapStateToProps,
)(Welcome);
