import React from 'react';

import './Associations.scss';

const Associations = (props) => {

	return (
		<div className="account__page--title">
			Associations
		</div>
	);
};

export default Associations;