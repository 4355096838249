import { refresh } from './loginAPI';

import { dispatchError } from '../utils/general';

let refreshing = false;

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

async function sendFetchRequest(endpoint, config, secure = true) {
	let success = true;
	let status = 200;

	if (secure) {
		const refreshToken = window.localStorage.getItem('refresh_token');

		if (refreshToken) {
			const expiry = window.localStorage.getItem('expiry');

			// Check if the access token has expired
			if (getUnixTimeInSeconds() >= expiry) {
				if (refreshing) {
					await sleep(500);
					return sendFetchRequest(endpoint, config, secure);
				}

				refreshing = true;
				// Return to stop/cancel the fetch reqest, refresh the token, then re-try the fetch request
				return refresh(refreshToken)
					.then(resp => {
						refreshing = false;
						return sendFetchRequest(endpoint, config, secure);
					})
					.catch(err => {
						dispatchError(err);
						window.localStorage.clear();
						window.location = '/sign-in';
					});
			}
		}

		// If there is an access token present, then add it as a request header
		const accessToken = window.localStorage.getItem('access_token');

		if (accessToken) {
			if (!config) {
				config = {};
			}

			if (!config.headers) {
				config.headers = {};
			}

			Object.assign(config.headers, { 'Authorization': `Bearer ${accessToken}` });
		}
	}

	return fetch(endpoint, config)
		.then(resp => {
			if (resp.status >= 500) {
				throw new Error(resp.statusText);
			}
			return resp;
		})
		.then(resp => {
			if (resp.status >= 400) {
				success = false;
				status = resp.status;
			}
			return resp;
		})
		.then(resp => resp.text())
		.then(text => {
			try {
				return text ? JSON.parse(text) : {};
			} catch(e) {
				return {};
			}
		})
		.then(json => ({
			success,
			status,
			data: json
		}));
};

// formURLEncode takes an object and encodies it in the x-www-form-urlencoded format. It does not handle nested data.
const formURLEncode = (data) => (
	Object.keys(data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`).join('&')
);

const getUnixTimeInSeconds = () => Number((new Date().getTime() / 1000).toFixed(0));

const getApiContext = (serviceContext = 'api') => {
	// REMEMBER this is to get the API (and IDSRV) service addresses. Not the Front End.
	// It was decided to include the full url in this function in case the api domain changes.
	// At this time serviceContext could be either ['api'|'idsrv'], the default being 'api'
	const thisHostName = window.location.hostname; // the host name the front end is running under.
	const productionUrls = [
		'inmemoriam.com',
		'lifetimeline.com',
		'jbc.lifetimeline.com',
		'glenaeon.lifetimeline.com'
	];
	/*	hosts can uses either dev, qa or live services (api and idsrv)
		Aside from named domains (e.g. optimus.lifetimeline.com), where
		*qa. is the end of the subdomain, get QA services
		*dev. is the end of the subdomain, get DEV services
		QA regex at:  regexr.com/4h6vq
		DEV Regex at: regexr.com/4h703
		Note: the linter freakes out at implicit regex setting so call the ignore before each. (otherwise can use new RegExp('') on the string with escaped \s
	*/
	// eslint-disable-next-line
	const useQAServicesRegex = /^((optimus|syd|din\.staging)|([a-z0-9]+-)?(dev)?qa)\.(?=[a-z0-9\._-]+$)/i;
	// eslint-disable-next-line
	const useDEVServicesRegex = /^((([a-z0-9]+-)?dev)|din)\.(?=[a-z0-9\._-]+$)/i;

	let portSuffix = '';												// only used in dev as it's not possible to run two services on the same port
	if (useDEVServicesRegex.test( thisHostName )) {
		portSuffix = serviceContext !== 'api' ? ':56080' : ':55080';
	}

	if ( productionUrls.indexOf( thisHostName ) > -1 ) {
		return `https://${serviceContext}.inmemoriam.com`;
	} else if ( useQAServicesRegex.test( thisHostName ) ) {
		return `https://${serviceContext}.staging.inmemoriam.com`;
	} else if ( useDEVServicesRegex.test( thisHostName ) ) {
		return `http://imgdev.localhost` + portSuffix;
	} else {
		console.log('UNKNOWN Host',thisHostName);						// TASK: add to AlertLog
		return null;
	}
}

export { sendFetchRequest, formURLEncode, getApiContext };
