import React from 'react';
import Logo from '../../components/Brand/Logo';
import { brandConfig } from '../../api/brandConfig';

import './Footer.scss';

const Footer = () => (
	<div className="footer">
		<div className="footer--centre">

			<div className="footer__column">


				<p><a className="footer-ask-link" href="mailto:ask@lifetimeline.com">ask@lifetimeline.com</a></p>
				<p>You can also connect with us on social media.</p>
				<p className="footer__socialIcons">
					<a href="https://www.facebook.com/lifetimeline.com.au/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-official"></i></a>
					<a href="https://twitter.com/LifeTimeLineCOM" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter-square"></i></a>
					<a href="https://www.linkedin.com/company/in-memoriam-group/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square"></i></a>
				</p>


				<div className="footer__column--subBlock">
					<div className="footer__column--leftBlock">
						<a href="https://goo.gl/maps/xiXRzytE7Sx" target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marker"></i></a>
					</div>
					<div className="footer__column--rightBlock">
						<h3>Sydney - Head Office</h3>
						<p>Suite 206, Level 2, 74 Pitt Street</p>
						<p>Sydney NSW 2000</p>
						<p>Australia</p>
					</div>
				</div>
				<div className="footer__column--subBlock">
					<div className="footer__column--leftBlock">
						<a href="https://goo.gl/maps/9tWT3DkT5H72" target="_blank" rel="noopener noreferrer"><i className="fa fa-map-marker"></i></a>
					</div>
					<div className="footer__column--rightBlock">
						<h3>Melbourne</h3>
						<p>P.O. Box 2108</p>
						<p>Brighton VIC 3186</p>
						<p>Australia</p>
					</div>
				</div>
			</div>

			<div className="footer__column">
				<h3>Trust Matters</h3>
				<p>We are passionate about the service we provide and a very large part of that is
					giving our users the confidence of knowing we do not on-sell, or even share, their private data with anyone.</p>
				<p>We do not sell or host any advertising so our users know when someone is looking at their LifeTimeLine, that is all they will see. No ads.</p>

				<h3>Privacy Policy</h3>
				<p>We are very serious about keeping your private data safe. You can view our full Privacy Policy, Terms and Conditions and Copyright Notice <a href="/privacy-policy">here</a>.</p>
			</div>

			<div className="footer__column">
				<Logo
					className="footer__column--logo"
					brand={brandConfig['lifetimeline'].brand}
					logoContext="default"
					/>
				<p className="margin-bottom-35">LifeTimeLine® is an ad-free, community-centric and permanent online space. It provides a safe and respectful place to create memorials and lasting life stories. It's a way to share your story around the world and across generations.</p>

				<Logo
					className="footer__column--logo"
					brand={brandConfig['inmemoriam'].brand}
					logoContext="default"
					/>
				<p>The In Memoriam logo and LifeTimeLine are Registered Trademarks of In Memoriam Group Pty. Ltd. ACN: 613 859 484 <a href="mailto:corporate@inmemoriam.com">corporate@inmemoriam.com</a></p>
				<p>All content is protected under copyright &copy; 2016 In Memoriam Group Pty Ltd.</p>
				<p>No content appearing on this website may be used without express written permission.</p>
			</div>

		</div>
	</div>
);

export default Footer;