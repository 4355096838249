import React, { Component, Fragment } from 'react';

import Spinner from '../../Spinner';

import * as fileAPI from '../../../api/fileAPI';

import './Image.scss';

const ORIENT_TRANSFORMS = {
	1: '',
	2: 'rotateY(180deg)',
	3: 'rotate(180deg)',
	4: 'rotate(180deg) rotateY(180deg)',
	5: 'rotate(270deg) rotateY(180deg)',
	6: 'rotate(90deg)',
	7: 'rotate(90deg) rotateY(180deg)',
	8: 'rotate(270deg)',
};

class FormImage extends Component {
	static fieldType = 'FormImage';

	constructor(props) {
		super(props);

		this.input = null;
		this.image = null;
		this.thumbnails = false;
		this.component = null;

		this.state = this.getNewState(
			props,
			{
				URL: '',
				width: 0,
				height: 0,
				thumbnailURL: '',
				orientation: 1,
				error: null,
				isVisible: !props.lazyLoading,
				loading: false,
			},
		);
	}

	componentDidMount() {
		if (!this.props.isVisible) {
			this.checkIsVisible();
			window.addEventListener('RESIZE', this.checkIsVisible);
			window.addEventListener('SCROLL_EVENT', this.checkIsVisible);
		}
	}

	componentWillUnmount() {
		if (!this.props.isVisible) {
			this.unbindListener();
		}
	}

	componentWillReceiveProps(nextProps) {
		const hasURIChanged = nextProps.image && this.state.URL !== nextProps.image.uri;

		if (!nextProps.image || hasURIChanged) {
			const newState = this.getNewState(
				nextProps,
				{
					URL: '',
					width: 0,
					height: 0,
					thumbnailURL: '',
					orientation: 1,
					loading: hasURIChanged,
				},
			);

			this.setState(newState);
		}
	}

	unbindListener = () => window.removeEventListener('SCROLL_EVENT', this.checkIsVisible);

	getNewState = (props, defaults) => {
		const state = Object.assign({}, defaults);

		if (props.image) {
			state.URL = props.image.uri;
			state.width = props.image.width;
			state.height = props.image.height;
			state.thumbnailURL = props.image.thumbnailUri;
			if (props.image.exifDataProfile) {
				state.orientation = props.image.exifDataProfile.orientation;
			} else {
				state.orientation = 1;
			}
		}

		return state;
	}

	fixOrientation = (file, image, event) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const view = new DataView(e.target.result);
			if (view.getUint16(0, false) !== 0xFFD8) {
				this.setState({
					orientation: 1,
					URL: image.src,
					width: image.width,
					height: image.height,
				});

				if (this.props.onChange) {
					this.props.onChange(event, image, 1);
				}
				return;
			}

			const length = view.byteLength;
			let offset = 2;
			while (offset < length) {
				const marker = view.getUint16(offset, false);
				offset += 2;
				if (marker === 0xFFE1) {
					offset += 2
					if (view.getUint32(offset, false) !== 0x45786966) {
						this.setState({
							orientation: 1,
							URL: image.src,
							width: image.width,
							height: image.height,
						});

						if (this.props.onChange) {
							this.props.onChange(event, image, 1);
						}

						return;
					}

					const little = view.getUint16(offset += 6, false) === 0x4949;
					offset += view.getUint32(offset + 4, little);
					const tags = view.getUint16(offset, little);
					offset += 2;
					for (let i = 0; i < tags; i++) {
						if (view.getUint16(offset + (i * 12), little) === 0x0112) {
							const orientation = view.getUint16(offset + (i * 12) + 8, little);

							if (this.props.onChange) {
								this.props.onChange(event, image, orientation);
							}

							this.setState({
								orientation,
								URL: image.src,
								width: image.width,
								height: image.height,
							});
							return;
						}
					}
				} else if ((marker & 0xFF00) !== 0xFF00) {
					break;
				} else {
					offset += view.getUint16(offset, false);
				}
			}

			if (this.props.onChange) {
				this.props.onChange(event, image, 1);
			}

			this.setState({
				orientation: 1,
				URL: image.src,
				width: image.width,
				height: image.height,
			});
		};

		reader.readAsArrayBuffer(file);
	};

	checkIsVisible = () => {
		if (!this.component || this.props.isVisible) {
			return;
		}

		const bounding = this.component.getBoundingClientRect();
		if (bounding.top >= -133 && bounding.top <= (window.innerHeight || document.documentElement.clientHeight)) {
			this.setState({ isVisible: true });
			this.unbindListener();
		}
	};

	handleClick = (event) => {
		if (this.input) {
			this.input.click();
		}
	};

	handleImageChange = (event) => {
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		// File size must be 25mb or smaller 25,000,000
		if (file.size > 25000000) {
			if (this.props.onImageUpload) {
				this.props.onImageUpload({
					success: false,
					data: {
						title: 'Image too big!',
						message: 'Please upload an image under 25mb.',
					},
				});
			}
			return;
		}

		const reader = new FileReader();
		const i = new Image();

		reader.onload = (e) => {
			i.src = e.target.result;

			fileAPI.upload('image', file)
				.then(resp => {
					if (this.props.onImageUpload) {
						this.props.onImageUpload(resp);
					}
				});
		};

		i.onload = () => this.fixOrientation(file, i, event);

		reader.readAsDataURL(file);
	};

	renderEditHover = (URL, editable) => {
		if (!editable) {
			return null;
		}

		const hoverClasses = [ 'formImage__hover' ];

		if (!URL) {
			hoverClasses.push('formImage__hover--default');
		}

		return (
			<div
				className={hoverClasses.join(' ')}
				onClick={this.handleClick}
			>
				<i className="formImage__icon fa fa-camera" />
				Upload an Image
				<input
					type="file"
					className="formImage__input"
					ref={ref => this.input = ref}
					onChange={this.handleImageChange}
				/>
			</div>
		);
	}

	renderImage() {
		const {
			alt,
			editable,
			defaultIcon,
			thumbnails,
			forceHeight,
			forceWidth,
			...otherProps
		} = this.props;

		// Clean up other props so they wont be passed to the img element
		delete(otherProps.image);
		delete(otherProps.onImageUpload);
		delete(otherProps.lazyLoading);
		delete(otherProps.thumbnails);

		const {
			width,
			height,
			URL,
			thumbnailURL,
			orientation,
			isVisible,
		} = this.state;

		if (!URL || !isVisible) {
			const placeholderClasses = [ 'formImage', 'formImage--empty' ];
			if (editable) {
				placeholderClasses.push('formImage--editable');
			}

			const iconClasses = [ 'fa' ];
			if (defaultIcon) {
				iconClasses.push(defaultIcon);
			} else {
				iconClasses.push('fa-picture-o');
			}

			return (
				<div className={placeholderClasses.join(' ')}>
					<i className={iconClasses.join(' ')} />
				</div>
			);
		}

		const classes = [ 'formImage' ];

		// standard 4 x 3 is 0.75 in this context. h/w was chosen over w/h as it provides a clean number instead of
		// 1.333333333* but, really, it's not important.
		// THUMBNAILS; We can ignore thumbnail width and height as calcs here will be same as thumbnails are created in same ratio.
		const imgRatio = height / width;

		if (imgRatio < 0.75) {
			classes.push('formImage--push-to-height');
		} else {
			classes.push('formImage--push-to-width');
		}

		const isPortrait = (orientation >= 5 && height < width) || (orientation <= 4 && height > width);

		if (isPortrait) {
			classes.push('formImage--portrait');
		}

		if (this.state.loading) {
			classes.push('formImage--loading');
		}

		let styles = { transform: ORIENT_TRANSFORMS[orientation] };

		if (orientation >= 5) {
			classes.push('formImage--rotated');
		}

		if (forceHeight) {
			styles = {
				height: forceHeight,
				...styles,
			 }
		}
		if (forceWidth) {
			styles = {
				width: forceWidth,
				...styles,
			}
		}

		return (
			<Fragment>
				{this.state.loading && <div className="formImage__spinner"><Spinner active={true} /></div>}
				<img
					{...otherProps}
					className={classes.join(' ')}
					style={styles}
					src={(thumbnails && thumbnailURL) ? thumbnailURL : URL}
					alt={alt}
					ref={ref => this.image = ref}
					onLoad={() => this.setState({ loading: false })}
				/>
			</Fragment>
		);
	}

	validate = () => {
		if (this.props.required && !this.state.URL) {
			const error = 'Image is required.';
			this.setState({ error });
			return error;
		}

		return null;
	};

	renderError = (error) => {
		if (error === null) {
			return null;
		}

		return (
			<div className="formImage__error-wrap">
				<div className="formImage__error">
					{error}
				</div>
			</div>
		);
	}

	render() {
		const {
			className,
			editable,
			forceWidth,
			forceHeight,
		} = this.props;

		const {
			URL,
			error,
		} = this.state;

		const wrapperClasses = [ 'formImage__wrapper' ];

		if (className) {
			wrapperClasses.push(className);
		}

		let styles = {};

		if (forceHeight) {
			styles = {
				height: forceHeight,
			 };
		}
		if (forceWidth) {
			styles = {
				width: forceWidth,
				...styles,
			};
		}

		return (
			<div className={wrapperClasses.join(' ')} ref={ref => this.component = ref} style={styles}>
				{this.renderEditHover(URL, editable)}
				{this.renderError(error)}
				{this.renderImage()}
			</div>
		);
	}
}

export default FormImage;
