import React, { Component } from 'react';

import Swiper from '../Swiper';
import SharePanel from '../SharePanel';

import './FullScreenMoment.scss';

class FullScreenMoment extends Component {
	state = {
		isDescriptionOpen: false,
	};

	toggleDescription = () => {
		if (this.props.editMode) {
			return;
		}

		this.setState({ isDescriptionOpen: !this.state.isDescriptionOpen });
	}

	renderControlls = (lifeTimeLineID, momentID, title, editMode, editAllowed, handleDelete) => {
		if (editMode) {
			return null;
		}

		const controllsClasses = [ 'full-screen-moment__controls' ];

		if (!this.props.isControlsOpen) {
			controllsClasses.push('full-screen-moment__controls--closed');
		}

		// TODO: Like, Comments, Settings
		return (
			<div className={controllsClasses.join(' ')}>
				<div className="full-screen-moment__control-item">
					<SharePanel
						className="full-screen-moment__share"
						lifeTimeLineID={lifeTimeLineID}
						momentID={momentID}
						title={title}
						position="bottom"
					/>
				</div>
				<div className="full-screen-moment__control-item">
					<i className="fa fa-newspaper-o" onClick={this.toggleDescription} />
				</div>
				{editAllowed && <div className="full-screen-moment__control-item">
					<i className="fa fa-pencil" onClick={this.props.handleEditSwitch} />
				</div>}
				{editAllowed && <div className="full-screen-moment__control-item">
					<i className="fa fa-trash" onClick={handleDelete} />
				</div>}
			</div>
		);
	};

	render() {
		const {
			moment,
			title,
			editMode,
			editAllowed,
			isControlsOpen,
			handleScrollLeft,
			handleScrollRight,
			handleToggleControlls,
			handleDelete,
		} = this.props;

		const image = moment.image;

		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		const imageFullWidth = (image && image.width) ? image.width : 600;
		const imageFullHeight = (image && image.height) ? image.height : 450;

		const imageWidthScale = windowWidth / imageFullWidth;
		const imageHeightScale = windowHeight / imageFullHeight;

		const rawScaleFactor = imageWidthScale >= imageHeightScale ? imageHeightScale : imageWidthScale;

		const scaleFactor = rawScaleFactor >= 1 ? 1 : rawScaleFactor;

		const fullMomentDisplayW = Math.floor(imageFullWidth * scaleFactor);
		const fullMomentDisplayH = Math.floor(imageFullHeight * scaleFactor);

		const fullScreenMomentClasses = [ 'full-screen-moment' ];
		if (editMode) {
			fullScreenMomentClasses.push('full-screen-moment--edit');
		}

		const descriptionClasses = [ 'full-screen-moment__description' ];
		if ((!this.state.isDescriptionOpen && !editMode) || !isControlsOpen) {
			descriptionClasses.push('full-screen-moment__description--closed')
		}
		if (editMode) {
			descriptionClasses.push('full-screen-moment__description--edit');
		}

		return (
			<div className={fullScreenMomentClasses.join(' ')} onClick={e => e.stopPropagation()}>
				{!this.props.editMode && <Swiper
					onSwipeLeft={handleScrollLeft}
					onSwipeRight={handleScrollRight}
					onTap={handleToggleControlls}
				/>}
				{this.props.renderImage(image, fullMomentDisplayW, fullMomentDisplayH)}
				<div className={descriptionClasses.join(' ')}>
					{this.props.renderMomentDescription('portrait', false, !editMode)}
				</div>
				{this.renderControlls(
					moment.timelineId,
					moment.momentId,
					title,
					editMode,
					editAllowed,
					handleDelete,
				)}
			</div>
		);
	}
}

export default FullScreenMoment;
