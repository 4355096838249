import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { AuthRoute } from '../../utils/routing';

import LifeTimeLine from './LifeTimeLine';

const LifeTimeLineRouter = ({ isSignedIn }) => (
	<Switch>
		<Route
			exact
			path="/:id"
			component={LifeTimeLine}
		/>
		<Route
			exact
			path="/:id/m/:momentID"
			component={LifeTimeLine}
		/>
		<AuthRoute
			exact
			path="/:author/:id/m/:momentID/e/:edit"
			component={LifeTimeLine}
			signInRequired={true}
			isSignedIn={isSignedIn}
		/>
		<Route
			exact
			path="/:author/:id"
			component={LifeTimeLine}
		/>
		<Route
			exact
			path="/:author/:id/m/:momentID"
			component={LifeTimeLine}
		/>
	</Switch>
);

export default LifeTimeLineRouter;