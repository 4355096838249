import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import FocusBox from '../../components/FocusBox/FocusBox';
import TextInput from '../../components/FocusBox/Inputs/TextInput';
import InputGroup from '../../components/FocusBox/Inputs/InputGroup';
import Button from '../../components/Form/Button/Button';

import signIn from './user_utils';
import * as personAPI from '../../api/personAPI';

import { dispatchError } from '../../utils/general';

class ResetPasswordAction extends Component {
	state = {
		email: '',
		password: '',
		confirmPassword: '',
		errorMessage: '',
		loading: false,
	};

	componentDidMount() {
		if (this.props.profile.isSignedIn) {
			this.props.history.push('/lifetimelines');
		}
	}

	onInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		let errorMessage = '';
		if (name === 'confirmPassword' && value !== this.state.password) {
			errorMessage = 'Confirm password does not match';
		}

		this.setState({
			[name]: value,
			errorMessage,
		});
	};

	handleResetPasswordAction = () => {
		this.setState({ loading: true });

		personAPI.actionPasswordReset(this.props.match.params.id, this.state.password)
			.then(this.onSuccess)
			.catch(this.onError);
	};

	onSuccess = (payload) => {
		this.setState({ loading: false });

		if (payload.success) {
			signIn(
				payload.data.primaryEmailAddress,
				this.state.password,
				this.onSignInSuccess,
				this.onSignInError,
				this.props.onSetProfile,
			);
		}
	};

	onError = (errorMessage) => {
		dispatchError(errorMessage);
		this.setState({
			errorMessage,
			loading: false,
		});
	};

	onSignInSuccess = (data) => {
		this.setState({ loading: false });

		this.props.onSignIn(data);
	};

	onSignInError = (errorMessage) => {
		this.setState({
			errorMessage,
			loading: false,
		});

		this.props.onSignOut();
	};

	onHandleEnter = (event) => {
		if (event.key === 'Enter') {
			this.handleResetPasswordAction();
		}
	};

	renderErrorMessage = (errorMessage) => {
		if (!errorMessage) {
			return null;
		}

		return <div className="focusBox__errorMessage">{errorMessage}</div>;
	};

	render() {
		const errorMessage = this.state.errorMessage;

		const spinnerClass = [ 'focusBox__spinner' ];
		if (this.state.loading) {
			spinnerClass.push('focusBox__spinner--active')
		}

		return (
			<FocusBox brand={this.props.audience.brand}>
				<div>
					<InputGroup>
						<TextInput
							type="password"
							name="password"
							placeholder="Password"
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							icon="fa-key"
							forceInvalid={errorMessage.length > 0}
						/>
						<TextInput
							type="password"
							name="confirmPassword"
							placeholder="Confirm Password"
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							icon="fa-check-circle"
							forceInvalid={errorMessage.length > 0}
						/>
					</InputGroup>

					{this.renderErrorMessage(errorMessage)}

					<Button
						className="focusBox__button"
						color="blue"
						loading={this.state.loading}
						onClick={this.handleResetPasswordAction}
					>
						Save New Password
					</Button>

					<Link to="/sign-in" className="bottom-link">
						Cancel and Return to Sign In
					</Link>
				</div>
				<div className="focusBox__description">
					<div className="focusBox__title">Set Password</div>
					<div className="focusBox__text">
						Enter a new password and retytpe it to confirm.
						<br /><br />
						An email will be sent containing a link allowing you to reset your password.
						<br /><br />
						Then click 'Save New Password' to complete the process.
					</div>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSignIn: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
		onSignOut: () => {
			dispatch({ type: 'SIGN_OUT' });
		},
		onSetProfile: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ResetPasswordAction);
