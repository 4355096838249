import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as personAPI from '../../api/personAPI';
import * as cartAPI from '../../api/cartAPI';

import * as validation from '../../components/Form/Validation.js';

import FocusBox from '../../components/FocusBox/FocusBox';
import TextInput from '../../components/FocusBox/Inputs/TextInput';
import InputGroup from '../../components/FocusBox/Inputs/InputGroup';
import Button from '../../components/Form/Button/Button';

import { dispatchError } from '../../utils/general';
import * as defer from '../../utils/defer';

import signIn from './user_utils';

class Register extends Component {
	constructor(props) {
		super(props);

		this.emailInput = null;
		this.passwordInput = null;

		this.state = {
			primaryEmailAddress: '',
			password: '',
			firstName: '',
			lastName: '',
			loading: false,
			errorMessage: '',
			validationMessages: {},
		};
	}

	componentDidMount() {
		if (this.props.profile.isSignedIn) {
			this.props.history.push('/lifetimelines');
		}
	}

	getValidationMessages = (name) => {
		return this.state.validationMessages[name] || [];
	};

	onInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		this.setState({
			[name]: value,
			errorMessage: '',
		});

		if (document.activeElement.name === name) {
			if (name === 'primaryEmailAddress' && this.getValidationMessages('PrimaryEmailAddress').length) {
				this.validateEmail(value);
			} else if (name === 'password' && this.getValidationMessages('Password').length) {
				this.validatePassword(value);
			}
		}
	};

	handleRegister = () => {
		if (!this.validateForm()) {
			return;
		}

		this.setState({ loading: true });

		const {
			primaryEmailAddress,
			password,
			firstName,
			lastName,
		} = this.state;

		personAPI.register(primaryEmailAddress, password, firstName, lastName)
			.then(resp => {
				if (resp.success) {
					signIn(
						primaryEmailAddress,
						password,
						this.onSignInSuccess,
						this.onSignInError,
					);
				} else {
					if (Object.keys(resp.data).length > 0) {
						this.setState({ validationMessages: resp.data });
					} else {
						// TASK: Handle 400 errors with no data better
						throw new Error();
					}
				}

				this.setState({ loading: false });
			})
			.catch(err => {
				dispatchError(err);
				this.setState({
					errorMessage: 'Something went wrong. Please try again.',
					loading: false,
				});
			});
	}

	onSignInSuccess = (data) => {
		this.setState({ loading: false });

		this.props.onSetProfile(data);

		cartAPI.getByPartnerID(this.props.audience.partnerID)
			.then(resp => {
				if (!resp.success) {
					return;
				}

				const cartItem = defer.get('ADD_PRODUCT_TO_CART_ON_SIGN_IN');
				if (cartItem.length > 0) {
					defer.clear('ADD_PRODUCT_TO_CART_ON_SIGN_IN');

					const productId = cartItem[0];

					if (productId === 'new') {
						this.props.history.push('/author/new');
						return;
					}

					cartAPI.addCartItem({
						cartId: resp.data.cartId,
						productId: productId,
						quantity: 1,
					})
					.then(resp => {
						if (resp.success) {
							this.props.addCartItem(resp.data);
							this.props.history.push('/cart');
						}
					});
				} else {
					this.props.history.push('/welcome');
				}
			});
	};

	onSignInError = (err) => {
		this.setState({ loading: false });

		this.props.onSignOut();

		// If we're not able to sign in automatically, then redirect to the sign in screen
		this.props.history.push('/sign-in');
	};

	validateForm = () => {
		const {
			primaryEmailAddress,
			password,
		} = this.state;

		const emailMessages = validation.email(primaryEmailAddress);
		const isEmailValid = emailMessages.length === 0;

		const passwordMessages = validation.password(password);
		const isPasswordValid = passwordMessages.length === 0;

		this.setState({
			validationMessages: {
				PrimaryEmailAddress: emailMessages,
				Password: passwordMessages,
			},
		});

		if (!isEmailValid) {
			this.emailInput.focus();
		} else if (!isPasswordValid) {
			this.passwordInput.focus();
		}

		return isEmailValid && isPasswordValid;
	}

	validateEmail = (email) => {
		const emailMessages = validation.email(email);

		this.setState({
			validationMessages: {
				...this.state.validationMessages,
				PrimaryEmailAddress: emailMessages,
			},
		});
	}

	validatePassword = (password) => {
		const passwordMessages = validation.password(password);

		this.setState({
			validationMessages: {
				...this.state.validationMessages,
				Password: passwordMessages,
			},
		});
	}

	onHandleEnter = (event) => {
		if (event.key === 'Enter') {
			this.handleRegister();
		}
	};

	renderErrorMessage = (errorMessage) => {
		if (!errorMessage) {
			return null;
		}

		return <div className="focusBox__errorMessage">{errorMessage}</div>
	};

	render() {
		const spinnerClass = [ 'focusBox__spinner' ];
		if (this.state.loading) {
			spinnerClass.push('focusBox__spinner--active')
		}

		const errorMessage = this.state.errorMessage;

		const emailMessages = this.getValidationMessages('PrimaryEmailAddress');
		const passwordMessages = this.getValidationMessages('Password');

		return (
			<FocusBox brand={this.props.audience.brand}>
				<div>
					<InputGroup>
						<TextInput
							type="email"
							name="primaryEmailAddress"
							placeholder="Email (required)"
							icon="fa-envelope-o"
							autoFocus={true}
							ref={input => this.emailInput = input}
							onChange={this.onInputChange}
							onBlur={e => this.validateEmail(e.target.value)}
							onKeyPress={this.onHandleEnter}
							validationMessages={emailMessages}
							forceShowErrors={emailMessages.length > 0 && passwordMessages.length === 0}
							forceInvalid={errorMessage.length > 0}
						/>
						<TextInput
							type="password"
							name="password"
							placeholder="Password (required)"
							icon="fa-lock"
							ref={input => this.passwordInput = input}
							onChange={this.onInputChange}
							onBlur={e => this.validatePassword(e.target.value)}
							onKeyPress={this.onHandleEnter}
							validationMessages={passwordMessages}
							forceShowErrors={passwordMessages.length > 0 && emailMessages.length === 0}
							forceInvalid={errorMessage.length > 0}
						/>
						<TextInput
							type="text"
							name="firstName"
							placeholder="First Name"
							icon="fa-user-secret"
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							forceInvalid={errorMessage.length > 0}
						/>
						<TextInput
							type="text"
							name="lastName"
							placeholder="Last Name"
							icon="fa-id-badge"
							onChange={this.onInputChange}
							onKeyPress={this.onHandleEnter}
							forceInvalid={errorMessage.length > 0}
						/>
					</InputGroup>

					{this.renderErrorMessage(errorMessage)}

					<Button
						className="focusBox__button"
						color="blue"
						onClick={this.handleRegister}
						loading={this.state.loading}
					>
						Register
					</Button>

					<Link to="/privacy-policy" className="bottom-link">
					Use of this system indicates you<br/>agree to our <u>Privacy Policy</u>.
					</Link>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
		addCartItem: (data) => {
			dispatch({
				type: 'ADD_CART_ITEM',
				data,
			});
		},
		onSignOut: () => {
			dispatch({ type: 'SIGN_OUT' });
		},
		onSetProfile: (data) => {
			dispatch({
				type: 'SET_PROFILE',
				data,
			});
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Register);