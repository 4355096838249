import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as util from '../../utils/general';

import Logo from '../Brand/Logo';

import './UserMenu.scss';

class UserMenu extends Component {
	render () {
		const {
			userMenuActive,
			onSignOut,
			onCloseHandler,
			audience,
			global,
		} = this.props;

		if (!userMenuActive) {
			return null;
		}

		const isResponsive = global.breakpoint.level <= util.breakpoints['tablet'];

		return (
			<div className="user_menu__canvas" onClick={onCloseHandler}>
				<div className="user_menu">
					{isResponsive &&
						<Link className="menu-item menu-item--responsive" to="/cart">
							<i className="menu-item__icon fa fa-shopping-cart"></i>
							<span className="menu-item__label">Shopping Cart</span>
						</Link>
					}
					<Link className="menu-item" to="/account/details">
						<i className="menu-item__icon fa fa-user-o"></i>
						<span className="menu-item__label">Account</span>
					</Link>
					<Link className="menu-item" to="/lifetimelines">
						<Logo brand={audience.brand} logoContext="userMenu"/>
						<span className="menu-item__label">LifeTimeLines</span>
					</Link>
					{isResponsive &&
						<Link className="menu-item menu-item--responsive" to="/search">
							<i className="menu-item__icon fa fa-search"></i>
							<span className="menu-item__label">Search</span>
						</Link>
					}
					<Link className="menu-item" to="/sign-in" onClick={onSignOut}>
						<i className="menu-item__icon fa fa-sign-out"></i>
						<span className="menu-item__label">Sign Out</span>
					</Link>
					<Link className="menu-item" to="/help">
						<i className="menu-item__icon fa fa-life-ring"></i>
						<span className="menu-item__label">Help</span>
					</Link>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		global: state.global,
	};
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSignOut: () => {
			dispatch({ type: 'SIGN_OUT' });
		}
	}
}


export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserMenu);
