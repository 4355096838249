import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image from '../Form/Image';

import { dateText } from './util.js';

import './SmallMoment.scss';



class SmallMoment extends Component {

	shortDescription = null;

	constructor(props) {
		super(props);

		this.state = {
			shortDescriptionClipped: false,
		};
	}

	componentDidMount() {
		this.setState({shortDescriptionClipped: this.shortDescription.clientHeight > 32 })

	}

	renderClippedLink(author, momentID) {
		if (this.state.shortDescriptionClipped) {
			return (
				<Link to={`${this.props.buildURL(author, momentID)}`}>
					<div className="moment__shortDescription--clippedLink">more...</div>
				</Link>
			);
		} else {
			return null;
		}
	}

	render() {
		const {
			momentID,
			date,
			approximate,
			year,
			month,
			day,
			image,
			shortDescription,
			editAllowed,
			onDeleteMoment,
			author,
		} = this.props;

		return (
			<div className="moment">
				<Link to={this.props.buildURL(author, momentID)} className="moment__link">
					<div className="moment__image">
						<Image
							image={image}
							alt="Moment"
							thumbnails={true}
							lazyLoading={true}
							/>
					</div>
				</Link>
				<div className="moment__card">
					<div className="moment__data">
					<div className="moment__shortDescription">
						<div className="moment__shortDescription--full" ref={(ref) => {this.shortDescription = ref}} >
							{this.renderClippedLink(author, momentID)}
							<span className="moment__datetime">{dateText(date, approximate, year, month, day)}</span>
							{approximate ? <span className="moment__datetime--circa"><i className="fa fa-random" title="This date is approximate."></i></span> : ''}
							{shortDescription}
						</div>
						</div>
					</div>
					<div className="moment__footer">
						<div className="moment__metaData">
							{editAllowed &&
								<Link
									className="small-moment__edit"
									to={`${this.props.buildURL(author, momentID)}/e/1`}
								>
									<i className="fa fa-pencil" title="Edit"></i>
								</Link>
							}
							{/* {editAllowed &&
								<Link
									className="small-moment__settings"
									to={`${this.props.buildURL(author, momentID)}/e/1`}
								>
									<i className="fa fa-cog" title="Settings"></i>
								</Link>
							} */}
							{editAllowed && // See console log via LifeTimeLione.js
								<Link
									className="small-moment__delete"
									to={this.props.buildURL(author, momentID)}
									onClick={(e) => {
										e.preventDefault();
										onDeleteMoment(momentID)
									}}
								>
									<i className="fa fa-trash " title="Delete"></i>
								</Link>
							}

							{/* <i className="fa fa-heart-o"></i> */}
						</div>
					</div>
				</div>
			</div>
		);
	};
}

export default SmallMoment;