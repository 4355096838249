	import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';

import * as util from '../../utils/general';
import { dateText } from './util.js';
import Input from '../Form/Input';
import Image from '../Form/Image';
import Button from '../Form/Button';
import Form from '../Form/Form';
import FullScreenMoment from './FullScreenMoment';
import SharePanel from '../SharePanel';

import * as momentAPI from '../../api/momentAPI';

import './FullMoment.scss';

class FullMoment extends Component {
	baseURL = null;

	constructor(props) {
		super(props);

		this.state = {
			moment: props.moment || {},
			changesMade: false,
			editAllowed: props.editAllowed,
			editMode: props.editMode || props.moment.momentId === 'new',
			saving: false,
			loadingImage: false,
			commentsPanelActive: false,
			isFullScreen: props.global.breakpoint.level <= util.breakpoints['mobile-landscape'],
			isControlsOpen: true,
		};
	}

	componentWillReceiveProps(nextProps) {
		const state = {};

		if (this.state.moment.momentId !== nextProps.moment.momentId) {
			state.moment = nextProps.moment;
		}

		if (this.props.global.breakpoint.level !== nextProps.global.breakpoint.level) {
			state.isFullScreen = nextProps.global.breakpoint.level <= util.breakpoints['mobile-landscape'];
		}

		if (Object.keys(state).length > 0) {
			this.setState(state);
		}
	}

	checkDiscardChanges = () => {
		// if   No changes made   OR   changes made AND discard confirmed
		return !this.state.changesMade || (this.state.changesMade && window.confirm('You have unsaved changes. Do you want to leave anyway?'));
	};

	onClickBackdrop = () => {
		const discardChanges = this.checkDiscardChanges();
		if (discardChanges) {
			this.props.redirect(this.props.author);
		}
	};

	handleEditSwitch = () => {
		const discardChanges = this.state.editMode && this.checkDiscardChanges();

		if (discardChanges) {
			this.setState({
				moment: this.props.moment || {},
				changesMade: false,
				editAllowed: this.props.editAllowed,
				editMode: false,
			});

			if (this.props.moment.momentId === "new") {
				this.props.redirect(this.props.author);
			} else {
				this.props.redirect(this.props.author, this.props.moment.momentId);
			}
		} else {
			this.setState({ editMode: true });
		}
	};

	saveMoment = () => {
		this.setState({
			saving: true,
		});

		if (this.state.loadingImage) {
			return;
		}

		const payload = {
			...this.state.moment,
			image: this.state.moment.image && {
				persistedFileId: this.state.moment.image.persistedFileId,
			},
			timelineID: parseInt(this.props.lifeTimeLineID, 10) || 0,
			year: parseInt(this.state.moment.year, 10) || 0,
			month: parseInt(this.state.moment.month, 10) || 0,
			day: parseInt(this.state.moment.day, 10) || 0,
		};

		if (this.state.moment.momentId !== 'new') {
			momentAPI.update(payload)
				.then(resp => {
					if (resp.success) {
						// TASK: Animate success?
						this.props.onSetMoment(resp.data);
						this.setState({
							editMode: false,
							changesMade: false,
							saving: false,
						});
						this.props.redirect(this.props.author, this.props.moment.momentId);
					} else {
						// TASK: Handle error
						this.setState({ saving: false });
						this.props.onSetMessage({
							title: 			'Ooops!',
							message: 		'Something went wrong, please try again later.',
						});
					}
				});
		} else {
		 	// Delete the 'new' momentId
		 	delete(payload.momentId);

			momentAPI.create(payload)
				.then(resp => {
					if (resp.success) {
						// TASK: Animate success?
						this.props.onSetMoment(resp.data);
						this.props.redirect(this.props.author);
					} else {
						// TASK: Handle error
					}
				});
		}
	};

	imageMetaData = (event, image, orientation) => {
		this.setState({
			loadingImage: true,
			moment: {
				...this.state.moment,
				image: {
					...this.state.moment.image,
					persistedFileId: 0,
					// Setting URI to be the image base64 so that the image is displayed on the screen while it is uploading,
					// once the upload is complete it will be set to a valid uri
					uri: image.src,
					width: image.width,
					height: image.height,
					exifDataProfile: image.exifDataProfile,
				},
			},
			changesMade: true,
		});
	};

	handleImageUploaded = (resp) => {
		if (resp.success) {
			this.setState({
				loadingImage: false,
				moment: {
					...this.state.moment,
					image: resp.data,
				},
			});
		} else {
			this.props.onSetMessage(resp.data);
		}

		// TASK: Consider what should happen if the user is trying to save but the image upload failed
		if (this.state.saving) {
			window.setTimeout(() => this.saveMoment(), 0);
		}
	};

	handleInputChange = (event) => {
		const {
			name,
			value,
			type,
			checked,
		} = event.target;

		// TASK: Add radio buttons and maybe improve checkbox implementation (basic and array)
		this.setState({
			moment: {
				...this.state.moment,
				[name]: type === 'checkbox' ? checked : value,
			},
			changesMade: true,
		});
	};

	handleDelete = (e) => {
		e.preventDefault();

		this.props.onDeleteMoment(this.props.moment.momentId);
	}

	// renderEditControls = (location, panelAspect, smallView) => {
	// 	if (!this.state.editAllowed || !this.state.editMode) {
	// 		return null;
	// 	} else {
	// 		return (
	// 			<div className="full-moment__section full-moment__section--controls">
	// 				<div className="button-box full-moment__form-controls">
	// 					<Button
	// 						className={`full-moment__button ${smallView && ' full-moment__button--smallView'}`}
	// 						onClick={this.handleEditSwitch}
	// 					>
	// 						{smallView ? <i className="fa fa-times-circle"></i> : 'Cancel'}
	// 					</Button>
	// 					<Button
	// 						className={`full-moment__button ${smallView && ' full-moment__button--smallView full-moment__button--blue'}`}
	// 						color="blue"
	// 						onClick={this.saveMoment}
	// 						disabled={!this.state.changesMade}
	// 						loading={this.state.saving}
	// 					>
	// 						{smallView ? <i className="fa fa-check-circle"></i> : 'Save'}
	// 					</Button>
	// 				</div>
	// 			</div>
	// 		);
	// 	}
	// };

	toggleCommentsPanel = () => {
		alert( this.state.commentsPanelActive ? 'COMING SOON\nComments\n\nc' :'COMING SOON\nComments\n\no' );
		this.setState({commentsPanelActive: !this.state.commentsPanelActive });
	}


	renderIcons = (narrowLandscape, panelAspect) => {
		if (this.state.editMode) {
			return (
				<div className="full-moment__icons">
					<Button
						className={`full-moment__button ${panelAspect === 'landscape' && 'full-moment__button--small'}`}
						onClick={this.handleEditSwitch}
					>
						Cancel
					</Button>
					<Button
						className={`full-moment__button ${panelAspect === 'landscape' && 'full-moment__button--small'}`}
						color="blue"
						isSubmit={true}
						onClick={this.saveMoment}
						disabled={!this.state.changesMade}
						loading={this.state.saving}
					>
						Save
					</Button>
				</div>
			)
		}

		// const momentID = this.props.moment.momentId;
		// const {
		// 	lifeTimeLineID,
		// } = this.props;

		return (
			<div className="full-moment__icons">
				{/* <i className="fa fa-heart-o" onClick={(e) => {alert('COMING SOON\nLike Moments.')}} ></i>
				<i className="fa fa-share-alt" onClick={(e) => {alert('COMING SOON\nSharing Moments across your social media.')}} ></i>

				{narrowLandscape && // In narrowLandscape the comments section is a popup
					<i className="fa fa-comment" onClick={this.toggleCommentsPanel}></i>
				} */}
				{this.renderAuthorIcons()}
			</div>
		);

	};

	renderAuthorIcons = () => {
		//console.log(this.props.global.breakpoint.label);

		// if (this.props.global.breakpoint.level <= util.breakpoints['mobile-l']) {
		// 	return (
		// 		<div className="full-moment__icons--author">
		// 			<i className="fa fa-bars" onClick={(e) => {alert('COMING SOON\nLike Moments.')}} ></i>
		// 		</div>
		// 	);

		// } else {
			return (
				<div className="full-moment__icons--author">
					<SharePanel
						className="full-moment__share"
						lifeTimeLineID={this.props.lifeTimeLineID}
						momentID={this.props.moment.momentId}
						title={this.props.lifeTimeLineTitle}
					/>
					{this.state.editAllowed && // See console log via LifeTimeLine.js
						<i className="fa fa-trash full-moment__delete" title="Delete" onClick={this.handleDelete}></i>
					}

					{/* {this.state.editAllowed &&
						<i className="fa fa-cog" title="Settings" onClick={(e) => {alert('COMING SOON\nSettings for Privacy and Publish state etc.')}}></i>
					}

					{this.state.editAllowed &&
						<i className="fa fa-map-marker" title="Location" onClick={(e) => {alert('COMING SOON\nSet a Location for a Moment.')}}></i>
					} */}

					{this.state.editAllowed &&
						<i className="fa fa-pencil" title="Edit" onClick={this.handleEditSwitch}></i>
					}
				</div>
			);
		//}
	}

	renderPrivacyControls = () => {
		if (!this.state.editMode) {
			return null;
		}

		return (
			<div className="input-wrapper full-moment__form-field full-moment__privacy">
				<label className="input__label">
					Privacy
				</label>
				<div className="full-moment__privacy--buttons">
					<div className="global__button full-moment__privacy-button" >
						<i className="fa fa-check-circle"></i>
						Same as LifeTimeLine
					</div>
					<div className="global__button full-moment__privacy-button--go" >
						<i className="fa fa-cog"></i>
						Edit
					</div>
				</div>
			</div>
		);
	};

	renderDateSection = (date, approximate, year, month, day, panelAspect) => {
		if (!this.state.editMode) {
			return (
				<div className="section__datetimeAndCirca">
					<div className="full-moment__section--datetime">
						{dateText(date, approximate, year, month, day)}
					</div>
					{approximate &&
						<span className="full-moment__datetime--circa">
							<i className="fa fa-random" title="This date is approximate."></i>
						</span>
					}
				</div>
			);
		}
		return (
			<div className={`full-moment__section full-moment__section--date-parts date-parts__${panelAspect}`}>
				<div className="full-moment__section--datetimePartsInputs">
					<Input
						type="text"
						name="year"
						className="full-moment__form-field full-moment__year"
						placeholder="YYYY"
						label="Year"
						onBlur={this.handleBlur}
						onKeyUp={this.handleKeyUp}
						value={year || ''}
						editable={this.props.editAllowed}
						onClick={this.handleClick}
						onKeyPress={this.handleKeyPress}
						editMode={this.state.editMode}
						onChange={this.handleInputChange}
						autoFocus={true}
						required
					/>
					<Input
						type="text"
						name="month"
						className="full-moment__form-field full-moment__month"
						placeholder="MM"
						label="Month"
						onBlur={this.handleBlur}
						onKeyUp={this.handleKeyUp}
						value={month || ''}
						editable={this.props.editAllowed}
						onClick={this.handleClick}
						onKeyPress={this.handleKeyPress}
						editMode={this.state.editMode}
						onChange={this.handleInputChange}
					/>
					<Input
						type="text"
						name="day"
						className="full-moment__form-field full-moment__day"
						placeholder="DD"
						label="Day"
						onBlur={this.handleBlur}
						onKeyUp={this.handleKeyUp}
						value={day || ''}
						editable={this.props.editAllowed}
						onClick={this.handleClick}
						onKeyPress={this.handleKeyPress}
						editMode={this.state.editMode}
						onChange={this.handleInputChange}
					/>
				</div>
				<div className="full-moment__section--datetimeApproximateInput">
					<Input
						type="checkbox"
						name="approximate"
						label="Approximate"
						className="full-moment__form-field full-moment__approximate"
						id="fullMomentApproximate"
						onBlur={this.handleBlur}
						onKeyUp={this.handleKeyUp}
						editable={this.props.editAllowed}
						onKeyPress={this.handleKeyPress}
						editMode={this.state.editMode}
						onChange={this.handleInputChange}
						checked={this.state.moment.approximate}
					/>
				</div>
			</div>
		);
	};

	renderFullMomentFeed = () => {
		if (this.state.editMode) {
			return null;
		} else {
			return (
				<div className="full-moment__feed">
					Comments etc. coming soon.
				</div>
			);
		}
	};

	renderScroll = (direction, editMode, isControlsOpen) => {
		if (editMode || this.props.moments.length < 2) {
			return null;
		}

		const arrowClasses = [ 'rounded-arrow' ];
		const chevronClasses = [ 'rounded-arrow__chevron', 'fa' ]

		if (!isControlsOpen) {
			arrowClasses.push('rounded-arrow--closed');
		}

		if (direction === 'right') {
			arrowClasses.push('rounded-arrow--right');
			chevronClasses.push('fa-chevron-right');
		} else {
			arrowClasses.push('rounded-arrow--left');
			chevronClasses.push('fa-chevron-left');
		}

		return (
			<div
				className={arrowClasses.join(' ')}
				onClick={(e) => this.scrollMoment(e, direction)}
			>
				<div className="rounded-arrow__circle">
					<i className={chevronClasses.join(' ')}></i>
				</div>
			</div>
		);
	};

	renderControls = (editMode, isControlsOpen) => {
		if (editMode) {
			return null;
		}

		const controllClassses = [ 'full-moment-controls' ];
		if (!isControlsOpen) {
			controllClassses.push('full-moment-controls--closed');
		}

		const zoomClasses = [ 'fa' ];
		let zoomTitle = 'Detail View';
		if (this.state.isFullScreen) {
			zoomClasses.push('fa-compress');
		} else {
			zoomClasses.push('fa-expand');
			zoomTitle = 'Full Screen';
		}

		const isMobile = this.props.global.breakpoint.level <= util.breakpoints['mobile-landscape'];

		return (
			<div className={controllClassses.join(' ')}>
				{!isMobile && <div
					className="full-moment-controls__zoom"
					onClick={this.ontToggleZoom}
					title={zoomTitle}
				>
					<i className={zoomClasses.join(' ')}></i>
				</div>}
				<div
					className="full-moment-controls__close"
					onClick={this.onClickBackdrop}
					title="Close"
				>
					<i className="fa fa-times"></i>
				</div>
			</div>
		);
	};

	ontToggleZoom = (e) => {
		e.stopPropagation();

		this.setState({ isFullScreen: !this.state.isFullScreen });
	};

	scrollMoment(e, direction) {
		if (e) {
			e.stopPropagation();
		}

		const index = this.props.moments.findIndex(
			moment => moment.momentId === this.props.moment.momentId
		);

		let newIndex = index + (direction === 'right' ? 1 : -1);

		if (newIndex === this.props.moments.length) {
			// Wrap right
			newIndex = 0;
		} else if (newIndex === -1) {
			// Wrap left
			newIndex = this.props.moments.length - 1;
		}

		const moment = this.props.moments[newIndex];

		if (moment) {					// Not in edit mode   or  in edit mode        and  discard changes true
			const noUnDiscardedChanges = !this.state.editMode || (this.state.editMode && this.checkDiscardChanges());
			if (noUnDiscardedChanges) {
				this.setState({
					moment: this.props.moment || {},
					changesMade: false,
					editAllowed: this.props.editAllowed,
					editMode: false,
				});
				this.props.redirect(this.props.author, moment.momentId);
			}
		}
	};

	renderMoment = () => {
		const {
			image,
		} = this.state.moment;


		/* Work out Modal Size
			Get the image Full Dimensiions
			Look at the pixes available in the window (after scaling to desired max - e.g. 80% of window width)
			Figure out (for each dimensions W & H) what scale to apply to image to fit into available window pixels
			See which scale was more reductive (which dimension needed to be scaled down the most)
			Use the chose scale on both dimensions
			Apply scale to various modal divs
		*/
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;


		// IMPORTANT - the check below is to marry to @media ($tablet) for .full-moment selector in css (in short it uses all of the screen on mobiles)
		const smallView = this.props.global.breakpoint.level <= util.breakpoints['tablet'];		//windowWidth <=  768;
		//const tinyView = windowWidth <=  480;

		const windowWidthScale = smallView ? 1 : 0.8;
		const windowHeightScale = smallView ? 1 : 0.8;

		// Used to make the panel calc aware of panelAspect
		const portraitPanelWidth = 364;
		const landscapePanelHeight = 150;
		//const isMobileLAndBelow	= this.props.global.breakpoint.level <= util.breakpoints['mobile-l'];
		//console.log('isMobileLAndBelow',isMobileLAndBelow);
		// const isLandscapeView = window.innerWidth >= window.innerHeight;
		//console.log('isLandscapeView',isLandscapeView);
	    // TASK: Check if we are in mobile and reduce  portraitPanelWidth and landscapePanelHeight prior to calculations
		// also check if image is not as high as view port (no extra benefit in reducing width of card)
		// const portraitPanelWidthSMALL = 200;
		// const landscapePanelHeightSMALL = 100;

		const imageFullWidth = (image && image.width) ? image.width : 600;
		const imageFullHeight = (image && image.height) ? image.height : 450;

		// pixels of window space available for each panel placement option - then compare least scaledown affect
		const windowWidthPXc  = Math.floor((windowWidth  * windowWidthScale)  - portraitPanelWidth  );
		const windowHeightPXc = Math.floor((windowHeight * windowHeightScale) - landscapePanelHeight );

		// now work out the scale needed to apply to image in each dimension to fit available space
		const imageAndPanelWidthScale = windowWidthPXc / imageFullWidth;
		const imageAndPanelHeightScale = windowHeightPXc / imageFullHeight;
		// Use the larger scale as the panelAspect (results in biggest image)
		const panelAspect = imageAndPanelWidthScale >= imageAndPanelHeightScale ? 'portrait' : 'landscape';

		// determine how much window space we have available taking into account the panel in the calculated panelAspect (for descrtiption, comments, etc.)
		// If the image is portrait panelAspect, the panel will be on the side, therefore affecting width
		// If the image is landscape we will put the panel below the image so the height is adjusted.
		const windowWidthPX  = Math.floor((windowWidth  * windowWidthScale) - (panelAspect === 'portrait'  ? portraitPanelWidth : 0 )  );
		const windowHeightPX = Math.floor((windowHeight * windowHeightScale) - (panelAspect === 'landscape' ? landscapePanelHeight : 0) );

		const imageWidthScale = windowWidthPX / imageFullWidth;
		const imageHeightScale = windowHeightPX / imageFullHeight;

		//const scaleBy = imageWidthScale>=imageHeightScale ? 'H' : 'W';
		const rawScaleFactor = imageWidthScale >= imageHeightScale ? imageHeightScale : imageWidthScale;
		const scaleFactor = rawScaleFactor>=1 ? 1 : rawScaleFactor;
		let fullMomentDisplayH = Math.floor(imageFullHeight * scaleFactor);		// includes space allowance for panel if landscape (and panel below image)
		let fullMomentDisplayW = Math.floor(imageFullWidth * scaleFactor);		// includes space allowance for panel if portrait (and panel beside image)

// TEMP remove extra small checks... reinstate with testing for mobile
		// if (fullMomentDisplayH < 390) {
		// 	console.log('Reduce FMD',windowWidth);
		// 	imageClasses.push('full-moment__image--small');
		// 	fullMomentDisplayH = 390;
		// 	fullMomentDisplayW = 520;
		// }

		// Push the card panel height to the height of the image if side by side (portrait image) or the standard height if below image (landscape)
		let fullMomentCardStyles = {
			height: ( panelAspect === 'landscape' ? landscapePanelHeight : fullMomentDisplayH )+'px',
			width:  ( panelAspect === 'landscape' ? fullMomentDisplayW  : portraitPanelWidth  )+'px',
		};
		// push the moment styles to match the image and panel
		// NOTE: reduction of 1 pixel to account for occasional gap due
		// NOTE: Special treatment of height  - may come back and do same for width. To do with URL Bar resizing.
		const fullMomentStyles = {
			height: smallView ? windowHeight+'px' : (fullMomentDisplayH + (panelAspect === 'landscape' ? landscapePanelHeight-1 : 0) )+'px',
			width:  smallView ? windowWidth+'px' : (fullMomentDisplayW + (panelAspect === 'portrait'  ? portraitPanelWidth-1  :  0) )+'px',
		};

		// SHOW/HIDE FEED... check the combined landscape px available (width of image) can cope with description and feed... otherwise kill feed
		// Logically this should be the min px width of the description and the min px width of the feed added together.
		const narrowLandscape = panelAspect === 'landscape' && fullMomentDisplayW <= 800;

		return (
			<div className={`full-moment full-moment--${panelAspect}`} onClick={e => e.stopPropagation()} style={fullMomentStyles}>
				<Form>
					{this.renderImage(image, fullMomentDisplayW, fullMomentDisplayH)}
					<div className={`full-moment__card full-moment__card--${panelAspect}`} style={fullMomentCardStyles}>
						{this.renderMomentDescription(panelAspect, narrowLandscape)}
						{!narrowLandscape && this.renderFullMomentFeed()}

						{/* MAYBE MOVE TO ICON {this.renderPrivacyControls()} */}
						{/* REINSTATE - MAYBE AS PART OF ICONS (AS THEY ARE NOT SHOWN IN EDIT MODE) {this.renderEditControls(smallView)} */}
					</div>
				</Form>
			</div>
		);
	};

	renderImage = (image, fullMomentDisplayW, fullMomentDisplayH) => {
		return (
			<Image
				className="full-moment__image"
				image={image}
				alt="Moment"
				onChange={this.imageMetaData}
				onImageUpload={this.handleImageUploaded}
				editable={this.state.editMode}
				forceWidth={fullMomentDisplayW+"px"}
				forceHeight={fullMomentDisplayH+"px"}
				required={true}
			/>
		);
	}

	renderMomentDescription = (panelAspect, narrowLandscape, hideIcons) => {
		const {
			year,
			month,
			day,
			date,
			approximate,
			shortDescription,
		} = this.state.moment;

		const descriptionClasses = [ 'full-moment__description' ];
		if (this.state.editMode) {
			descriptionClasses.push('full-moment__form-field');
		} else {
			descriptionClasses.push('full-moment__display-field');
		}

		const dateClasses = [ 'full-moment__dateCircaAndIcons' ];
		if (this.state.editMode) {
			dateClasses.push('full-moment__dateCircaAndIcons--switched');
		}

		return (
			<div className="full-moment__dateCircaIconsAndDescription">
				<div className={dateClasses.join(' ')}>
					{this.renderDateSection(date, approximate, year, month, day, panelAspect)}
					{!hideIcons && this.renderIcons(narrowLandscape, panelAspect)}
				</div>
				<Input
					type="textarea"
					rows={panelAspect === 'landscape' ? '' : 6}
					name="shortDescription"
					label={panelAspect === 'landscape' ? '' : "Description"}
					className={descriptionClasses.join(' ')}
					placeholder="Enter a short description of this Moment up to 1000 characters."
					maxlength={1000}
					onBlur={this.handleBlur}
					onKeyUp={this.handleKeyUp}
					value={shortDescription || ''}
					editable={this.props.editAllowed}
					onClick={this.handleClick}
					onKeyPress={this.handleKeyPress}
					editMode={this.state.editMode}
					onChange={this.handleInputChange}
				/>
			</div>
		);
	}

	toggleControlls = () => this.setState({ isControlsOpen: !this.state.isControlsOpen });

	renderFullScreenMoment = () => {
		return (
			<FullScreenMoment
				moment={this.state.moment}
				title={this.props.lifeTimeLineTitle}
				handleScrollLeft={() => this.scrollMoment(null, 'left')}
				handleScrollRight={() => this.scrollMoment(null, 'right')}
				handleToggleControlls={this.toggleControlls}
				isControlsOpen={this.state.isControlsOpen}
				handleDelete={this.handleDelete}
				renderMomentDescription={this.renderMomentDescription}
				handleEditSwitch={this.handleEditSwitch}
				editMode={this.state.editMode}
				editAllowed={this.props.editAllowed}
				renderImage={this.renderImage}
			/>
		);
	};

	render () {
		const {
			moment,
		} = this.props;

		if (!moment) {
			return null;
		}

		const {
			editMode,
			isFullScreen,
			isControlsOpen,
		} = this.state;

		return (
			<div
				className="full-moment__canvas"
				onClick={this.onClickBackdrop}
			>
				{this.renderControls(
					editMode,
					isControlsOpen,
				)}
				{this.renderScroll(
					'right',
					editMode,
					isControlsOpen,
				)}
				{this.renderScroll(
					'left',
					editMode,
					isControlsOpen,
				)}
				{isFullScreen ?
					this.renderFullScreenMoment() :
					this.renderMoment()
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
		global: state.global,
	};
};

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSetMoment: (data) => {
			dispatch({
				type: 'SET_LIFETIMELINE_MOMENT',
				data,
			});
		},
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(FullMoment);
