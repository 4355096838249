import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import PageMenu 		from '../../components/PageMenu/PageMenu';
import PageMenuItem 	from '../../components/PageMenu/PageMenuItem';
import VerticalMenu 		from '../../components/PageMenu/VerticalMenu';

//import * as util from '../../utils/general';

import './AccountMenu.scss';

class AccountMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			verticalMenuActive: false,
		};
	}

	toggleVerticalMenu = () => {
		this.setState(prevState => ({ verticalMenuActive: !prevState.verticalMenuActive }));
	};

	// TASK: (already begun) Look at how we can compbine the menu item calls
	// (PageMenuItem & PageSubMenuItem - & maybe UserMenu too)
	// into a common component. See CR-12
	renderMenuEnd = (isMobileLAndBelow) => {
		if (isMobileLAndBelow) {
			return (
				<Fragment>
					<PageMenuItem
						isOn={false}
						isHighlighted={false}
						onClick={this.toggleVerticalMenu}
						linkTo=""
						iconClassName="fa fa-bars"
						label="More"
						/>
					<VerticalMenu
						verticalMenuActive={this.state.verticalMenuActive}
						onCloseHandler={this.toggleVerticalMenu}
						>
						<PageMenuItem
							isOn={this.props.windowLocationPathname === '/account/notifications'}
							isHighlighted={false}
							linkTo="/account/notifications"
							iconClassName="fa fa-bell-o"
							label="Notifications"
							menuType="vertical"
							/>
						<PageMenuItem
							isOn={this.props.windowLocationPathname === '/account/settings'}
							isHighlighted={false}
							linkTo="/account/settings"
							iconClassName="fa fa-cog"
							label="Settings"
							menuType="vertical"
							/>
						<PageMenuItem
							isOn={this.props.windowLocationPathname === '/account/associations'}
							isHighlighted={false}
							linkTo="/account/associations"
							iconClassName="fa fa-black-tie"
							label="Associations"
							menuType="vertical"
							/>
					</VerticalMenu>
				</Fragment>
			)
		} else {
			return (
				<Fragment>
					<PageMenuItem
						isOn={this.props.windowLocationPathname === '/account/notifications'}
						isHighlighted={false}
						linkTo="/account/notifications"
						iconClassName="fa fa-bell-o"
						label="Notifications"
						/>
					<PageMenuItem
						isOn={this.props.windowLocationPathname === '/account/settings'}
						isHighlighted={false}
						linkTo="/account/settings"
						iconClassName="fa fa-cog"
						label="Settings"
						/>
					<PageMenuItem
						isOn={this.props.windowLocationPathname === '/account/associations'}
						isHighlighted={false}
						linkTo="/account/associations"
						iconClassName="fa fa-black-tie"
						label="Associations"
						/>
				</Fragment>
			)
		}
	};


	render () {
		// var {
		// 	global,
		// } = this.props;

		//const isTabletAndBelow = this.props.breakpoint.level <= util.breakpoints['tablet'];
		//const isMobileLAndBelow = global.breakpoint.level <= util.breakpoints['mobile-l'];
		return (
			<PageMenu className='pageMenu pageMenu--account'>
				<PageMenuItem
					isOn={this.props.windowLocationPathname === '/account/details'}
					isHighlighted={false}
					linkTo="/account/details"
					iconClassName="fa fa-id-badge"
					label="My Details"
					/>
				{/* <PageMenuItem
					isOn={this.props.windowLocationPathname === '/account/transactions'}
					isHighlighted={false}
					linkTo="/account/transactions"
					iconClassName="fa fa-file-text-o"
					label="Transactions"
					/>
				<PageMenuItem
					isOn={this.props.windowLocationPathname === '/account/messages'}
					isHighlighted={false}
					linkTo="/account/messages"
					iconClassName="fa fa-envelope-o"
					label="Messages"
					/>
				{this.renderMenuEnd(isMobileLAndBelow)} */}
			</PageMenu>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
		global: state.global,
		cart: state.cart,
	};
}


export default connect(
	mapStateToProps,
)(AccountMenu);
