import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Message.scss';

class Message extends Component {
	onClose = () => {
		this.props.onSetMessage({message: undefined});
		// TODO: implement better solution to turning off spinners when alert terminates.
		if(document.body.contains(document.getElementById('theSpin'))){
			document.getElementById('theSpin').className = '';		// spin the icon in the right box
			document.getElementById('theSocial').className = '';	// hide the social sign in options
		}
	}

	renderEmailLink = () => {
		const { title, actionEmail, message, description, detail, id, } = this.props.global;
		if(actionEmail) {
			let theHref = `mailto:${this.props.audience.supportEmail}?subject=${title}&body=${message}%0D%0A
			${description}%0D%0A
			${detail}%0D%0A
			AlertID: ${id}%0D%0A
			%0D%0A`;
			return (
				<a	className="message__email"
					href={theHref}
					>Ask us about this.</a>
			)
		}
	}

 	render() {
		 const {
			//status,
			title,
			message,
			description,
			detail,
			actionLabel,
			actionTask,
			id,
			//	actionEmail,
		 } = this.props.global



		 if (!message ) {
			return null;
		 }

		 let aProps = {};
		 if (actionTask === 'close') {
			aProps = {
				...aProps,
				onClick: this.onClose
			}
		}

		const alertID = id ? `AlertID: ${id}` : '';

		 // TODO: Stop message_content click event		onClick={this.onClose}
		return (
			<div className="message__canvas" onClick={this.onClose}>
				<div className="message__content">
					<div className="message__title">
						{title}
					</div>
					<div className="message__description">
						{description}
					</div>
					<div className="message__detail">
						{message}<br />
						{detail}<br />
						{alertID}<br />
						{this.renderEmailLink()}
					</div>
					<div className="button-box">
						<a 	className="global__button global__button--blue"
							{...aProps}
							>
							{actionLabel}
						</a>
					</div>
				</div>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		global: state.global,
		audience: state.audience,
	};
};

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Message);