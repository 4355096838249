import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import TextInput from '../FocusBox/Inputs/TextInput';
import Image from '../Form/Image';
import Backdrop from '../Backdrop';
import Spinner from '../Spinner';

import * as timeLineAPI from '../../api/timeLineAPI';

import { formatTimespan } from '../../utils/text';
import { debounce } from '../../utils/general';

import './Search.scss';

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			results: null,
		};
	}

	getSearchResults = (e) => this.setState(
		{ title: e.target.value },
		// setState callback
		this.callSearchAPI,
	);

	callSearchAPI = debounce(() => {
		const title = this.state.title;

		if (title.length < 3) {
			this.setState(() => ({ results: null }));
			return;
		}

		timeLineAPI.search(title)
			.then(resp => {
				if (!resp.success) {
					// TASK: Handle error
					return;
				}

				this.setState(() => ({
					results: resp.data
				}));
			});
	}, 300);

	clearSearch = () => this.setState(() => ({ title: '', results: null }));

	renderResults = (title, results) => {
		if (results === null && title.length > 0) {
			return (
				<div className="search__results search__results--empty">
					<Spinner
						className="search__spinner"
						active={true}
						color="#999"
					/>
				</div>
			);
		} else if (results === null) {
			return null;
		} else if (results && results.length === 0) {
			return (
				<div className="search__results search__results--empty">No results</div>
			);
		}

		return (
			<div className="search__results">
				{results.map(result => (
					<Link
						to={`/${result.timelineId}`}
						onClick={this.clearSearch}
						className="search__result" key={`search-result${result.timelineId}`}
					>
						<Image
							className="search__image"
							image={result.image}
						/>
						<div className="search__title">
							{result.title}
							{result.numberOfMoments > 0 && <span className="search__moment-count">{result.numberOfMoments} moments</span>}
							<div className="search__timespan">
								{formatTimespan(result.fromYear, result.toYear)}
							</div>
						</div>
					</Link>
				))}
			</div>
		);
	};

	render() {
		const {
			title,
			results,
		} = this.state;

		const isResultsOpen = title.length > 0;

		const classes = [ 'search' ];
		if (this.props.className) {
			classes.push(this.props.className);
		}

		return (
			<div className={classes.join(' ')}>
				<TextInput
					name="title"
					className="search__input"
					placeholder="Enter a name."
					value={title}
					onChange={this.getSearchResults}
				/>
				<Backdrop
					className="description__backdrop"
					isOpen={isResultsOpen}
					onClose={this.clearSearch}
				/>
				{this.renderResults(title, results)}
				<button
					htmlFor="headerSearchInput"
					className="search__button"
				>
					Search
				</button>
			</div>
		);
	}
};

export default Search;
