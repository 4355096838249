import React, { Component } from 'react';
import { connect } from 'react-redux';

import FocusBox from '../../../components/FocusBox/FocusBox';
import Button from '../../../components/Form/Button';
import Spinner from '../../../components/Spinner';

import * as personAPI from '../../../api/personAPI';

import './Verification.scss';

class Verify extends Component {
	state = {
		loading: true,
		resultHeading: 'loading...',
		resultMessage: '',
	};

	componentDidMount() {
		personAPI.actionEmailVerify(this.props.match.params.id)
			.then(resp => {
				if (resp.success === true && resp.data.verificationSuccessful === true) {

					this.setState({
						loading: false,
						resultHeading: "Verification Successful!",
						resultMessage: "That's great, now we can protect your content using your trusted email.",
					 });

					if (resp.data.entityId && this.props.profile.entityId && resp.data.entityId === this.props.profile.entityId) {		// verifying already signed in user

						console.log('onSetProfile',this.props.profile);
						this.props.onSetProfile({
							...this.props.profile,
							primaryEmailAddressVerified: true,
						});

					} else {
						console.log('onSignOut');
						this.props.onSignOut();
					}

				} else {

					this.setState({
						loading: false,
						resultHeading: "Unable to Verify",
						resultMessage: "We couldn't verify your email at his time.",
					});
				}
			});
	}

	onConfirm = () => this.props.history.push('/lifetimelines');

	render() {
		if (this.state.loading) {
			return (
				<Spinner className="verify__loader" active={true} color="#999" />
			);
		}

		return (
			<FocusBox className="verify" brand={this.props.audience.brand}>
				<div>
					<div className="verify__content">
						<div className="focusBox__title">{this.state.resultHeading}</div>
						<div className="focusBox__text">{this.state.resultMessage}</div>
						<div className="verify__buttons">
							<Button
								className="verify__button"
								color="blue"
								onClick={this.onConfirm}
							>
								Ok
							</Button>
						</div>
					</div>
				</div>
			</FocusBox>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile,
	audience: state.audience,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetProfile: (data) => {
		dispatch({
			type: 'SET_PROFILE',
			data,
		});
	},
	onSignOut: () => {
		dispatch({ type: 'SIGN_OUT' });
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Verify);
