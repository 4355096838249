import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import * as timeLineAPI from '../../api/timeLineAPI';
//import { brandConfig } from '../../api/brandConfig';

import RecordBox from '../../components/RecordBox/RecordBox';
import Spinner from '../../components/Spinner';
import Logo from '../../components/Brand/Logo';
import Footer from '../../components/Footer';

import './HomePartner.scss';

class HomePartner extends Component {
	_isMounted = false;
	constructor(props) {
		super(props);
		this.state = {
			lifeTimeLines: [],
			loading: true
		};

	}

	componentDidMount = () => {
		this._isMounted = true;

		timeLineAPI.getAllPublic()
			.then(resp => {
				console.log('HP.26 _isMounted = ', this._isMounted );
				if (!this._isMounted) {
					console.log('HP.27 Canceled the subscription as the component has been un-mounted.');
					return null;
				}
				if (resp.success) {
					this.setState({
						lifeTimeLines: resp.data,
						loading: false,
					});
				} else {
					this.setState({ loading: false });
				}
			});
	}

	componentWillUnmount = () => {
		this._isMounted = false;
		console.log('HP.48 _isMounted = ', this._isMounted );
	}

	renderLifeTimelines = (lifeTimeLines) => {
		if (!lifeTimeLines) {
			return null;
		}
		const recordBoxesAcross = Math.floor(window.innerWidth/306.66);
		const getToRecordPosition = recordBoxesAcross === 3 ? 17 : 19; // this gets 18 records if width = 3 across and 20 in all other cases.
		return Object.keys(lifeTimeLines).map((lifeTimeLineID, i) => {
			if (i > getToRecordPosition) {
				return null;
			}
			//console.log('ownerEntityId',lifeTimeLines[lifeTimeLineID].ownerEntityId,'entityId',this.props.profile.entityId)
			return (
				<RecordBox
					className="homeRecordBox"
					key={lifeTimeLineID}
					isPublic={this.props.profile.entityId !== lifeTimeLines[lifeTimeLineID].ownerEntityId}
					isImageLazyLoaded={false}
					{...lifeTimeLines[lifeTimeLineID]}
				/>
			);
		});
	};

	render() {
		const lifeTimeLines = this.renderLifeTimelines(this.state.lifeTimeLines);
		const audience = this.props.audience;
		return (
			<div className="homepartner-page">
				<div className="main-strip">
					<div className="main-strip__centred">
						<div className="main-strip__pic">
							<img className="partner-manager-profile-pic" src={`https://cdn.inmemoriam.com/images/${audience.strip.managerPic}`} alt={audience.strip.managerName} />
						</div>
						<div className="main-strip__note">
							<h1>{audience.strip.managerName}</h1>
							<h2>{audience.strip.managerRole}</h2>
							<p>{audience.strip.homePitch}</p>
						</div>
						<div className="main-strip__pitch">

							<h1>Beautiful and enduring life stories.</h1>
							<p>LifeTimeLine<span className="regMark">®</span> is an ad-free, community-centric</p>
							<p>and permanent online space. It provides a </p>
							<p>safe and respectful place to create lasting </p>
							<p>life stories. It’s a way to share your story </p>
							<p>around the world and across generations.</p>

						</div>
						<Logo
							className="main-strip__logo"
							brand={audience.brand}
							logoContext="mainStrip"
						/>
					</div>
				</div>
				<div className="home-records__wrapper">
					<div className="home-records">
						{lifeTimeLines}
						<Spinner
							className="home-records__spinner"
							active={this.state.loading}
							color="#999"
						/>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
};

const mapStateToProps = (state) => {
	return {
		audience: state.audience,
		profile: state.profile,
	};
}


export default connect(
	mapStateToProps,
)(HomePartner);
