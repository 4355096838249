import React from 'react';

import './VerticalMenu.scss';

const VerticalMenu = ({
	verticalMenuActive,
	onCloseHandler,
	children,
	...otherProps,
}) => {

	if (!verticalMenuActive) {
		return null;
	}

	return (
		<div className="verticalMenu__canvas" onClick={onCloseHandler}>
			<div className="verticalMenu">
				{children}
			</div>
		</div>
	);
}

export default VerticalMenu