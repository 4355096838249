//import { sendFetchRequest, getApiContext } from './api_utils';

//const maintenance = `${getApiContext()}/api/maintenance`;

const logError = (message) => {
	//const payload = {
	//	message,
	//};

console.log(message);

	// const options = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json',
	// 	},
	// 	body: JSON.stringify(payload),
	// };

	// return sendFetchRequest(`${maintenance}/felog`, options);
};

export { logError };
