import React, { Component } from 'react';
import { Switch, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';

import * as personAPI from '../../api/personAPI';
import { AuthRoute } from '../../utils/routing';

import Details from './Pages/Details';
import Transactions from './Pages/Transactions';
import Messages from './Pages/Messages';
import Notifications from './Pages/Notifications';
import Settings from './Pages/Settings';
import Associations from './Pages/Associations';
import AccountMenu from '../../components/AccountMenu/AccountMenu';

import Spinner from '../../components/Spinner';

import { dispatchError } from '../../utils/general';

import './Account.scss';

class AccountRouter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			editMode: true,
			entityData: {
				entityId: +this.props.match.params.id || this.props.profile.entityId,
				image: {},
				firstName: '',
				middleName: '',
				lastName: '',
				primaryEmailAddress: '',
				name: '',
				nickname: '',
				nicknameDisplay: '',
				otherNames: '',
				password: '',
				type: '',
				verificationPassword: '',
				primaryEmailAddressVerified: false,
				// MATCH: entityData :: See profileReducer for matching list.
			},
			passwordEditActive: false,
			passwordChanged: false,
			saveState: 'default', // [default] | processing | success | fail
			childChangesMade: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState( { entityData: nextProps.profile, childChangesMade: false, } );
	}

	componentDidMount() {
		const entityId = this.state.entityData.entityId;
		if (entityId === this.props.profile.entityId) {
			this.setState( { entityData: this.props.profile, loading: false } );
		} else if ( entityId && !isNaN(entityId)) {
			this.setState({ loading: false });
			this.props.onSetMessage({
				title: 			'Ooops!',
				message: 		'The current security policy is only to allow access to your own user details.',
				description: 	'Access not permitted.',
				detail: 		'',
				actionEmail: 	false,
				id:				'D42'
			});
			// TASK: to allow management of other user records, once the API allows, change the error above to the calls below.
			// this.callPersonAPI(entityId);
			// Add setState for entityDate etc.
		} else if (!this.props.entityId) {
			this.setState({ loading: false });
			this.props.onSetMessage({
				title: 			'Ooops!',
				message: 		'Users can only be created by registration.',
				description: 	'Access not permitted.',
				detail: 		'',
				actionEmail: 	false,
				id:				'D53'
			});
			// TASK: to allow creation of other users' records, once the API allows, change the error above to the calls below.
			this.setState( { entityData: { entityId: 'new', }, loading: false, } )
		}
		// Switch to editMode if a new record
		if( (this.props.entity &&  this.props.entity.entityId === 'new') || (!this.props.entity && isNaN(entityId)) ) {
			this.setState({editMode: true});
		}
	}

	callPersonAPI = (id) => {
		return personAPI.get(id)
			.then(resp => {
				if (resp.success) {
					this.setState({ loading: false });
					return resp.data;
				}
			})
			.catch((err) => {
				dispatchError(err);
				// STANDARD FETCH CONNECTION ERROR
				this.setState({ loading: false });
				this.props.onSetMessage({
					title: 			'Ooops!',
					message: 		'Please check your internet connection and try again.',
					description: 	'We could not connect to the service.',
					detail: 		'',
					actionEmail: 	false,
					id:				'D36'
				});
			});
	}

	renderLoadingSpinner = (loading) => {
		if (!loading) {
			return null;
		}
		return (
			<div className="spinnerBox"><Spinner active={true} color="#999" /></div>
		);
	}


	render() {

		const { isSignedIn, match } = this.props;
		const loadingSpinner = this.renderLoadingSpinner(this.state.loading);
		const windowLocationPathnameNoIds = window.location.pathname.replace(/\/?\d+/g, '');

		return (
			<div className="account__page">
				<AccountMenu
					path={match.path}
					windowLocationPathname={windowLocationPathnameNoIds}
				/>
				{loadingSpinner}
				<Switch>
					<AuthRoute
						exact
						path="/account/:id?/details"
						render={() => <Details entityData={this.state.entityData} />}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
					<AuthRoute
						exact
						path="/account/:id?/transactions"
						component={Transactions}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
					<AuthRoute
						exact
						path="/account/:id?/messages"
						component={Messages}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
					<AuthRoute
						exact
						path="/account/:id?/notifications"
						component={Notifications}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
					<AuthRoute
						exact
						path="/account/:id?/settings"
						component={Settings}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
					<AuthRoute
						exact
						path="/account/:id?/associations"
						component={Associations}
						signInRequired={true}
						isSignedIn={isSignedIn}
					/>
				</Switch>
				<Prompt
					when={this.state.childChangesMade}
					message={location =>
						`It looks like you have unsaved changes on this page. Are you sure you want to go to ${location.pathname}`
					}
					/>
			</div>
		);
	}
}
//export default AccountRouter;

const mapStateToProps = (state) => {
	return {
		profile: state.profile,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetMessage: (data) => {
		dispatch({
			type: 'SET_MESSAGE',
			data,
		});
	},
	onSetProfile: (data) => {
		dispatch({
			type: 'SET_PROFILE',
			data,
		});
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountRouter);
