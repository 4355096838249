import React, { Component } from 'react';

import './TextInput.scss';

export default class TextInput extends Component {
	input = null;

	state = {
		showErrors: false,
	};

	focus = () => this.input.focus();

	renderIcon = (icon) => {
		if (!icon || !icon.length) {
			return null;
		}

		return (
			<div className="textInput__icon">
				<i className={`fa ${icon}`}></i>
			</div>
		);
	};

	renderValidationMessages = (validationMessages) => {
		if (!validationMessages || !validationMessages.length) {
			return null;
		}

		const messages = React.Children.map(
			validationMessages,
			message => <li className="textInput__validationMessage">{message}</li>
		);

		return (
			<ul className="textInput__validationMessages">
				<i className="textInput__validationIcon fa fa-exclamation-triangle"></i>
				{messages}
			</ul>
		);
	};

	onFocus = (e) => {
		this.setState({ showErrors: true });

		const onFocus = this.props.onFocus;
		if (onFocus) {
			onFocus(e);
		}
	}

	onBlur = (e) => {
		this.setState({ showErrors: false });

		const onBlur = this.props.onBlur;
		if (onBlur) {
			onBlur(e);
		}
	}

	render() {
		const {
			className,
			icon,
			validationMessages,
			forceInvalid,
			forceShowErrors,
			...otherProps
		} = this.props;

		const wrapperClasses = [ 'textInputWrapper' ];

		if (className) {
			wrapperClasses.push(className);
		}

		if (forceShowErrors || this.state.showErrors) {
			wrapperClasses.push('textInputWrapper--showErrors');
		}

		if (forceInvalid || (validationMessages && validationMessages.length)) {
			wrapperClasses.push('textInputWrapper--invalid')
		}

		const inputClasses = [ 'textInput' ];

		if (icon && icon.length) {
			inputClasses.push('textInput--hasIcon');
		}

		return (
			<div className={wrapperClasses.join(' ')}>
				{this.renderIcon(icon)}
				<input
					ref={input => this.input = input}
					className={inputClasses.join(' ')}
					{...otherProps}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
				/>
				{this.renderValidationMessages(validationMessages)}
			</div>
		);
	}
}