import React, { Component } from 'react';
import { connect } from 'react-redux';

import SummaryPanel from '../../components/SummaryPanel/SummaryPanel';
import Moments from '../../components/Moments/Moments';
import FullMoment from '../../components/Moment/FullMoment';
import AuthorMenu from '../../components/AuthorMenu/AuthorMenu';

import { dispatchError } from '../../utils/general';

import * as timeLineAPI from '../../api/timeLineAPI';

//import * as util from '../../utils/general';
import Spinner from '../../components/Spinner';

import './LifeTimeLine.scss';

class LifeTimeLine extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			editMode: !this.props.lifeTimeline ? false : true,
			changesMade: false,
			loadingImage: false,
			saving: false,
			lifeTimeLineData: Object.assign({}, props.lifeTimeLine),
		};
	}

	componentDidMount() {
		if (!this.props.profile.isSignedIn && this.props.match.params.author === 'author') {
			this.props.history.push('/sign-in');
		}

		const lifeTimeLineId = this.props.match.params.id;
		if (!this.props.lifeTimeLine && lifeTimeLineId && !isNaN(lifeTimeLineId)) {
			this.callLifeTimeLineAPI(lifeTimeLineId);
		} else if (!this.props.lifeTimeline) {
			const data = {
				timelineId: 'new',
				ownerEntityId: this.props.profile.entityId,
				subjectEntityType: 'Person',
				title: `${this.props.profile.firstName} ${this.props.profile.lastName}`,
				subjectEntityId: this.props.profile.entityId,
			};
			// New or Edit - Get the data from the redux store
			this.props.onSetLifeTimeLine(data);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.lifeTimeLine && nextProps.lifeTimeLine.timelineId !== this.state.lifeTimeLineData.timelineId) {
			this.setState({ lifeTimeLineData: nextProps.lifeTimeLine });
		} else if (this.props.match.params.id !== nextProps.match.params.id) {
			this.callLifeTimeLineAPI(nextProps.match.params.id);
		}
	}

	resolveLifeTimeLineId = (id) => {
		if (isNaN(id)) {
			console.log('CustomURL Preview L67 for',id);
			var regex = /^[a-z0-9]{1}[a-z0-9-]+[a-z0-9]{1}$/i; 		// must be at lest three characters and not start or end with anything but 1-0,a-z
			if (!regex.test(id)) {
				console.log('Invalid CustomURL');
			} else {
				console.log('TEST: Valid');
				// Temporarily manually set LifeTimeLine IDs by known Custom URLs
				const tempCustomURLs = [
					{	customURL: 'chester-nutting',
						id: 1
					},
					{	customURL: 'pamela-nutting',
						id: 8
					},
					{	customURL: 'keith-webb',
						id: 52
					},
				]
				const resolvedId = tempCustomURLs.find(u => u.customURL === id).id;
				if (!isNaN(resolvedId)) {
					console.log('RESOLVED TO: ',resolvedId);
					return resolvedId;
				}
			}
		}
		console.log('Post Check 01',id);
		return null;
	}



	callLifeTimeLineAPI = (id) => {
		//alert('callLifeTimeLineAPI');
		if (this.props.profile.isSignedIn && this.props.match.params.author === 'author') {
			timeLineAPI.get(id)
				.then(this.onCallLifeTimeLineAPISuccess)
				.catch(err => {
					dispatchError(err);
					this.onCallLifeTimeLineAPIError(err)
				});
		} else {
			timeLineAPI.getPublic(id)
				.then(this.onCallLifeTimeLineAPISuccess)
				.catch(err => {
					dispatchError(err);
					this.onCallLifeTimeLineAPIError(err)
				});
		}
	};

	onCallLifeTimeLineAPISuccess = (resp) => {
		if (resp.success) {
			this.props.onSetLifeTimeLine(resp.data);
		} else {
			// TODO: Handle no LifeTimeLine data
			this.props.onSetMessage({
				title: 			'Ooops!',
				message: 		'We could not load the requested LifeTimeLine.',
				description: 	'',
				detail: 		'',
				actionEmail: 	false,
				id:				'LTL52'
			});
		}
		this.setState({ loading: false });
	};

	onCallLifeTimeLineAPIError = (error) => {
		// STANDARD FETCH CONNECTION ERROR
		this.setState({ loading: false });
		this.props.onSetMessage({
			title: 			'Ooops!',
			message: 		error.name + ':  ' + error.message,
			description: 	'We could not connect to the service.',
			detail: 		'',
			actionEmail: 	false,
			id:				'LTL75'
		});
	};

	onDeleteMoment = (momentID) => {
		const lifeTimeLineID = this.props.match.params.id;

		this.props.onSetConfirm({
			lifeTimeLineID,
			momentID,
		});
		// toggle delete confirm
		// type of confirm required (passive/active)
		// action task (uri? function?)
		// outcome (close, callback, other?)
	};

	handleInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;

		this.setState({
			lifeTimeLineData: {
				...this.state.lifeTimeLineData,
				[name]: value,
			}
		});

		this.handleChangesMade();
	};

	checkDiscardChanges = () => {
		// if   No changes made   OR   changes made AND discard confirmed
		return !this.state.changesMade || (this.state.changesMade && window.confirm('You have unsaved changes. Do you want to leave anyway?'));
	};

	handleChangesMade = () => {
		this.setState({
			changesMade: true,
		});
	}

	handleCancelEdit = (editMode) => {
		if (editMode) {										// scroll (which could be set to cancel editMode) can trigger multiple times
			const discardChanges = this.checkDiscardChanges();
			if (discardChanges) {
				if (this.props.lifeTimeLine &&  this.props.lifeTimeLine.timelineId === 'new') {
					window.location = '/lifetimelines';
					return;
				}
				this.setState({
					lifeTimeLineData: Object.assign({}, this.props.lifeTimeLine),
					changesMade: false,
				});
				this.toggleEditMode();
			}
		}

	};


	handleImageChange = (event, image, orientation) => {
		this.setState({
			loadingImage: true,
			lifeTimeLineData: {
				...this.state.lifeTimeLineData,
				image: {
					...this.state.lifeTimeLineData.image,
					persistedFileId: 0,
					// Setting URI to be the image base64 so that the image is displayed on the screen while it is uploading,
					// once the upload is complete it will be set to a valid uri
					uri: image.src,
					width: image.width,
					height: image.height,
				},
			}
		});
	};


	handleImageUploaded = (resp) => {
		if (resp.success) {
			this.setState({
				lifeTimeLineData: {
					...this.state.lifeTimeLineData,
					image: resp.data,
				},
				loadingImage: false,
			});
		} else {
			// TASK: Handle file upload failure
		}

		// TASK: *concept from Moment Save. Consider what should happen if the user is trying to save but the image upload failed
		if (this.state.saving) {
			window.setTimeout(() => this.saveLifeTimeLine(), 0);
		}
	};


	saveLifeTimeLine = () => {

		this.setState({
			saving: true,						// set flag so we can let image upload finish (if in progress) then return to save
		});
		if (this.state.loadingImage) {
			return;								// if image is mid way through uploading, bail on the save and refire ir after upload finished
		}

		const lifeTimeLineData = this.state.lifeTimeLineData;
		const payload = {
			...this.state.lifeTimeLineData,
			fromYear: !!lifeTimeLineData.fromYear ? lifeTimeLineData.fromYear : 0,
			toYear: !!lifeTimeLineData.toYear ? lifeTimeLineData.toYear : 0,
		};

		if (payload.timelineId && payload.timelineId !== 'new') {
			timeLineAPI.update(payload)
				.then(resp => {
					if (resp.success) {
						//this.handleLoadingEnd('formLoading');
						//this.setState({ editMode: false });
						this.toggleEditMode();
						this.props.onSetLifeTimeLine(payload);
						this.setState({ saving: false });
					} else {
						// TASK: Handle error
						console.log('Error response from timeLineAPI');
					}
				});
		} else {
			// Delete the 'new' timelineId
			delete(payload.timelineId);
			timeLineAPI.create(payload)
				.then(resp => {
					if (resp.success) {
						this.toggleEditMode();
						this.props.onSetLifeTimeLine(resp.data);
						this.redirect(this.props.match.params.author, null, resp.data.timelineId);
						this.setState({ saving: false });
					} else {
						// TASK: Handle error
					}
				});
		}
	};


	buildURL = (author, momentID, newLifeTimeLineID) => {
		let url = '';

		if (author) {
			url += `/${author}`;
		}

		url += `/${newLifeTimeLineID || this.props.match.params.id}`;

		if (momentID) {
			url += `/m/${momentID}`;
		}

		return url;
	};


	toggleEditMode = () => {
		if (!this.state.editMode) {
			window.scrollTo(0, 0);
		}
		this.setState({ editMode: !this.state.editMode });
	}

	redirect = (author, momentID, newLifeTimeLineID) => {
		const url = this.buildURL(author, momentID, newLifeTimeLineID);

		this.props.history.push(url);
	};

	render() {
		const lifeTimeLine = this.props.lifeTimeLine;

		if (!lifeTimeLine) {
			if (!this.state.loading) {
				return null;
			} else {
				return <div className="spinnerBox"><Spinner active={true} color="#999" /></div>;
			}
		}

		const lifeTimeLineID = this.props.match.params.id;

		// TASK: update security model
		const isEditAllowed = lifeTimeLine.ownerEntityId === this.props.profile.entityId;

		let editMode = this.state.editMode;

		const isMinimumSet = lifeTimeLine &&
			!!lifeTimeLine.image &&
			lifeTimeLine.fromYear > 0 &&
			!!lifeTimeLine.description;

		if (!isMinimumSet) {
			editMode = true;
		}

		const momentsPanelClasses = [ 'moments-panel' ];
		if (this.props.profile.isSignedIn && this.props.match.params.author === 'author') {
			momentsPanelClasses.push('moments-panel--author');
		}

		return (
			<div className="lifeTimeLine">
				{isEditAllowed &&
				 isMinimumSet &&
					<AuthorMenu
						lifeTimeLineID={lifeTimeLineID}
						lifeTimeLine={lifeTimeLine}
						onSetLifeTimeLine={this.props.onSetLifeTimeLine}
						breakpoint={this.props.global.breakpoint}
						buildURL={this.buildURL}
						author={this.props.match.params.author}
						partnerID={this.props.audience.partnerID}
						toggleEditMode={this.toggleEditMode}
						editMode={editMode}
						handleCancelEdit={() => this.handleCancelEdit(editMode)}
						cartID={this.props.cart.cartID}
						history={this.props.history}
						addCartItem={this.props.addCartItem}
						setCartOptionChoice={this.props.setCartOptionChoice}
					/>
				}
				<SummaryPanel
					lifeTimeLineData={this.state.lifeTimeLineData}
					editAllowed={isEditAllowed}
					author={this.props.match.params.author}
					editMode={editMode}
					isMinimumSet={isMinimumSet}
					handleChangesMade={this.handleChangesMade}
					handleInputChange={this.handleInputChange}
					handleCancelEdit={() => this.handleCancelEdit(editMode)}
					handleImageUploaded={this.handleImageUploaded}
					handleImageChange={this.handleImageChange}
					saveLifeTimeLine={this.saveLifeTimeLine}
					saving={this.state.saving}
				/>
				<div className={momentsPanelClasses.join(' ')}>
					<Moments
						lifeTimeLine={lifeTimeLine}
						editAllowed={isEditAllowed}
						onDeleteMoment={this.onDeleteMoment}
						buildURL={this.buildURL}
						author={this.props.match.params.author}
					/>
				</div>
				{this.props.moment &&
					<FullMoment
						moment={this.props.moment}
						moments={lifeTimeLine.moments}
						lifeTimeLineID={lifeTimeLineID}
						lifeTimeLineTitle={lifeTimeLine.title}
						author={this.props.match.params.author}
						redirect={this.redirect}
						editMode={this.props.match.params.edit === '1'}
						editAllowed={isEditAllowed}
						onDeleteMoment={this.onDeleteMoment}
					/>
				}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const lifeTimeLine = state.lifeTimeLines[props.match.params.id];

	let moment = null;
	if (lifeTimeLine) {
		if (props.match.params.momentID === 'new') {
			moment = {
				momentId: 'new',
			};
		} else {
			const momentID = parseInt(props.match.params.momentID, 10);
			moment = lifeTimeLine.moments ? lifeTimeLine.moments.find(moment => moment.momentId === momentID) : null;
		}
	}

	return {
		profile: state.profile,
		lifeTimeLine,
		moment,
		global: state.global,
		audience: state.audience,
		cart: state.cart,
	};
};

// maps dispatch action creator to be a prop of this component
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSetMessage: (data) => {
			dispatch({
				type: 'SET_MESSAGE',
				data,
			});
		},
		onSetConfirm: (data) => {
			dispatch({
				type: 'SET_MODAL',
				data: {
					type: 'delete-moment',
					data,
				},
			});
		},
		onSetLifeTimeLine: (data) => {
			dispatch({
				type: 'SET_LIFETIMELINE',
				data,
			});
		},
		addCartItem: (data) => {
			dispatch({
				type: 'ADD_CART_ITEM',
				data,
			});
		},
		setCartOptionChoice: (data) => {
			dispatch({
				type: 'SET_CART_OPTION_CHOICE',
				data,
			});
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LifeTimeLine);
