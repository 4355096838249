import React from 'react';

import './Backdrop.scss';

const Backdrop = ({ isOpen, onClose, className, children }) => {
	if (!isOpen) {
		return null;
	}

	const classes = [ 'backdrop' ];
	if (className) {
		classes.push(className);
	}

	return (
		<div className={classes.join(' ')} onClick={onClose}>
			{children}
		</div>
	);
}

export default Backdrop;
