import React from 'react';

import './Notificatons.scss';

const Notificatons = (props) => {

	return (
		<div className="account__page--title">
			Notificatons
		</div>
	);
};

export default Notificatons;