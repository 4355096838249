import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import * as personAPI from '../../../api/personAPI';

import Button from '../../../components/Form/Button';
import Input from '../../../components/Form/Input';
import Image from '../../../components/Form/Image';
import Form from '../../../components/Form/Form';

import { dispatchError } from '../../../utils/general';

import './Details.scss';

class Detail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			resendButtonLoading: false,
			loading: true,
			editMode: true,
			changesMade: false,
			entityData: this.props.entityData,
			passwordEditActive: false,
			emailChanged: false,
			emailVerifyActive: !this.props.entityData.primaryEmailAddressVerified,
			saveState: 'default', // [default] | processing | success | fail
		};
	}

	componentWillReceiveProps = (nextProps) => {
		//console.log('nextProps|this.props - .entityData',nextProps.entityData,this.props.entityData);
		this.setState({
			resendButtonLoading: false,
			entityData: nextProps.entityData,
			saveState: nextProps.saveState,
			changesMade: false,
			emailChanged: false,
			emailVerifyActive: !nextProps.entityData.primaryEmailAddressVerified
		});
	}

	handlePasswordEditToggle = () => {
		this.setState(prevState => ({ passwordEditActive: !prevState.passwordEditActive }));
	}

	handleSaveAccountDetails = (payload) => {
		this.setState({ saveState: 'processing' });
		if (payload.entityId && payload.entityId !== 'new') {
			personAPI.update(payload)
				.then(resp => {
					if (resp.success) {
						this.props.onSetProfile(resp.data);
						this.setState({ saveState: 'success', changesMade: false, emailChanged: false, passwordEditActive: false  });
						//console.log('handleSaveAccountDetails-onSetProfile',resp);
					} else {
						// TASK: Handle error
						console.log('Error response from timeLineAPI', resp);
						this.setState({ saveState: 'fail' });
						this.props.onSetMessage({
							title: 			'Ooops!',
							message: 		'Something went wrong while attempting to complete your request.',
							description: 	resp.status,
							detail: 		'',
							actionEmail: 	false,
							id:				'AI.114'
						});
					}
				})
				.catch(err => {
					dispatchError(err);
					// TASK: Handle error
					console.log('Error response from timeLineAPI',err);
					this.props.onSetMessage({
						title: 			'Ooops!',
						message: 		'Something went wrong while talking to the API.',
						description: 	err,
						detail: 		'',
						actionEmail: 	false,
						id:				'AI.127'
					});
				});
		} else {
			// Delete the 'new' entityId
			delete(payload.entityId);
			personAPI.register(payload)
				.then(resp => {
					if (resp.success) {
						//this.props.onSetLifeTimeLine(resp.data);
						this.redirect(this.props.author, null, resp.data.timelineId);
						//this.handleLoadingEnd('formLoading');
					} else {
						// TASK: Handle error
					}
				});
		}
	};

	onValidateMatchedNewPasswords = (name, value) => {
		let newPasswordsMatchError = null;
		if (value !== this.state.entityData.password) {
			newPasswordsMatchError = 'New passwords do not match.';
		}
		this.setState({ newPasswordsMatchError });
	};

	renderPasswordInputs = () => {
		if (!this.state.editMode || !this.state.passwordEditActive) {
			return null;
		}
		return (
			<Fragment>
				<div className="account__page--input">
					<Input
						type="password"
						name="password"
						label="New Password"
						placeholder="Enter a New Password"
						className={`inputField ${this.state.editMode && 'inputField--editMode'}`}
						value={this.state.entityData.password || ''}
						editMode={this.state.editMode}
						editable={true}
						onChange={this.handleInputChange}
						required={true}
					/>
				</div>
				<div className="account__page--input">
					<Input
						type="password"
						name="confirmNewPassword"
						label="Confirm New Password"
						placeholder="Re-enter Your New Password"
						className={`inputField ${this.state.editMode && 'inputField--editMode'}`}
						value={this.state.entityData.confirmNewPassword || ''}
						editMode={this.state.editMode}
						editable={true}
						onChange={this.handleInputChange}
						required={true}
						onValidate={this.onValidateMatchedNewPasswords}
						error={this.state.newPasswordsMatchError}
					/>
				</div>
			</Fragment>
		)
	}

	renderCurrentPasswordInput = () => {
		if (!this.state.editMode || (!this.state.passwordEditActive && !this.state.emailChanged)) {
			return null;
		}
		return (
			<Fragment>
				<div className="account__page--input">
					<p className="base"><strong>Security Check:</strong> Changes to your Password or Email require you enter your Current Password.</p>
				</div>
				<div className="account__page--input">
					<Input
						type="password"
						name="verificationPassword"
						label="Current Password"
						placeholder="Enter your Current Password"
						className={`inputField ${this.state.editMode && 'inputField--editMode'}`}
						value={this.state.entityData.verificationPassword || ''}
						editMode={this.state.editMode}
						editable={true}
						onChange={this.handleInputChange}
						required={true}
					/>
				</div>
			</Fragment>
		)
	}

	renderEmailVerification = () => {
		if (!this.state.emailVerifyActive) {
			return null;
		}
		if (this.state.emailChanged) {
			return (
					<div className="account__page--input">
						<p className="top">You will be sent a verification code via email to the above address when you save this form.</p>
					</div>
			)
		}
		// return (
		// 		<div className="account__page--input">
		// 			<p className="top">A verification email has been sent to {this.state.entityData.primaryEmailAddress}.</p>
		// 		</div>
		// )
		return (
			<Fragment>
				<div className="account__page--input account__page--inputSet">
					{/*
					WHEN INTEGRATING MANUAL EMAIL VERIFICATION CODE
					Uncomment this block and remove the div bloak immediately below it
					<Input
						type="text"
						name="emailVerification"
						label="Email Verification"
						placeholder="Enter the Code"
						className={`inputField inputField--short40 ${this.state.editMode && 'inputField--editMode'}`}
						value={this.state.entityData.emailVerification || ''}
						editMode={this.state.editMode}
						editable={true}
						onChange={this.handleInputChange}
						required={false}
					/>
					<Button
						onClick={this.onVerifyEmail}
						className="formButton--toggle"
						color="blue"
						>Verify
					</Button>
					<Button
						className="formButton--toggle"
						color="grey"
						loading={this.state.resendButtonLoading}
						onClick={this.resendEmail}
						>Resend
					</Button> */}

					<div className="input-wrapper inputField inputField--short40 inputField--editMode">
						<label className="input__label">Email Verification</label>
						<Button
							className="formButton--toggle"
							color="grey"
							loading={this.state.resendButtonLoading}
							onClick={this.resendEmail}
							>Resend
						</Button>
					</div>



				</div>
			</Fragment>
		)
	}

	handleInputChange = (input) => {
		const name = input.target.name;
		const value = input.target.value;
		if (!value) {
			// TASK: WHy is this empty? Do we need to make a call on empty va;lues clearing data or being ignored?
		}
		this.setState({
			changesMade: true,
			entityData: {
				...this.state.entityData,
				[name]: value,
			}
		});
		if (name === 'primaryEmailAddress') {
			this.setState({
				emailChanged: value !== this.props.entityData.primaryEmailAddress,
				emailVerifyActive: value !== this.props.entityData.primaryEmailAddress || !this.state.entityData.primaryEmailAddressVerified
			});
		}
	};

	renderEmailVerified = () => {
		const isVerified = { text: "Verified", suffixIcon: "fa-check-circle"};
		const notVerified = { text: "Not Verified", suffixIcon: "fa-exclamation-circle", className: "input__flag--warn"};
		if (!this.state.emailChanged && this.state.entityData.primaryEmailAddressVerified) {
			//this.setState({ emailVerifyActive: false })
			return isVerified
		}
		//this.setState({ emailVerifyActive: true })
		return notVerified
	}

	onVerifyEmail = () => {
		alert('Email Address Verification coming soon.');
	}


	// RESEND EMAIL VERIFICATION LINK
	resendEmail = () => {
		this.setState({ resendButtonLoading: true, });
		personAPI.requestEmailVerify(this.props.profile.entityId)
			.then(resp => {
				console.log('Requesting new Verification Email :: resp',resp);
				if (resp.success) {
					this.props.onSetMessage({
						title: 			'Success!',
						message: 		'Verification Email successfully re-sent.',
						description: 	'',
						detail: 		'',
						actionEmail: 	false,
						id:				'',
					});
				} else {
					this.props.onSetMessage({
						title: 			'Hmmmm!',
						message: 		'Something went wrong. Please try again.',
						description: 	'',
						detail: 		'',
						actionEmail: 	false,
						id:				'',
					});
				}
			});
	};





	onImageUpload = () => {
		alert('Profile Image Uploads are coming soon.');
	}

	handleImageUploaded = (resp) => {
		if (resp.success) {
			this.setState({
				entityData: {
					...this.state.entityData,
					image: resp.data,
				}
			});
			//this.handleLoadingEnd('imageLoading');
		} else {
			// TASK: Handle file upload failure
		}
	};

	// handleSaveAccountDetails = () => {
	// 	alert('Save');
	// }

	handleResetAccountDetails = () => {
		this.setState({
			changesMade: false,
			saveState: 'default',
			emailChanged: false,
			entityData: {
				...this.props.entityData,
			}
		});
	}

	renderSaveCancel = () => {
		const { editMode, changesMade} = this.state;
		if (!editMode && !changesMade) {
			return null;
		}

		let saveButtonLabel = 'Save';
		let saveButtonColor = 'blue';
		if (!this.state.changesMade) {
			if (this.state.saveState==='processing') {
				saveButtonLabel = 'Processing';
			}
			if (this.state.saveState==='success') {
				saveButtonLabel = 'Saved!';
				saveButtonColor = 'green';
			}
			if (this.state.saveState==='fail') {
				saveButtonLabel = 'Error!';
				saveButtonColor = 'red';
			}
		}

//console.log('changesMade',changesMade,'this.state.saveState',this.state.saveState,'saveButtonLabel', saveButtonLabel, 'saveButtonColor',saveButtonColor);

		return (
			<div className="account__page--buttons">
				<Button onClick={this.handleResetAccountDetails} className="formButton--toggle" color="lightGrey">Reset</Button>
				<Button
					className="formButton--status"
					onClick={() => this.handleSaveAccountDetails(this.state.entityData)}
					color={saveButtonColor}
					loading={this.state.saveState==='processing'}
					isSubmit={true}
					>{saveButtonLabel}</Button>
			</div>
		);
	};


	render() {

		const {
			editMode,
			entityData,
			passwordEditActive,
		} = this.state;

		// const imageTemp = {
		// 	height: 1106,
		// 	originalFilename: "_chester-nutting-protrait.jpg",
		// 	ownerEntityId: 31,
		// 	persistedFileId: "d6d30b94-4f73-4793-a732-70793e580bb3",
		// 	type: "image",
		// 	uri: "https://files.staging.inmemoriam.com/44e9cf63-4f11-40b1-b6df-3c21076ab589.jpg",
		// 	width: 776,
		// };

		let imageTemp = {
			height: null,
			originalFilename: null,
			ownerEntityId: null,
			persistedFileId: null,
			type: "image",
			uri: null,
			width: null,
		};
		if (entityData.image) {
			imageTemp = entityData.image;
		}

		if (!entityData) {
			return (
				<div className="account__page">
					<div className="account__page--title">Could not return the User Details</div>
				</div>
			)
		}
		return (
			<div className="account__page">
				<div className="account__page--title">
					Account Details
				</div>
				<Form>
				<div className="account__page--form">
					<Image
						image={imageTemp}
						alt="Profile Image"
						editable={editMode}
						//onChange={this.props.handleImageChange}
						onImageUpload={this.handleImageUploaded}
						className="account__page--image"
						defaultIcon="fa-user"
						lazyLoading={false}
					/>
					<div className="account__page--inputs">
						<div className="account__page--input">
							<Input
								type="text"
								name="firstName"
								label="First Name"
								placeholder="First or Given Name"
								className={`inputField ${editMode && 'inputField--editMode'}`}
								value={entityData.firstName}	// test longname "Chester Wolfeschlegelsteinhausenbergerdorff"
								editMode={editMode}
								editable={true}
								onChange={this.handleInputChange}
							/>
						</div>
						<div className="account__page--input">
							<Input
								type="text"
								name="middleName"
								label="Middle Name"
								placeholder="Middle Name(s)"
								className={`inputField ${editMode && 'inputField--editMode'}`}
								value={entityData.middleName && entityData.middleName.length ? entityData.middleName: ''}
								editMode={editMode}
								editable={true}
								onChange={this.handleInputChange}
							/>
						</div>
						<div className="account__page--input">
							<Input
								type="text"
								name="lastName"
								label="Last Name"
								className={`inputField ${editMode && 'inputField--editMode'}`}
								placeholder="Last or Family Name"
								value={entityData.lastName && entityData.lastName.length ? entityData.lastName: ''}
								editMode={editMode}
								editable={true}
								onChange={this.handleInputChange}
							/>
						</div>
						<div className="account__page--input">
							<Input
								type="email"
								name="primaryEmailAddress"
								label="Email"
								className={`inputField ${editMode && 'inputField--editMode'}`}//{`summaryPanel__field summaryPanel__title ${editMode && 'summaryPanel__title--editMode'}`}
								placeholder="Email"
								required={true}
								value={entityData.primaryEmailAddress}	// test longname "Chester Wolfeschlegelsteinhausenbergerdorff"
								editMode={editMode}
								editable={true}
								onChange={this.handleInputChange}
								flag={this.renderEmailVerified()}
							/>
						</div>
						{this.renderEmailVerification()}
						<div className="account__page--input">
							<Button
								onClick={this.handlePasswordEditToggle}
								className="formButton--toggle"
								color={!passwordEditActive ? "blue" : ""}>
								{passwordEditActive && "Cancel "}Change Password
							</Button>
						</div>
						{this.renderPasswordInputs()}
						{this.renderCurrentPasswordInput()}
					</div>
				</div>
				{this.renderSaveCancel()}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		profile: state.profile,
		global: state.global,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	onSetMessage: (data) => {
		dispatch({
			type: 'SET_MESSAGE',
			data,
		});
	},
	onSetProfile: (data) => {
		dispatch({
			type: 'SET_PROFILE',
			data,
		});
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Detail);
