import React, { Component } from 'react';

import * as validation from '../../Form/Validation.js';

import { getCardType } from '../../../utils/card';

import './Input.scss';

class Input extends Component {
	static fieldType = 'Input';

	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
			error: props.error,
		};
	}

	componentWillReceiveProps(nextProps) {
		const data = {};
		if (nextProps.value !== undefined) {
			data.value = nextProps.value;
		}
		if (nextProps.error !== undefined) {
			data.error = nextProps.error;
		}
		if (Object.keys(data).length > 0) {
			this.setState(data);
		}
	}

	handleOnChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange(event);
		}

		const value = event.target.value;

		this.validate(value);

		this.setState({
			value,
		});
	}

	handleOnFocus = (event) => {
		if (this.props.onFocus) {
			this.props.onFocus(event);
		}

		// Fixes an issue where the cursor would be at the start of the value on focus.
		if (event.target.selectionStart) {
			const length = event.target.value.length;
			event.target.selectionStart = length;
			event.target.selectionEnd = length;
		}
	}

	validate = (value) => {
		const error = this.getError(value);

		this.setState({ error });

		if (this.props.onValidate) {
			const displayName = this.getDisplayName();

			this.props.onValidate(displayName, value, error);
		}

		return error && error.length > 0;
	};

	getError = (value) => {
		const {
			type,
			required,
			reqlength,
			minlength,
			maxlength,
		} = this.props;

		const displayName = this.getDisplayName();

		if (required && !value) {
			return displayName ?
				`${displayName} is a required field.` :
				'Required field.';
		}

		const valueLength = !!value ? value.length : 0;

		if (reqlength && valueLength < reqlength) {
			return displayName ?
				`${displayName} must be ${reqlength} characters.` :
				`Must be ${reqlength} characters.`;
		}

		if (minlength && valueLength < minlength) {
			return displayName ?
				`${displayName} must be at least ${minlength} characters.` :
				`Must be at least ${minlength} characters.`;
		}

		if (maxlength && valueLength > maxlength) {
			return displayName ?
				`${displayName} must be less than ${maxlength} characters.` :
				`Must be less than ${maxlength} characters.`;
		}

		let error = null;

		switch (type) {
			case 'email':
				const emailError = validation.email(value);
				if (emailError.length > 0) {
					error = emailError[0];
				}
				break;
			case 'password':
				const passwordError = validation.password(value);
				if (passwordError.length > 0) {
					error = passwordError[0];
				}
				break;
			case 'cardNumber':
				const cardNumberError = validation.cardNumber(value);
				if (cardNumberError.length > 0) {
					error = cardNumberError[0];
				}
				break;
			default:
		}

		return error;
	};

	titleCase = (str) => {
		const spacedOut = str.replace(/([A-Z])/g, ' $1').trim();
		return spacedOut.toLowerCase().split(' ').map(function(word) {
			return (word.charAt(0).toUpperCase() + word.slice(1));
		}).join(' ');
	}

	getDisplayName = () => {
		const {
			name,
			label,
		} = this.props;

		// Use label by default
		let displayName = label;

		if (!displayName) {
			displayName = this.titleCase(name);
		}

		return displayName;
	};

	renderLabel = (label,required) => {
		if (!label) {
			return null;
		}

		return (
			<label htmlFor={this.props.id} className="input__label">
				{label}{required && label && <i className="fa fa-asterisk input__label--required" />}
			</label>
		);
	};

	renderInput = (props, value) => {
		if (value === undefined || value === null) {
			value = '';
		}

		switch (props.type) {
			case 'textarea':
				return (
					<textarea
						{...props}
						className="input"
						value={value}
						onChange={this.handleOnChange}
						onFocus={this.handleOnFocus}
					/>
				);
			case 'cardNumber':
				const classes = [ 'input__icon' ];

				switch (getCardType(value.replace(/ /g, ''))) {
					case "Visa":
						classes.push('input__icon--visa');
						break;
					case "MasterCard":
						classes.push('input__icon--mastercard');
						break;
					case "American Express":
						classes.push('input__icon--amex');
						break;
					default:
				}

				return (
					<div className={classes.join(' ')}>
						<input
							{...props}
							type="text"
							className="input"
							value={value}
							onChange={this.handleOnChange}
							onFocus={this.handleOnFocus}
						/>
					</div>
				);
			case 'text':
			default:
				return (
					<input
						type="text"
						{...props}
						className="input"
						value={value}
						onChange={this.handleOnChange}
						onFocus={this.handleOnFocus}
					/>
				);
		}
	};

	renderError = (error) => {
		if (!error) {
			return null;
		}

		return (
			<div className="input__error">
				{error}
			</div>
		);
	};

	renderFlag(flag) {
		// Flag Object can contain an icon, text, suffix icon and className.
		if (!flag) {
			return null;
		}
		let flagIcon = null;
		if (flag.icon) {
			flagIcon = <i className={`fa ${flag.icon}`} />
		}
		let flagSuffixIcon = null;
		if (flag.suffixIcon) {
			flagSuffixIcon = <i className={`fa ${flag.suffixIcon}`} />
		}
		return (
			<div className={`input__flag ${flag.className}`}>
				{flagIcon}{flag.text}{flagSuffixIcon}
			</div>
		);
	};

	render() {
		const {
			label,
			className,
			editable,
			editMode,
			required,
			flag,
			...otherProps
		} = this.props;

		delete(otherProps.reqlength);
		delete(otherProps.minlength);
		delete(otherProps.maxlength);
		delete(otherProps.onValidate);

		const {
			value,
			error,
		} = this.state;

		const classes = [ 'input-wrapper' ];

		if (error && error.length) {
			classes.push('input-wrapper--error');
		}

		if (className) {
			classes.push(className);
		}

		if (!editable || !editMode) {
			const isZeroValue = !value || value === '0';

			return (
				<div className={classes.join(' ')} {...otherProps}>
					{isZeroValue ? this.props.placeholder || label : value}
				</div>
			);
		}

		delete(otherProps.error);

		return (
			<div className={classes.join(' ')}>
				{this.renderLabel(label, required)}
				{this.renderInput(otherProps, value)}
				{this.renderError(error)}
				{this.renderFlag(flag)}
			</div>
		);
	}
}

export default Input;
