import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

//import './PageMenuItem.css';  --- uses pageMenu to delcare its css

const renderClassName = (menuType, suffix='') => {
	return (
		(menuType==='vertical'?'vertical':'page') + 'Menu__menu-item' + suffix
	)
}

const PageMenuItem = ({
	onClick,
	linkTo,
	iconClassName,
	label,
	disabled,
	children,
	isHighlighted,
	isOn,
	isActive,
	isOpen,
	menuType='horizontal',
	...otherProps,
}) => {
	if ( !onClick && !linkTo ) {
		return null;
	}

	// Clean up other props so they wont be passed to the outer div element
	delete(otherProps.linkTo);
	delete(otherProps.iconClassName);
	delete(otherProps.label);
	delete(otherProps.disabled);
	delete(otherProps.children);
	delete(otherProps.isHighlighted);
	delete(otherProps.isOn);
	delete(otherProps.isActive);
	delete(otherProps.isOpen);
	delete(otherProps.menuType);

	let menuItemElement = "";
	if (linkTo) {;
		menuItemElement = (
			<Fragment>
				<Link to={linkTo} className="page-menu-item__element"><i className={iconClassName}></i></Link>
				<Link to={linkTo} className="page-menu-item__element"><span className={renderClassName(menuType,'--label')}>{label}</span></Link>
			</Fragment>
		);
	} else if (onClick) {
		menuItemElement = (
			<Fragment>
				<div className="page-menu-item__element" onClick={!disabled ? onClick : null}><i className={iconClassName}></i></div>
				<div className="page-menu-item__element" onClick={!disabled ? onClick : null}><span className={renderClassName(menuType,'--label')}>{label}</span></div>
			</Fragment>
		);
	}

	const classes = [ renderClassName(menuType,'') ];
	if (isOn) {
		classes.push(renderClassName(menuType,'--on'));
	}
	if (isHighlighted) {
		classes.push(renderClassName(menuType,'--highlight'));
	}
	if (isActive) {
		classes.push(renderClassName(menuType,'--active'));
	}
	if (isOpen) {
		classes.push(renderClassName(menuType,'--open'));
	}

	return (
		<div
			className={classes.join(' ')}
			{...otherProps}
		>
			{menuItemElement}
			{children}
		</div>
	);
}

export default PageMenuItem;



