import React, { Component } from 'react';

import Form from '../Form/Form';
import Input from '../Form/Input';
import Switch from '../Form/Switch';
import Button from '../Form/Button';

import { formatMoney } from '../../utils/general';

import * as cartAPI from '../../api/cartAPI';

import './Payment.scss';

class Payment extends Component {
	state = {
		isLoading: false,
		customExpiryError: null,
	};

	onSubmit = (payload) => {
		this.setState({ isLoading: true });

		payload.PartnerId = this.props.partnerID;
		payload.CreditCardNumber = payload.CreditCardNumber.replace(/ /g, '');

		const expiry = payload.Expiry.split('/');
		delete(payload.Expiry);

		if (expiry.length === 2) {
			payload.ExpiryMonth = expiry[0];
			payload.ExpiryYear = `${new Date().getFullYear().toString().substr(0, 2)}${expiry[1]}`;
		}

		cartAPI.submitCartPayment(payload)
			.then(resp => {
				if (resp.success && resp.data.paymentSucceeded) {
					if (this.props.onSubmit) {
						this.props.onSubmit(resp, payload);
					} else {
						this.setState({ isLoading: false });
					}
				} else {
					this.setState({
						errorMessage: resp.data.statusMessage,
						isLoading: false,
					});
				}
			});
	};

	onValidateExpiry = (name, value) => {
		const regex = /^(0?[1-9]|1[0-2])\/[0-9]{2}$/;

		let customExpiryError = null;
		if (!regex.test(value)) {
			customExpiryError = 'Expiry must be in the format MM/YY.';
		}

		this.setState({ customExpiryError });
	};

	renderErrorMessage = (message) => {
		if (!message) {
			return null;
		}

		return (
			<div className="payment__error">
				{message}
			</div>
		);
	};

	render() {
		return (
			<div className="payment">
				<Form>
					<Input
						type="text"
						id="CardholderName"
						name="CardholderName"
						label="Name on Card"
						placeholder="Name on Card"
						className="payment__input"
						required
						editable={true}
						editMode={true}
					/>
					<Input
						type="cardNumber"
						id="CreditCardNumber"
						name="CreditCardNumber"
						label="Card Number"
						placeholder="Card Number"
						className="payment__input"
						required
						editable={true}
						editMode={true}
					/>
					<div className="payment__details">
						<Input
							type="text"
							id="Expiry"
							name="Expiry"
							label="Expiry"
							placeholder="MM/YY"
							className="payment__input payment__input--short"
							required
							editable={true}
							editMode={true}
							onValidate={this.onValidateExpiry}
							error={this.state.customExpiryError}
						/>
						<Input
							type="number"
							id="ValidationCode"
							name="ValidationCode"
							label="CVC"
							placeholder="CVC"
							className="payment__input payment__input--short"
							required
							minlength={3}
							maxlength={4}
							editable={true}
							editMode={true}
						/>
						<Switch
							name="SaveCreditCardDetails"
							label="Save"
							value={this.props.isSubscriptionPayment || undefined}
							locked={this.props.isSubscriptionPayment}
							lockedMessage="Your cart contains a subscription and we need to save some details. We don't actually store your card details, just a token to allow us to complete the future subscription transactions."
						/>
					</div>
					Your bank statement will show <span className="payment__highlight">InMemoriamGroup</span>
					{this.renderErrorMessage(this.state.errorMessage)}
					<div className="payment__buttons">
						<Button onClick={this.props.onCancel}>Cancel</Button>
						<Button
							color="blue"
							isSubmit={true}
							onClick={this.onSubmit}
							loading={this.state.isLoading}
						>
							Pay {formatMoney(this.props.amount/100, 0)} Now
						</Button>
					</div>
				</Form>
			</div>
		);
	}
};

export default Payment;
