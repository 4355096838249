const formatTimespan = (from, to) => {
	if (!from) {
		return null
	}

	if (!to) {
		to = "present";
	}

	return `${from} to ${to}`;
};

export { formatTimespan }