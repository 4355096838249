import * as storage from '../utils/storage';

const defaultState = {
	isSignedIn: false,

	entityId: 0,
	image: {},
	firstName: '',
	middleName: '',
	lastName: '',
	primaryEmailAddress: '',
	name: '',
	nickname: '',
	nicknameDisplay: '',
	otherNames: '',
	password: '',
	type: '',
	verificationPassword: '',
	primaryEmailAddressVerified: false,
	// MATCH: entityData :: See Account/index.js for matching list.
};

const initialState = storage.load(defaultState);

const reducer = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_PROFILE':
			storage.save(action.data);

			return {
				...state,
				...action.data,
			};
		case 'SIGN_OUT':
			storage.clear({
				access_token: null,
				refresh_token: null,
				expiry: null,
				...state,
			});
			return {
				...defaultState,
			};
		default:
			return state;
	}
}

export default reducer;
