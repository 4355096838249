import { sendFetchRequest, getApiContext } from './api_utils';

const personURL = `${getApiContext()}/api/person`;

const register = (primaryEmailAddress, password, firstName, lastName) => {
	const formData = {
		primaryEmailAddress,
		password,
		firstName,
		lastName,
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(personURL, options);
};

const get = (entityId) => {
	const options = {
		method: 'GET',
	};

	return sendFetchRequest(`${personURL}/${entityId}`, options);
};

const requestPasswordReset = (emailAddress) => {
	const formData = {
		emailAddress,
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(`${personURL}/password-reset`, options);
};

const actionPasswordReset = (passwordResetTokenId, password) => {
	const formData = {
		passwordResetTokenId,
		password,
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(`${personURL}/password-reset/action`, options);
};

const requestEmailVerify = (entityId) => {
	const formData = {
		entityId,
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(`${personURL}/email-verify`, options);
};

const actionEmailVerify = (emailVerificationTokenId) => {
	const formData = {
		emailVerificationTokenId,
	};

	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(`${personURL}/email-verify/action`, options);
};

const update = (formData) => {
	const entityId = formData.entityId
	const options = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	};

	return sendFetchRequest(`${personURL}/${entityId}`, options);
};

export {
	register,
	get,
	requestPasswordReset,
	actionPasswordReset,
	requestEmailVerify,
	actionEmailVerify,
	update,
};
