import jwtDecode from 'jwt-decode';

import { sendFetchRequest, formURLEncode, getApiContext } from './api_utils';
import * as storage from '../utils/storage';

function signIn(username, password) {
	const payload = {
		username,
		password,
		grant_type: 'password',
	};

	return sendTokenRequest(payload);
}

function refresh(refresh_token) {
	const payload = {
		refresh_token,
		grant_type: 'refresh_token',
	};

	return sendTokenRequest(payload);
}

function sendTokenRequest(payload) {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: formURLEncode(Object.assign({
			scope: 'offline_access InMemoriamApi',
			client_id: 'imgfe',
		}, payload)),
	};

	const signInURL = `${getApiContext('idsrv')}/connect/token`;

	return sendFetchRequest(signInURL, options, false)
		.then(resp => {
			if (!resp.success) {
				throw new Error(resp.data.error);
			}

			const token = jwtDecode(resp.data.access_token);

			const expiry = token.exp;
			const entityId = Number(token.eid);

			storage.save({
				access_token: resp.data.access_token,
				refresh_token: resp.data.refresh_token,
				expiry,
			});

			return {
				success: true,
				data: {
					isSignedIn: true,
					entityId,
				},
			};
		});
}

export { signIn, refresh };
