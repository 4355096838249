import React from 'react';

import './Messages.scss';

const Messages = (props) => {

	return (
		<div className="account__page--title">
			Messages
		</div>
	);
};

export default Messages;